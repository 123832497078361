import { EditOutlined, KeyOutlined, Lock, Person } from "@mui/icons-material";

import {
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function MenuAdm({
  anchorEl,
  handleClose,
  open,
  setOpenEditProfileModal = () => {},
  setOpenEditLoginModal = () => {},
  setOpenEditPermissionModal = () => {},
}) {
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
      >
        <MenuList>
          <MenuItem
            color="text.secondary"
            onClick={() => {
              setOpenEditLoginModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Senha</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenEditProfileModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Detalhes</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenEditPermissionModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <KeyOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Alterar Permissões</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
