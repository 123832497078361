import { useEffect, useState } from "react";
import {
  Add,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  ChevronRight,
  Delete,
  ExpandMore,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Chip,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import TreeItemFabricante from "./treeItemFabricante";
import imgEmpty from "../../../../../assets/wallet.svg";
import Disquete from "@mui/icons-material/Save";
import Caneta from "@mui/icons-material/ModeEdit";
import Close from "@mui/icons-material/Close";
import api from "../../../../../services/api";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useTheme } from "@mui/material";

const filter = createFilterOptions();

export default function Predifinicoes(params) {
  const [groupHosts, setGroupHosts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [macros, setMacros] = useState([{ macro: "", valor: "" }]);
  const [filtro, setFiltro] = useState("");
  //
  const [lastGroupHosts, setLastGroupHosts] = useState([]);
  const [lastTemplates, setLastTemplates] = useState([]);
  const [lastMacros, setLastMacros] = useState([{ macro: "", valor: "" }]);
  //
  const [predefinicaoId, setPredefinicaoId] = useState("");
  const [nomeModelo, setNomeModelo] = useState("selecione o modelo");
  const [fabricantes, setFabricantes] = useState([]);
  const [fabricante, setFabricante] = useState("");
  const [predefinicoes, setPredefinicoes] = useState([]);
  //
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxOutlined fontSize="small" />;
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selecionado, setSelecionado] = useState(false);
  //configuração
  const [ssh, setSsh] = useState(false);
  const [telnet, setTelnet] = useState(false);
  const [userSSH, setUserSSH] = useState("");
  const [portaSSH, setportaSSH] = useState("");
  const [senhaSSH, setSenhaSSH] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setportaTelnet] = useState("");
  const [senhaTelnet, setSenhaTelnet] = useState("");

  const theme = useTheme();

  const [editar, setEditar] = useState(false);

  const fabricanteFiltred = fabricantes.filter((fabricante) =>
    fabricante.fabricante.toUpperCase().includes(filtro.toUpperCase())
  );

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minWidth: "1000px",
      justifyContent: "center",
    },
    checkBoxText: {
      "& .MuiSvgIcon-root": { fontSize: 28 },
    },
    predefinicoesColumn: {
      //padding: "0 3.5vw",
      display: "flex",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    predefinicoesPaper: {
      padding: "20px",
      width: "300px",
      height: "calc(100vh - 95px)",
    },
    select: { width: "240px" },
    textfield: { width: "220px" },
    formPaper: {
      padding: "30px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "600px",
      maxWidth: "880px",
      width: "50vw",
      padding: "40px",
      mb: "50px",
    },
    paperUnselected: {
      backgroundColor: "rgba(0,0,0,0.2)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      filter: "opacity(0.6)",
      minWidth: "600px",
      //maxWidth: "880px",
      height: "600px",
      width: "100%",
    },
    MacroCampo: {
      fontSize: "16px",
      width: "220px",
    },
    StyleButtons: {
      width: "40px",
      height: "40px",
    },
    textTitle: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: 1.5,
      color: theme.palette.color.textDisable,
      display: "flex",
    },
    textData: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: 1.5,
      display: "flex",
    },
  };

  function createFabricantes(novoFabricante) {
    const novoArray = [...fabricantes];
    novoArray.push(novoFabricante);
    setFabricantes(novoArray);
  }
  function updateFabricantes(novoFabricante) {
    const novoArray = fabricantes.map((obj) => {
      if (obj.id === novoFabricante.id) {
        return { ...obj, ...novoFabricante };
      } else {
        return obj;
      }
    });
    setFabricantes(novoArray);
  }

  function deleteFabricante(removeFabricante) {
    const novoArray = fabricantes.map((obj) => {
      if (obj.id !== removeFabricante) {
        return obj;
      } else {
        return { id: null, fabricante: null, Funcoes: [] };
      }
    });
    setFabricantes(novoArray);
    setPredefinicaoId("");
  }

  function updatePredefinicoes(novaPredefinicao) {
    const novoArray = predefinicoes.map((value) => {
      if (value.id === novaPredefinicao.id) {
        return novaPredefinicao;
      } else {
        return value;
      }
    });
    setPredefinicoes(novoArray);
  }
  function createPredefinicoes(novaPredefinicao) {
    const novoArray = predefinicoes;
    novoArray.push(novaPredefinicao);
    setPredefinicoes(novoArray);
  }

  const handleMacroChange = (index, event) => {
    const { name, value } = event.target;
    const newMacros = [...macros];
    newMacros[index][name] = value;
    setMacros(newMacros);
  };

  const addMacro = () => {
    setMacros([...macros, { macro: "", valor: "" }]);
  };

  const removeMacro = (index) => {
    const newMacros = [...macros];
    newMacros.splice(index, 1);
    setMacros(newMacros);
  };

  const handleExpand = () => {
    const funcoesExpanded = fabricantes.flatMap((value) =>
      value.Funcoes.map((funcao) => funcao.id)
    );
    const fabricantesExpanded = fabricantes.map((value) => value.id);
    //const aux = [...funcoesExpanded, ...fabricantesExpanded];
    setExpanded(fabricantesExpanded);
  };

  function savePredefinicoes(newFabricantes) {
    if (newFabricantes) {
      let auxArray = [];
      newFabricantes.map((obj) => {
        obj.Funcoes.map((obj) => {
          obj.Modelos.map((obj) => {
            auxArray.push(obj.Predefinicoes);
          });
        });
      });
      setPredefinicoes(auxArray);
    }
  }

  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetTemplates();
  }, []);

  useEffect(() => {
    if (predefinicaoId) {
      //  setSelecionado(false);
      // setTimeout(() => {
      setSelecionado(true);
      // }, 200);
    } else {
      setSelecionado(false);
    }
  }, [predefinicaoId]);

  useEffect(() => {
    if (predefinicaoId) {
      const newPredefinicao = predefinicoes.find(
        ({ id }) => id === predefinicaoId
      );
      setMacros(
        newPredefinicao.macros
          ? JSON.parse(newPredefinicao.macros)
          : [{ macro: "", valor: "" }]
      );
      setTemplates(
        newPredefinicao.templates ? JSON.parse(newPredefinicao.templates) : []
      );
      setGroupHosts(
        newPredefinicao.groups ? JSON.parse(newPredefinicao.groups) : []
      );

      setLastTemplates(
        newPredefinicao.templates ? JSON.parse(newPredefinicao.templates) : []
      );
      setLastGroupHosts(
        newPredefinicao.groups ? JSON.parse(newPredefinicao.groups) : []
      );
      setLastMacros(
        newPredefinicao.macros
          ? JSON.parse(newPredefinicao.macros)
          : [{ macro: "", valor: "" }]
      );
      //configurações
      let config = newPredefinicao.config;

      setSsh(config?.SSH ? true : false);
      setUserSSH(config?.SSH?.userSSH || "");
      setportaSSH(config?.SSH?.portaSSH || "");
      setSenhaSSH(config?.SSH?.senhaSSH || "");
      setTelnet(config?.telnet ? true : false);
      setUserTelnet(config?.Telnet?.userTelnet || "");
      setportaTelnet(config?.Telnet?.portaTelnet || "");
      setSenhaTelnet(config?.Telnet?.senhaTelnet || "");
    }
  }, [predefinicaoId]);

  async function handleApiGetPredefinicoes() {
    try {
      const response = await api.get("/getPredefinicoes");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Predefinicoes",
          severity: "error",
        });
      } else {
        setFabricantes(response.data);
        savePredefinicoes(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleEditaPredefinicoes() {
    try {
      const response = await api.put("/putPredefinicao", {
        id: predefinicaoId,
        templates: JSON.stringify(templates),
        macros: JSON.stringify(macros),
        groups: JSON.stringify(groupHosts),
        config: {
          SSH: ssh
            ? {
                userSSH,
                senhaSSH,
                portaSSH,
              }
            : null,
          Telnet: telnet
            ? {
                userTelnet,
                senhaTelnet,
                portaTelnet,
              }
            : null,
        },
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Não foi possível salvar as predefinicoes, verifique se você selecionou um modelo corretamente",
          severity: "error",
        });
      } else {
        let data = response.data;
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updatePredefinicoes(data);
        setLastGroupHosts(data.groupHosts);
        setLastTemplates(data.templates);
        setLastMacros(data.macros);
        setSsh(data?.config?.SSH ? true : false);
        setUserSSH(data?.config?.SSH?.userSSH || "");
        setportaSSH(data?.config?.SSH?.portaSSH || "");
        setSenhaSSH(data?.config?.SSH?.senhaSSH || "");
        setTelnet(data?.config?.telnet ? true : false);
        setUserTelnet(data?.config?.Telnet?.userTelnet || "");
        setportaTelnet(data?.config?.Telnet?.portaTelnet || "");
        setSenhaTelnet(data?.config?.Telnet?.senhaTelnet || "");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  const handleApiCriaFabricante = async () => {
    try {
      const response = await api.post("/postFabricante", {
        fabricante,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível adicionar o fabricante",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        let aux = response.data;
        aux.Funcoes = [];
        createFabricantes(aux);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };

  async function handleApiGetTemplates() {
    try {
      const response = await api.get("/getTemplates");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar templates",
          severity: "error",
        });
      } else {
        setTemplatesList(response.data || []);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  function expandeNovoModelo(novoModelo, funcaoId) {
    let aux = expanded;
    const funcaoExpandida = expanded.find((value) => value === funcaoId);
    if (!funcaoExpandida) {
      aux.push(funcaoId);
      setExpanded(aux);
    }
    setSelected(novoModelo.id);
    setPredefinicaoId(novoModelo.Predefinicoes.id);
    setNomeModelo(novoModelo.modelo);
  }

  function expandeNovaFuncao(novoValor) {
    let aux = expanded;
    const funcaoExpandida = expanded.find((value) => value === novoValor.id);
    if (!funcaoExpandida) {
      aux.push(novoValor.id);
      setExpanded(aux);
    }
    setSelected(novoValor.id);
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpanded(itemIds);
  };

  return (
    <Box sx={styles.container}>
      <Stack direction={"row"} gap={4} width={"100%"}>
        <Box sx={styles.predefinicoesColumn}>
          <Paper sx={styles.predefinicoesPaper}>
            {" "}
            <Stack direction={"column"} gap={"20px"}>
              <Typography color={"primary"} fontWeight={500}>
                Predefinições
              </Typography>
              <TextField
                id="Fabricante"
                variant="standard"
                {...params}
                label="Filtra fabricante"
                fullWidth={true}
                value={filtro}
                onChange={(event) => {
                  setFiltro(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  setOpenEditModal(true);
                }}
              >
                Adicionar Fabricante
              </Button>
              <Divider />
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Button
                  onClick={handleExpand}
                  startIcon={<KeyboardDoubleArrowDown />}
                >
                  {" "}
                  Expandir
                </Button>
                <Button
                  onClick={() => {
                    setExpanded([]);
                  }}
                  startIcon={<KeyboardDoubleArrowUp />}
                >
                  {" "}
                  Recolher
                </Button>
              </Stack>
              <SimpleTreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                onNodeToggle={(event, nodeIds) => {
                  setExpanded(nodeIds);
                }}
                onNodeSelect={handleSelect}
                expandedItems={expanded}
                onExpandedItemsChange={handleExpandedItemsChange}
                selected={selected}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                  flexGrow: 1,
                  maxHeight: "calc(100vh - 430px)",
                  width: "250px",
                }}
                defaultExpanded={["root"]}
              >
                {Array.isArray(fabricantes)
                  ? fabricanteFiltred.map((node, index) => (
                      <TreeItemFabricante
                        id={node.id}
                        key={node.id}
                        nodeId={node.id}
                        label={node.fabricante}
                        node={node}
                        deleteFabricante={deleteFabricante}
                        updateFabricantes={updateFabricantes}
                        createPredefinicoes={createPredefinicoes}
                        expandeNovoModelo={expandeNovoModelo}
                        expandeNovaFuncao={expandeNovaFuncao}
                        setPredefinicaoId={setPredefinicaoId}
                        setNomeModelo={setNomeModelo}
                      />
                    ))
                  : null}
              </SimpleTreeView>
            </Stack>
          </Paper>
        </Box>
        {!selecionado && (
          <Fade in={!selecionado} {...(!selecionado ? { timeout: 500 } : {})}>
            <Box sx={styles.paperUnselected}>
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Selecione um modelo
              </Typography>
            </Box>
          </Fade>
        )}
        {selecionado && (
          <Fade in={selecionado} {...(selecionado ? { timeout: 500 } : {})}>
            {nomeModelo ? (
              <Box>
                <Paper sx={styles.formPaper}>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent="space-between"
                    mb="10px"
                  >
                    <Stack direction="row" gap={1}>
                      <Typography
                        color={"primary"}
                        fontWeight={500}
                        fontSize={20}
                      >
                        {`Modelo:`}
                      </Typography>
                      <Typography fontWeight={500} fontSize={20}>
                        {nomeModelo}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      {editar ? (
                        <Tooltip title="salvar">
                          <IconButton
                            variant="contained"
                            color="primary"
                            sx={{
                              width: "40px",
                              height: "40px",
                            }}
                            onClick={async () => {
                              handleEditaPredefinicoes();
                              setEditar(false);
                            }}
                          >
                            <Disquete />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="editar">
                          <IconButton
                            variant="contained"
                            color="primary"
                            sx={styles.StyleButtons}
                            onClick={(e) => {
                              setEditar(true);
                            }}
                          >
                            <Caneta />
                          </IconButton>
                        </Tooltip>
                      )}
                      {editar && (
                        <Tooltip title="cancelar">
                          <IconButton
                            variant="contained"
                            color="secondary"
                            sx={styles.StyleButtons}
                            onClick={(e) => {
                              setEditar(false);
                              setMacros(lastMacros);
                              setGroupHosts(lastGroupHosts);
                              setTemplates(lastTemplates);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction={"column"} gap={"10px"} width="100%">
                    <Box width="100%">
                      <Typography color={"primary"} fontWeight={500} mt="15px">
                        Grupo de hosts
                      </Typography>
                      {!editar && <Divider sx={{ mt: "5px" }} />}
                    </Box>

                    {!editar ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        gap={"5px"}
                        component="ul"
                      >
                        {groupHosts?.length == 0 && (
                          <>
                            <li margin={0.5} key={"semGupos"}>
                              <Chip
                                sx={{
                                  bgcolor: theme.palette.background.arquive,
                                }}
                                label={"Sem Grupos"}
                              />
                            </li>
                          </>
                        )}
                        {groupHosts.map((grupo, index) => (
                          <li margin={0.5} key={index}>
                            <Chip
                              sx={{
                                bgcolor: theme.palette.chip.waitingReturn,
                                color: "white",
                              }}
                              label={grupo.name}
                            />
                          </li>
                        ))}
                      </Box>
                    ) : null}
                    {editar ? (
                      <Autocomplete
                        multiple
                        sx={{ width: "100%" }}
                        id="grupo-hosts-autocomplete"
                        isOptionEqualToValue={(option, value) =>
                          option.groupid === value.groupid
                        }
                        disableCloseOnSelect
                        value={groupHosts}
                        defaultValue={[]}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              groupid: `${
                                options.length + groupHosts.length + 2
                              }`,
                              name: inputValue,
                            });
                          }
                          return filtered;
                        }}
                        onChange={(event, newValue) => {
                          {
                            /* const isExisting = groupHostList.some(
                          (option) =>
                            option.name === newValue[newValue.length - 1].name
                        );
                        if (!isExisting) {
                          // adcionar novo grupo de host a lista de grupos de hosts
                        }*/
                          }
                          if (newValue && newValue.name) {
                            setGroupHosts({
                              nome: newValue.inputValue,
                            });
                          } else {
                            setGroupHosts(newValue);
                          }
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.name;
                        }}
                        options={[]}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Grupos"
                            variant="standard"
                          />
                        )}
                      />
                    ) : null}

                    <Stack direction={"row"} alignItems={"center"}>
                      <Box width="100%">
                        <Typography
                          color={"primary"}
                          fontWeight={500}
                          mt="15px"
                        >
                          Templates
                        </Typography>
                        {!editar && <Divider sx={{ mt: "5px" }} />}
                      </Box>
                    </Stack>
                    {!editar ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        gap={"5px"}
                        component="ul"
                      >
                        {templates?.length == 0 && (
                          <>
                            <li margin={0.5} key={"semGupos"}>
                              <Chip
                                sx={{
                                  bgcolor: theme.palette.background.arquive,
                                }}
                                label={"Sem Templates"}
                              />
                            </li>
                          </>
                        )}
                        {templates.map((template, index) => (
                          <li margin={0.5} key={index}>
                            <Chip
                              sx={{
                                bgcolor: theme.palette.chip.waitingReturn,
                                color: "white",
                              }}
                              label={template.name}
                            />
                          </li>
                        ))}
                      </Box>
                    ) : null}
                    {editar ? (
                      <Autocomplete
                        sx={{ width: "100%" }}
                        multiple
                        id="templates-autocomplete"
                        isOptionEqualToValue={(option, value) =>
                          option.templateid === value.templateid
                        }
                        disableCloseOnSelect
                        value={templates}
                        defaultValue={[]}
                        onChange={(event, newValue) => {
                          if (newValue && newValue.inputValue) {
                            setTemplates({
                              nome: newValue.inputValue,
                            });
                          } else {
                            setTemplates(newValue);
                          }
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.name;
                        }}
                        options={templatesList}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Templates"
                            variant="standard"
                          />
                        )}
                      />
                    ) : null}
                    <Stack direction={"row"} alignItems={"center"}>
                      <Box width="100%">
                        <Typography
                          color={"primary"}
                          fontWeight={500}
                          mt="15px"
                        >
                          Macros
                        </Typography>
                        {!editar && <Divider sx={{ mt: "5px" }} />}
                      </Box>

                      {editar && (
                        <IconButton onClick={addMacro}>
                          {editar && <Add color="primary" />}
                        </IconButton>
                      )}
                    </Stack>
                    {!editar ? (
                      <Stack
                        direction="column"
                        justifyContent={"space-around"}
                        // alignItems={"flex-start"}
                        minWidth={"400px"}
                        gap={"10px"}
                        margin={"0px"}
                      >
                        <Stack
                          direction="row"
                          justifyContent="start"
                          key={"title"}
                          gap={"10px"}
                        >
                          <Typography
                            sx={[styles.textTitle, { width: "220px" }]}
                          >
                            {" "}
                            Macro
                          </Typography>
                          <Typography
                            sx={[styles.textTitle, { width: "220px" }]}
                          >
                            {" "}
                            Valor
                          </Typography>
                        </Stack>
                        {macros.map((macro, index) => (
                          <Stack
                            direction="row"
                            justifyContent="start"
                            key={index}
                            gap={"10px"}
                          >
                            <Box sx={styles.MacroCampo}>{macro.macro}</Box>
                            <Box sx={styles.MacroCampo}>{macro.valor}</Box>
                          </Stack>
                        ))}
                      </Stack>
                    ) : null}
                    {editar ? (
                      <>
                        {macros.map((macro, index) => (
                          <Stack
                            key={index}
                            direction={"row"}
                            justifyContent={"start"}
                            alignItems={"center"}
                            gap={2}
                          >
                            <TextField
                              id="macro-textfield"
                              name="macro"
                              variant="standard"
                              label="Macro"
                              sx={styles.textfield}
                              value={macros[index].macro}
                              onChange={(event) =>
                                handleMacroChange(index, event)
                              }
                            />
                            <TextField
                              id="valor-textfield"
                              name="valor"
                              variant="standard"
                              label="Valor"
                              value={macros[index].valor}
                              sx={styles.textfield}
                              onChange={(event) =>
                                handleMacroChange(index, event)
                              }
                            />
                            {macros.length > 1 && (
                              <IconButton
                                onClick={() => removeMacro(index)}
                                sx={{ mt: 3 }}
                              >
                                <Delete color="secondary" />
                              </IconButton>
                            )}
                          </Stack>
                        ))}
                      </>
                    ) : null}
                    <Box width="100%">
                      <Typography color={"primary"} fontWeight={500} mt="15px">
                        Configurações do modelo
                      </Typography>
                      {!editar && <Divider sx={{ mt: "5px" }} />}
                    </Box>

                    {editar && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={ssh}
                              onChange={() => setSsh(!ssh)}
                            />
                          }
                          label="SSH"
                          m={"0px"}
                        />
                        {ssh && (
                          <Stack
                            direction="row"
                            gap={2}
                            mt={"-10px"}
                            mb={"10px"}
                          >
                            <TextField
                              id="valor-textfield"
                              disabled={!ssh}
                              name="SSH"
                              variant="standard"
                              label={"User SSH"}
                              value={userSSH}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setUserSSH(event.target.value)
                              }
                            />
                            <TextField
                              id="valor-textfield"
                              disabled={!ssh}
                              name="Porta"
                              variant="standard"
                              type="number"
                              label={"Porta SSH"}
                              value={portaSSH}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setportaSSH(event.target.value)
                              }
                            />
                            <TextField
                              id="valor-textfield"
                              disabled={!ssh}
                              name="Senha"
                              variant="standard"
                              label={"Senha SSH"}
                              value={senhaSSH}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setSenhaSSH(event.target.value)
                              }
                            />
                          </Stack>
                        )}

                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={telnet}
                              onChange={() => setTelnet(!telnet)}
                            />
                          }
                          label={
                            <Typography fontSize="16px">Telnet</Typography>
                          }
                        />

                        {telnet && (
                          <Stack
                            direction="row"
                            gap={2}
                            mt={"-10px"}
                            mb={"10px"}
                          >
                            <TextField
                              id="valor-textfield"
                              name="SSH"
                              variant="standard"
                              label={"User Telnet"}
                              value={userTelnet}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setUserTelnet(event.target.value)
                              }
                            />
                            <TextField
                              id="valor-textfield"
                              name="Porta"
                              variant="standard"
                              label={"Porta Telnet"}
                              value={portaTelnet}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setportaTelnet(event.target.value)
                              }
                            />
                            <TextField
                              id="valor-textfield"
                              name="Senha "
                              variant="standard"
                              label={"Senha Telnet"}
                              value={senhaTelnet}
                              sx={styles.textfield}
                              onChange={(event) =>
                                setSenhaTelnet(event.target.value)
                              }
                            />
                          </Stack>
                        )}
                      </>
                    )}

                    {!editar && (
                      <>
                        {!telnet && !ssh && groupHosts?.length == 0 && (
                          <Chip
                            sx={{
                              bgcolor: theme.palette.background.arquive,
                              width: "150px",
                            }}
                            label={"Sem Configurações"}
                          />
                        )}

                        {telnet && (
                          <Stack direction="row" gap={2}>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {" "}
                                User Telnet
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {userTelnet}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {"Porta Telnet"}
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {portaTelnet}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {" "}
                                {"Senha Telnet"}
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {senhaTelnet}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                        {ssh && (
                          <Stack direction="row" gap={2} mt={"10px"}>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {" "}
                                User SSH
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {userSSH}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {"Porta SSH"}
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {portaSSH}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="column"
                              alignItems="start"
                              width={"220px"}
                            >
                              <Typography sx={styles.textTitle}>
                                {" "}
                                {"Senha SSH"}
                              </Typography>
                              <Typography sx={styles.textData}>
                                {" "}
                                {senhaSSH}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </>
                    )}
                  </Stack>
                </Paper>
              </Box>
            ) : null}
          </Fade>
        )}
      </Stack>
      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle color={"primary"}>Adicionar fabricante</DialogTitle>
        <DialogContent>
          <TextField
            id="fabricante"
            variant="standard"
            label="Fabricante"
            value={fabricante}
            onChange={(event) => {
              setFabricante(event.target.value);
            }}
            sx={{ width: "240px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenEditModal(false);
              setFabricante("");
            }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              handleApiCriaFabricante();
              setFabricante("");
              setOpenEditModal(false);
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
