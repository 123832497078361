import {
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreVert,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import useStyles from "./styles";
import { DataGrid } from "@mui/x-data-grid";

export default function MobileTable(params) {
  const {
    rows,
    columns,
    headerMobile = () => {
      <></>;
    },
    columnVisibility = false,
    onOptionsClick = () => {},
    noRowsOverlay = () => {},
    //rowsPerPage = 25,
  } = params;

  const [expanded, setExpanded] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((exp) => (panel === exp ? false : panel));
  };

  const rowsPageZero = rows.slice(page * rowsPerPage, 1 * rowsPerPage);

  const rowsPageNotZero = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const rowsPage = page === 0 ? rowsPageZero : rowsPageNotZero;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userLoggedId = JSON.parse(localStorage.getItem("1"))?.refreshToken
    ?.usuarioId;

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}>
        <DataGrid {...params} rows={rowsPage} />
      </Box>

      <Box sx={{ display: { xs: "flex", sm: "none" }, width: "100%" }}>
        <Box sx={styles.container}>
          {(!rowsPage || rowsPage.length === 0) && (
            <Box sx={styles.containerNoRows}>
              <Box sx={styles.boxNoRows}>{noRowsOverlay()}</Box>
            </Box>
          )}
          {rowsPage.map((row) => {
            return (
              <Accordion
                key={row?.id}
                sx={styles.accordion}
                disableGutters
                variant="outlined"
                expanded={expanded === row?.id}
                onChange={() => {}}
                onClick={handleChange(row?.id)}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton sx={styles.optionsIcon}>
                      <ExpandMore />
                    </IconButton>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={styles.sumary}
                >
                  {headerMobile(row)}

                  <IconButton
                    sx={styles.optionsIcon}
                    onClick={(e) => onOptionsClick(e, row)}
                    disabled={userLoggedId === row?.id}
                  >
                    <MoreVert />
                  </IconButton>
                </AccordionSummary>
                <Divider sx={styles.divider} />
                <AccordionDetails sx={styles.details}>
                  {columns?.map((column) => {
                    return (
                      (!columnVisibility ||
                        columnVisibility[column?.field]) && (
                        <Box key={column?.field} sx={styles.boxDetails}>
                          <Typography sx={styles.textTitle}>
                            {column?.headerName}
                          </Typography>
                          {column?.renderCell ? (
                            column?.renderCell({
                              field: column?.field,
                              row: row,
                              value: row[column?.field],
                            })
                          ) : column?.valueGetter ? (
                            <Typography sx={styles.textContent}>
                              {column?.valueGetter(row[column?.field], row)}
                            </Typography>
                          ) : (
                            <Typography sx={styles.textContent}>
                              {row[column?.field]}
                            </Typography>
                          )}
                        </Box>
                      )
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Box>
      <Box
        direction={"row"}
        justifyContent={"center"}
        sx={{ margin: 0, padding: 0 }}
      >
        {Array.isArray(rows) && rows.length > 0 && (
          <TablePagination
            component="div"
            count={rows.length}
            labelRowsPerPage="Linhas por página:"
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>
  );
}
