import { AddAPhoto } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";

import Resizer from "react-image-file-resizer";
import { useNotification } from "../notificationProvider";
import { useRef } from "react";

export default function ImageButton(params) {
  const notify = useNotification();
  const {
    title = "Adicionar imagem",
    setFoto = () => {},
    callback = () => {},
    children,
    ...buttonProps
  } = params;

  const inputFile = useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file?.size >= 1024 * 300) {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        80,
        0,
        (uri) => {
          setFoto(uri);
        },
        "base64",
        500,
        500
      );
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {};
      fileReader.onerror = (error) => {
        console.log("Error:", error);
        notify("Error:" + error, "error");
      };
    } else {
      notify("Tamanho máximo permitido é 300KB", "error");
      if (inputFile.current) {
        inputFile.current.value = "";
      }
    }
  };
  console.log(buttonProps);
  return (
    <Tooltip title={title}>
      {buttonProps.isButton ? (
        <Button {...buttonProps} component="label">
          {children}
          <input
            hidden
            accept=".png, .jpg, .jpeg"
            type="file"
            ref={inputFile}
            onChange={handleChange}
          />
        </Button>
      ) : (
        <IconButton {...buttonProps} component="label">
          {children}
          <input
            hidden
            accept=".png, .jpg, .jpeg"
            type="file"
            onChange={handleChange}
          />

          <AddAPhoto fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
}
