import React, { useEffect, useState } from "react";

import { Box, CssBaseline } from "@mui/material";

//import Notificacoes from "./notificacoes";

import AppBar from "./appBar";

import { Outlet } from "react-router-dom";

//import ModalPerfil from "../perfil";

function Layout(params) {
  const { colorMode, setColorMode, socket } = params;

  const [openRightBar, setOpenRightBar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    let sidebarOpen = JSON.parse(localStorage.getItem("sidebarCollapsed"));
    if (sidebarOpen !== null) setOpenRightBar(sidebarOpen);
  }, []);

  const handleRightBarToggle = () => {
    setOpenRightBar((preValue) => {
      localStorage.setItem("sidebarCollapsed", JSON.stringify(!preValue));
      return !preValue;
    });
    //setOpenDrawer(false);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer((preValue) => !preValue);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        colorMode={colorMode}
        setColorMode={setColorMode}
        handleDrawerToggle={handleDrawerToggle}
        handleRightBarToggle={handleRightBarToggle}
        socket={socket}
        setOpenDrawer={setOpenDrawer}
        params={params}
      />
      <Box sx={{ height: "64px", width: "100%" }} />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Outlet
          context={[openRightBar, setOpenRightBar, openDrawer, setOpenDrawer]}
        />
      </Box>
    </Box>
  );
}

export default Layout;
