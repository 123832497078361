import {
  ArrowDropDown,
  BackspaceOutlined,
  Close,
  EventNote,
  FilterAltOutlined,
  Key,
  Person,
  Verified,
  KeyOutlined,
  Add,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  DialogActions,
} from "@mui/material";

import {
  DateCalendar,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { ptBR as ptBRdatePicker } from "@mui/x-date-pickers/locales";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { useState, useMemo } from "react";
import useStyles from "./style";

const ModalProfileStatus = ({ anchorEl, setAnchorEl, setProfileStatus }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setProfileStatus(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem
        sx={{ width: "100px" }}
        onClick={() =>
          handleClose({
            value: true,
            label: "Ativo",
          })
        }
      >
        Ativo
      </MenuItem>
      <MenuItem
        onClick={() =>
          handleClose({
            value: false,
            label: "Aguardando",
          })
        }
      >
        Aguardando
      </MenuItem>
    </Menu>
  );
};

const ModalPermission = ({ anchorEl, setAnchorEl, setPermissionStatus }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setPermissionStatus(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "adm001", label: "Adm001" })}
      >
        Adm 001
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "adm002", label: "Adm002" })}
      >
        Adm 002
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "dev001", label: "Dev 001" })}
      >
        Dev 001
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "dev002", label: "Dev 002" })}
      >
        Dev 002
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "noc001", label: "Noc 001" })}
      >
        Noc 001
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "noc002", label: "Noc 002" })}
      >
        Noc 002
      </MenuItem>
    </Menu>
  );
};

const ModalDate = ({ anchorEl, setAnchorEl, ...props }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={"modal-data"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          boxShadow: "0px 4px 14px 0px  #0000002c",
        },
      }}
    >
      <>
        {
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"pt-br"}
          >
            <DateCalendar
              value={props.value ? dayjs(props.value) : new Date(null)}
              {...props}
            />
          </LocalizationProvider>
        }
        <Box>
          <Button
            //sx={styles.buttonModalDate}
            disabled={!props.value}
            color="error"
            startIcon={<BackspaceOutlined />}
            onClick={() => {
              props.handleClear();
              handleClose();
            }}
          >
            Limpar
          </Button>
        </Box>
      </>
    </Popover>
  );
};

const ModalSector = ({ anchorEl, setAnchorEl, setSector }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setSector(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem onClick={() => handleClose({ value: "DEV", label: "Dev" })}>
        Dev
      </MenuItem>
      <MenuItem onClick={() => handleClose({ value: "NOC", label: "Noc" })}>
        Noc
      </MenuItem>
      <MenuItem onClick={() => handleClose({ value: "IOT", label: "Iot" })}>
        IOT
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "VENDARKETING", label: "Vendas" })}
      >
        Vendas
      </MenuItem>
      <MenuItem
        onClick={() =>
          handleClose({ value: "FINANCEIRO", label: "Financeiro" })
        }
      >
        Financeiro
      </MenuItem>

      <MenuItem
        onClick={() =>
          handleClose({ value: "NOC-EASYFLOW", label: "Noc-EasyFlow" })
        }
      >
        Noc EasyFlow
      </MenuItem>
      <MenuItem
        onClick={() =>
          handleClose({ value: "NOC-EASYMON", label: "Noc-EasyMon" })
        }
      >
        Noc EasyMon
      </MenuItem>
    </Menu>
  );
};

const ModalFiltersMobile = (props) => {
  const {
    openModal,
    setOpenMobileModal = () => {},
    profileStatus = { label: "", value: "" },
    setProfileStatus = () => {},
    permissionStatus = { label: "", value: "" },
    setPermissionStatus = () => {},
    sector = { label: "", value: "" },
    setSector = () => {},
  } = props;
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClose = () => {
    setOpenMobileModal(false);
    setPermissionStatus({ label: "", value: "" });
    setProfileStatus({ label: "", value: "" });
    setSector({ label: "", value: "" });
  };

  const handleClearFilters = () => {
    setPermissionStatus({ label: "", value: "" });
    setSector({ label: "", value: "" });
    setProfileStatus({ label: "", value: "" });
  };
  return (
    <Modal open={openModal} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={styles.dialogTitle}>
          <Box sx={styles.boxHeader}>
            <IconButton size="small">
              <FilterAltOutlined sx={{ color: "primary.main" }} />
            </IconButton>
            <Typography fontWeight={500}>Filtros:</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Status Perfil</Typography>
                <TextField
                  select
                  size="small"
                  value={profileStatus.value}
                  onChange={(e) => setProfileStatus({ value: e.target.value })}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <Verified sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value={true} label="Ativo">
                    Ativo
                  </MenuItem>
                  <MenuItem value={false} label="Aguardando">
                    Aguardando
                  </MenuItem>
                </TextField>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Permissão</Typography>
                <TextField
                  size="small"
                  select
                  value={permissionStatus.value}
                  onChange={(e) =>
                    setPermissionStatus({ value: e.target.value })
                  }
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <KeyOutlined sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value="adm001">Adm 001</MenuItem>
                  <MenuItem value="adm002">Adm 002</MenuItem>
                  <MenuItem value="dev001">Dev 001</MenuItem>
                  <MenuItem value="dev002">Dev 002</MenuItem>
                  <MenuItem value="noc001">Noc 001</MenuItem>
                  <MenuItem value="noc002">Noc 002</MenuItem>
                </TextField>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Setor</Typography>
                <TextField
                  size="small"
                  select
                  value={sector.value}
                  onChange={(e) => setSector({ value: e.target.value })}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <Person sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value="DEV">Dev</MenuItem>
                  <MenuItem value="NOC">Noc</MenuItem>
                  <MenuItem value="IOT">IOT</MenuItem>
                  <MenuItem value="VENDARKETING">Vendas</MenuItem>
                  <MenuItem value="FINANCEIRO">Financeiro</MenuItem>

                  <MenuItem value="NOC-EASYFLOW">Noc EasyFlow</MenuItem>
                  <MenuItem value="NOC-EASYMON">Noc EasyMon</MenuItem>
                </TextField>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              handleClearFilters();
            }}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenMobileModal(false);
            }}
            sx={styles.buttonModalMobileFilters}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default function Filters(props) {
  const {
    profileStatus,
    setProfileStatus,
    permissionStatus,
    setPermissionStatus,
    sector,
    setSector,
    criadoEm,
    setCriadoEm,
    ultimoAcesso,
    setUltimoAcesso,
    handleNewOpen,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElPermission, setAnchorElPermission] = useState(null);
  const [anchorElSector, setAnchorSector] = useState(null);
  const [openMobileModal, setOpenMobileModal] = useState(false);

  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClick = (event, filterType) => {
    if (filterType === "status") {
      setAnchorElStatus(event.currentTarget);
    } else if (filterType === "permission") {
      setAnchorElPermission(event.currentTarget);
    } else if (filterType === "createdAt") {
      setAnchorElDate(event.currentTarget);
    } else if (filterType === "lastLogin") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorSector(event.currentTarget);
    }
  };
  const handleCleanFilters = () => {
    setCriadoEm("");
    setUltimoAcesso("");
    setPermissionStatus({
      value: "",
      label: "",
    });
    setSector({ value: "", label: "" });
    setProfileStatus({
      value: "",
      label: "",
    });
  };
  const formatteDate = (date) => {
    return dayjs(date).locale("pt-br").format("D [de] MMM, YYYY");
  };

  const filtersCount = useMemo(() => {
    let filtersCount = 0;
    if (profileStatus.value.toString()) {
      filtersCount += 1;
    }
    if (permissionStatus.value.toString()) {
      filtersCount += 1;
    }
    if (sector.value.toString()) {
      filtersCount += 1;
    }
    if (criadoEm) {
      filtersCount += 1;
    }
    return filtersCount;
  }, [openMobileModal]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: { xs: "fit-content", md: "100%" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={styles.backgroundBox}>
          <Grid2
            container
            rowSpacing={1}
            spacing={1}
            sx={styles.gridItemDesktop}
          >
            <Grid2 item size={styles.sizeGridItem}>
              <Box sx={styles.boxTitle}>
                <FilterAltOutlined sx={styles.size} />
                <Typography sx={styles.text}>{"Filtros:"}</Typography>
              </Box>
            </Grid2>
            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "status")}
              >
                <Verified sx={styles.size} />
                <Typography
                  sx={styles.text}
                >{`Status perfil: ${profileStatus?.label} `}</Typography>
                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalProfileStatus
                anchorEl={anchorElStatus}
                setAnchorEl={setAnchorElStatus}
                setProfileStatus={setProfileStatus}
              />
            </Grid2>

            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "permission")}
              >
                <Key sx={styles.size} />
                <Typography
                  sx={styles.text}
                >{`Permissão: ${permissionStatus?.label}`}</Typography>
                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalPermission
                anchorEl={anchorElPermission}
                setAnchorEl={setAnchorElPermission}
                setPermissionStatus={setPermissionStatus}
              />
            </Grid2>

            {/* <Grid2 item size={styles.sizeGridItem}>
          <Paper
            variant="brick"
            sx={styles.paperBrick}
            onClick={(e) => handleClick(e, "createdAt")}
          >
            <EventNote sx={styles.size} />
            <Typography sx={styles.text}>
              {`Criado em: ${!!criadoEm ? formatteDate(criadoEm) : ""}`}
            </Typography>

            <ArrowDropDown sx={styles.size} />
          </Paper>
          <ModalDate
            anchorEl={anchorElDate}
            setAnchorEl={setAnchorElDate}
            value={criadoEm || null}
            handleClear={() => setCriadoEm(null)}
            onChange={(newValue) => setCriadoEm(dayjs(newValue))}
          />
        </Grid2>

        <Grid2 item size={styles.sizeGridItem}>
          <Paper
            variant="brick"
            sx={styles.paperBrick}
            onClick={(e) => handleClick(e, "lastLogin")}
          >
            <EventNote sx={styles.size} />
            <Typography sx={styles.text}>
              {`Último acesso: ${
                !!ultimoAcesso ? formatteDate(ultimoAcesso) : ""
              }`}
            </Typography>

            <ArrowDropDown sx={styles.size} />
          </Paper>
          <ModalDate
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            value={criadoEm || null}
            handleClear={() => setUltimoAcesso(null)}
            onChange={(newValue) => setUltimoAcesso(dayjs(newValue))}
          />
        </Grid2> */}
            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "sector")}
              >
                <Person sx={styles.size} />
                <Typography sx={styles.text}>
                  <Typography
                    sx={styles.text}
                  >{`Setor: ${sector?.label}`}</Typography>
                </Typography>

                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalSector
                anchorEl={anchorElSector}
                setAnchorEl={setAnchorSector}
                setSector={setSector}
              />
            </Grid2>

            {profileStatus.label ||
            permissionStatus.label ||
            sector.label ||
            criadoEm ||
            ultimoAcesso ? (
              <Grid2 item size={styles.sizeGridItem}>
                <Box sx={styles.boxTitle}>
                  <Button
                    sx={styles.buttonClearFilters}
                    startIcon={<Close />}
                    onClick={handleCleanFilters}
                    variant="text"
                  >
                    Limpar Filtros
                  </Button>
                </Box>
              </Grid2>
            ) : null}
          </Grid2>
        </Box>
        <Grid2 item size={styles.sizeGridItem}>
          <Button
            startIcon={<Add />}
            onClick={handleNewOpen}
            sx={styles.button}
            variant="contained"
          >
            Novo Usuário
          </Button>
        </Grid2>
      </Box>

      <ModalFiltersMobile
        openModal={openMobileModal}
        setOpenMobileModal={setOpenMobileModal}
        profileStatus={profileStatus}
        setProfileStatus={setProfileStatus}
        permissionStatus={permissionStatus}
        setPermissionStatus={setPermissionStatus}
        sector={sector}
        setSector={setSector}
      />
      <Tooltip title="Adicionar filtros">
        <Button
          sx={styles.buttonFilterMobile}
          onClick={() => setOpenMobileModal(true)}
          variant="contained"
        >
          <FilterAltOutlined sx={styles.size} />
          <Badge badgeContent={filtersCount} sx={styles.badge} />
        </Button>
      </Tooltip>
    </>
  );
}
