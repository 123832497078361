import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Alert,
  Snackbar,
  Button,
  Icon,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import mwIcon from "../../../../assets/logo4.png";
import { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";

import AccordionGroup from "./accordionGroup";
import api from "../../../../services/api";
import ModalNovoCliente from "./modalNovoCliente";
import useStyles from "./styles";
import { useOutletContext } from "react-router-dom";

export default function Acessos(props) {
  const [tabValue, setTabValue] = useState(
    JSON.parse(localStorage.getItem("filtroAcesso"))?.parceiro || "mw"
  );
  const [snackbar, setSnackbar] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingPage, setLoadingPage] = useState(false);
  const [filterText, setFilterText] = useState(
    JSON.parse(localStorage.getItem("filtroAcesso"))?.cliente || ""
  );
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("smd"));
  const isSmd = useMediaQuery(theme.breakpoints.up("smd"));

  const { openRightBar } = useOutletContext();

  const styles = useStyles({ openRightBar });

  const handleFilterChange = (event, newValue) => {
    setFilterText(newValue || "");
    setCurrentPage(1);
    localStorage.setItem(
      "filtroAcesso",
      JSON.stringify({ cliente: newValue, parceiro: tabValue })
    );
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const filteredOParceiros = clientes.filter(
    (cliente) => cliente.parceiro?.toLowerCase() === tabValue?.toLowerCase()
  );

  const filteredClientes = Array.isArray(filteredOParceiros)
    ? filteredOParceiros.filter((cliente) =>
        `${cliente.numero} - ${cliente.empresa}`
          ?.toLowerCase()
          ?.includes(filterText.toLowerCase())
      )
    : [];

  const indexOfLastCard = currentPage * rowsPerPage;
  const indexOfFirstCard = indexOfLastCard - rowsPerPage;
  const paginatedClientes = filteredClientes.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  async function handleApi() {
    try {
      const response = await api.get("/acessos");
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setClientes(response.data);
        setLoadingPage(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function atualizarCliente(clientesNovoProduto) {
    const novoArray = clientes.map((obj) => {
      if (obj.id === clientesNovoProduto.id) {
        return clientesNovoProduto;
      } else {
        return obj;
      }
    });
    setClientes(novoArray);
  }

  function deletarCliente(id) {
    const novosClientes = clientes.filter((objeto) => objeto.id !== id);
    setClientes(novosClientes);
  }

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem("cliente"));
    if (filter) {
      setFilterText(filter);
    }
    handleApi();
  }, []);

  // useEffect(() => {
  // setFilterText("");
  // }, [tabValue]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Box sx={styles.content}>
          <Stack justifyContent={"center"} sx={{ width: "100%" }}>
            <Paper sx={styles.paper}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                  setFilterText("");
                  localStorage.setItem(
                    "filtroAcesso",
                    JSON.stringify({ cliente: "", parceiro: newValue })
                  );
                }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                sx={styles.tabs}
              >
                <Tab label="MW" value={"mw"} />
                <Tab label="CDM" value={"cdm"} />
                <Tab label="MCD" value={"mcd"} />
                <Tab label="IPV7" value={"ipv7"} />
                <Tab label="PRO" value={"PRO"} />
                <Tab label="NTK" value={"ntk"} />
                <Tab label="ADV" value={"adv"} />
                <Tab label="PEV7" value={"pev7"} />
                <Tab label="PR" value={"pr"} />
                <Tab label="ISPTI" value={"ispti"} />
                <Tab label="SV2" value={"SV2"} />
                <Tab label="SRT" value={"SRT"} />
                <Tab label="INN" value={"inn"} />
              </Tabs>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                paddingX="10px"
                width={"100%"}
                gap={"10px"}
                sx={styles.tabs}
              >
                <Autocomplete
                  disablePortal
                  freeSolo
                  options={filteredOParceiros.map(
                    (cliente) => `${cliente.numero} - ${cliente.empresa}`
                  )}
                  sx={{ m: 1, maxWidth: "300px" }}
                  fullWidth
                  value={filterText}
                  onChange={handleFilterChange}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Consultar Cliente"
                      fullWidth
                    />
                  )}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  sx={{ mt: 2 }}
                >
                  {isXs ? "Novo" : isSmd ? "Novo Cliente" : "Novo Cliente"}
                  <Add sx={{ mt: -0.4 }} />
                </Button>
              </Stack>
            </Paper>
          </Stack>
          {!loadingPage && (
            <Box sx={styles.LoadingLogo}>
              <Icon style={{ width: 80, height: 80 }}>
                <img
                  src={mwIcon}
                  style={{ width: 80, height: 80 }}
                  alt="mw icon"
                />
              </Icon>

              <CircularProgress
                size={100}
                sx={styles.CircularProgress}
                thickness={2.5}
              />
            </Box>
          )}
          {loadingPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                marginTop: "20px",
                flexWrap: "wrap",
                gap: "10px",
                width: "100%",
              }}
            >
              {paginatedClientes.map((cliente, index) => {
                return (
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    key={cliente.id}
                  >
                    <AccordionGroup
                      cliente={cliente}
                      atualizarCliente={atualizarCliente}
                      deletarCliente={deletarCliente}
                    />
                  </Stack>
                );
              })}
            </Box>
          )}
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          sx={{ marginTop: "20px" }}
          width={"100%"}
        >
          <TablePagination
            component="div"
            count={filteredClientes.length}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            pageSizeOptions={[10, 20, 40]}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </Stack>
      </Box>
      <ModalNovoCliente
        setOpenModal={setOpenModal}
        openModal={openModal}
        setClientes={setClientes}
        tabValue={tabValue}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
