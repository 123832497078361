import { Close, KeyOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  Paper,
  Typography,
  Switch,
  Grid2,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useNotification } from "../../../componentes/notificationProvider";

export default function ModalEditPermission(params) {
  const {
    openEditPermissionModal,
    setOpenEditPermissionModal,
    selectedRow,
    rows,
    setRows,
    role,
  } = params;
  const [permission, setPermission] = useState([]);
  const [hasProfile, setHasProfile] = useState(false);
  const isSuperAdmin = role.includes("adm001");
  const notify = useNotification();

  useEffect(() => {
    if (selectedRow?.Perfil) {
      setPermission(selectedRow?.Perfil?.codigoPermissao?.split(",") || []);
      setHasProfile(true);
    }
  }, [selectedRow]);

  const styles = {
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
    paper: {
      padding: 2,
      maxWidth: "540px",
      width: "90%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    boxDialogTitle: {
      display: "flex",
      flexDirection: "row",
      gap: 2,
      marginBottom: 1,
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    foto: {
      width: "80px",
      height: "80px",
    },
    fotoModalPermission: {
      width: "50px",
      height: "50px",
    },
    textfield: {
      width: "100%",
      minWidth: "min-content",
    },
    name: {
      color: "text.primary",
      fontWeight: 500,
    },
    login: {
      color: "text.secondary",
      fontWeight: 400,
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
      paddingBottom: 1,
    },
    boxRowTitlesAndSwitch: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: 1,
    },
    divider: { mt: 1, mb: 1 },
    title: {
      fontWeight: 500,
      color: "text.primary",
      fontSize: "1em",
    },
    subtitle: {
      fontWeight: 500,
      color: "text.secondary",
      fontSize: "0.875em",
    },
    boxLabelsSwitch: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      alignItems: "start",
    },
    label: {
      marginTop: "20px",
      fontWeight: 500,
      color: "text.primary",
    },
    subLabel: {
      fontWeight: 400,
      color: "text.secondary",
    },

    buttonCancelDialog: {
      color: "primary.main",
      textTransform: "none",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: 0,
    },
    boxFoto: {
      display: "flex",
      width: "440px",
      height: "80px",
      justifyContent: "space-between",
      alignItems: "start",
    },
    boxContainerFoto: {
      display: "flex",
      alignItems: " center",
      gap: "12px",
    },
    formLabel: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "noWrap",
      marginLeft: 4,
      width: "90%",
    },
  };

  const handleChange = (event) => {
    const { value, checked } = event.target;
    setPermission((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((perm) => perm !== value);
      }
    });
  };

  const handleUpdatePermission = async (id, permission) => {
    try {
      const response = await api.put("/administracao/permissoes/update", {
        id: id,
        permissoes: permission.join(","),
      });
      if (response.data) {
        const updateRows = rows;
        updateRows.map((row) => {
          if (row?.id === selectedRow.id) {
            const codPermission = response.data.codigoPermissao;

            if (codPermission) row.Perfil.codigoPermissao = codPermission;
          }
        });
        notify("Permissão atualizada com sucesso!", "success");
        setRows(updateRows);
      }
    } catch (error) {
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Erro ao atualizar dados!", "error");
      console.log(error);
    } finally {
      setOpenEditPermissionModal(false);
    }
  };

  return (
    <Modal
      open={openEditPermissionModal}
      onClose={() => {
        setOpenEditPermissionModal(false);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={{ padding: 0 }}>
          <Box sx={styles.boxHeader}>
            {!hasProfile ? (
              <Box sx={styles.boxDialogTitle}>
                <Typography sx={styles.login}>{selectedRow?.login}</Typography>
              </Box>
            ) : (
              <Box sx={styles.boxDialogTitle}>
                <Avatar
                  alt={"foto de perfil"}
                  src={selectedRow?.Perfil?.foto}
                  sx={styles.fotoModalPermission}
                />
                <Box>
                  <Typography sx={styles.name}>
                    {selectedRow?.Perfil?.nome} {selectedRow?.Perfil?.sobrenome}
                  </Typography>
                  <Typography sx={styles.login}>
                    {selectedRow?.login}
                  </Typography>
                </Box>
              </Box>
            )}

            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenEditPermissionModal(false);
              }}
              sx={styles.iconButton}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Divider />
          <Box sx={styles.boxTitle}>
            <IconButton>
              <KeyOutlined sx={{ color: "primary.main" }} />
            </IconButton>
            <Typography fontWeight={500}>Permissões</Typography>
          </Box>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  disabled={!isSuperAdmin}
                  onChange={handleChange}
                  checked={permission.includes("adm001")}
                  value="adm001"
                />
                <Box>
                  <Typography sx={styles.title}>Adm001</Typography>
                  <Typography sx={styles.subtitle}>
                    Acesso a página de administração, pode remover e conceder
                    qualquer permissões para outros usuários.
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  onChange={handleChange}
                  checked={permission.includes("adm002")}
                  value="adm002"
                />
                <Box>
                  <Typography sx={styles.title}>Adm002</Typography>
                  <Typography sx={styles.subtitle}>
                    Acesso a página de administração, pode remover e conceder
                    algumas permissões para outros usuários exceto a usuário com
                    permissão superior
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  onChange={handleChange}
                  checked={permission.includes("dev001")}
                  value="dev001"
                />
                <Box>
                  <Typography sx={styles.title}>Dev001</Typography>
                  <Typography sx={styles.subtitle}>
                    Acesso desenvolvedor admin
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  onChange={handleChange}
                  checked={permission.includes("dev002")}
                  value="dev002"
                />
                <Box>
                  <Typography sx={styles.title}>Dev002</Typography>
                  <Typography sx={styles.subtitle}>
                    Acesso padrão de desenvolvedor
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  onChange={handleChange}
                  checked={permission.includes("noc001")}
                  value="noc001"
                />
                <Box>
                  <Typography sx={styles.title}>Noc001</Typography>
                  <Typography sx={styles.subtitle}>Acesso Noc admin</Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 item size={12}>
              <Box sx={styles.boxRowTitlesAndSwitch}>
                <Switch
                  onChange={handleChange}
                  checked={permission.includes("noc002")}
                  value="noc002"
                />
                <Box>
                  <Typography sx={styles.title}>Noc002</Typography>
                  <Typography sx={styles.subtitle}>
                    Acesso padrão Noc
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            sx={styles.buttonCancelDialog}
            onClick={() => {
              setOpenEditPermissionModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={() => {
              handleUpdatePermission(selectedRow.id, permission);
            }}
            disabled={!permission.length}
          >
            Salvar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
