import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object

const URL = window?.config?.REACT_APP_BASE_URL;
const USUARIO_ID = JSON.parse(localStorage.getItem("1"))?.usuario?.id;

export const socket = io(URL, {
  reconnection: true,
  autoConnect: false,
  query: {
    usuarioId: USUARIO_ID,
  },
  auth: {
    token: window?.config?.REACT_APP_SOCKET_TOKEN,
  },
});
