import { Box, FormControl, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Code({ onChange }) {
  const [codigo, setCodigo] = useState(["", "", "", "", "", ""]); // Inicializa um array de 6 strings vazias

  useEffect(() => {
    onChange(codigo);
  }, [codigo]);

  const handleInputChange = (e, i) => {
    let value = e.target.value
      .replace(/\D/g, "")
      .substring(e.target.value.length - 1, e.target.value.length);
    let newCodigo = [...codigo];
    newCodigo[i] = value;
    setCodigo(newCodigo);
    if (i < 5) {
      document.getElementById(i + 1 + "_code").focus();
    }
  };
  const handlePaste = (e, i) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").replace(/\D/g, "");
    let array = [];
    for (let j = 0; j < 6; j++) {
      array[j] = pastedText.split("")[j];
      if (array[j] === undefined) array[j] = "";
    }
    setCodigo(array);
  };

  const elementos = [];
  for (let i = 0; i < 6; i++) {
    elementos.push(
      <FormControl
        key={i + "_form_code"}
        sx={{ width: "15%" }}
        variant="outlined"
      >
        <OutlinedInput
          id={i + "_code"}
          maxLength="1"
          type="text"
          value={codigo[i]}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          onPaste={(e) => handlePaste(e, i)}
          onChange={(e) => handleInputChange(e, i)} // Passa uma função de retorno de chamada
        />
      </FormControl>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {elementos}
    </Box>
  );
}
