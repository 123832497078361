import {
  Alert,
  Box,
  Button,
  FormControl,
  Icon,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Link,
  Snackbar,
  Stack,
  Typography,
  CircularProgress,
  useTheme,
  TextField,
} from "@mui/material";
import axios from "axios";
import mwIcon from "../../assets/logo4.png";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/headerHome.jpeg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../assets/logo4.png";
import logo2 from "../../assets/logo5.png";
import Code from "./textFieldCode";
import Interval from "./contador";
import ReCAPTCHA from "react-google-recaptcha";

export default function RedefinirSenha() {
  const [rev, setRev] = useState(false);
  const [email, setEmail] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [sended, setSended] = useState(false);
  const [user, setUser] = useState("");
  const [code, setCode] = useState("");
  const [codeAlert, setCodeAlert] = useState(false);
  const [id, setId] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const intervalRef = useRef(null);
  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  const navigate = useNavigate();

  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const styles = {
    LoadingLogo: {
      position: "relative",
      margin: "50px",
    },
    CircularProgress: {
      color: "primary",
      position: "absolute",
      top: -9,
      left: -6,
      zIndex: 1,
    },
    TextCampo: {
      mt: 1,
      mb: 2,
      width: "200px",
    },
    ContainerBox: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperPhoto: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "65vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
      objectFit: "cover",
    },
    boxTitlePhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    title: {
      fontSize: { lg: "3em", md: "2em" },
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
      maxWidth: "max-content",
      textWrap: "wrap",
    },
    subTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    boxHeaderAndForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      height: "100vh",
      gap: { xs: 6, lg: 18 },
      padding: 4,
      width: { xs: "100vw", md: "35vw" },
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
      padding: 2,
      gap: "auto",
    },
    icon: { height: "auto", width: "auto" },
    img: { height: "auto", width: "120px" },
    PaperForm: {
      width: { xs: "80vw", smd: "30vw" },
      minWidth: "310px",
      height: "90vh",
      minHeight: "50vh",
      maxHeight: "600px",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    boxForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
      width: { xs: "80vw", md: "100%" },
      maxWidth: "520px",
      padding: 2,
    },
    boxFormCode: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      gap: 2,
      width: { xs: "90%", md: "70%" },
      maxWidth: "520px",
      padding: 2,
    },
    textField: {
      width: "100%",
      marginTop: 2,
    },
    titleForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
      alignSelf: "stretch",
    },
    boxTitles: { gap: 2, display: "flex", flexDirection: "column" },
    boxFieldAndButton: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 3,
      alignSelf: "stretch",
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      marginTop: "10px",
      height: "40px",
      marginBottom: "20px",
      fontSize: 18,
      textTransform: "none",
    },
    formCodeContent: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      alignSelf: "stretch",
    },
    codeErrorMsg: {
      fontSize: "13px",
      marginTop: "-10px",
      color: "#e83750",
      marginBottom: "-20px",
    },
  };

  useEffect(() => {
    if (email.includes("@") && email.includes(".")) setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  const handleNextUpdatePassword = async () => {
    try {
      const response = await axios.post(
        `${url}/usuario/resetPassword/validate`,
        {
          code,
          usuarioId: id,
        }
      );

      navigate(`/resetPassword/${response.data.token}/${id}`);
    } catch (error) {
      if (error?.response?.status == 401) {
        setCodeAlert(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
  };

  const handleSend = async () => {
    if (!email || !emailValid) {
      setNull(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${url}/usuario/resetPassword/request`,
        {
          email: email.toLowerCase(),
        }
      );
      setUser(response.data.login);
      setId(response.data.usuarioId);

      setSended(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 404) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
    setLoading(false);
  };

  //function handleStartCounter() {
  //  if (intervalRef.current) {
  //    intervalRef.current.startCounter();
  //  }
  //}

  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.boxHeaderAndForm}>
          <Box sx={styles.boxHeader}>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              color="primary.main"
              disabled={loading}
              href="/login"
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
            <Icon style={styles.icon}>
              <img
                alt=""
                src={colorMode === "light" ? logo : logo2}
                style={styles.img}
              />
            </Icon>
          </Box>

          <Stack sx={styles.PaperForm}>
            {loading && (
              <Box sx={styles.boxFormCode}>
                <Box sx={styles.LoadingLogo}>
                  <Icon style={{ width: 80, height: 80 }}>
                    <img
                      src={mwIcon}
                      style={{ width: 80, height: 80 }}
                      alt="mw icon"
                    />
                  </Icon>

                  <CircularProgress
                    size={100}
                    sx={styles.CircularProgress}
                    thickness={2.5}
                  />
                </Box>
              </Box>
            )}
            {!loading && !sended && (
              <Box sx={styles.boxForm}>
                <Box sx={styles.boxTitles}>
                  <Typography
                    fontSize="2em"
                    fontWeight={400}
                    color="text.primary"
                  >
                    Esqueceu a senha?
                  </Typography>
                  <Typography
                    fontSize="0.875em"
                    fontWeight={400}
                    color="text.secondary"
                  >
                    Digite o endereço de e-mail verificado da sua conta de
                    usuário e lhe enviaremos um link de redefinição de senha.
                  </Typography>
                </Box>
                <Box sx={styles.boxFieldAndButton}>
                  <TextField
                    error={Null && !emailValid}
                    id="emailredefine"
                    variant="standard"
                    value={email}
                    type="text"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    label="Email"
                    helperText={Null && !emailValid && "Email inválido"}
                    sx={styles.textField}
                  />

                  <ReCAPTCHA
                    sitekey={window?.config?.REACT_APP_SITE_KEY || ""}
                    theme={colorMode === "light" ? "light" : "dark"}
                    onChange={setCaptcha}
                  />

                  <Button
                    variant="contained"
                    disabled={!captcha}
                    onClick={() => {
                      handleSend();
                    }}
                    sx={styles.button}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>
            )}
            {!loading && sended && (
              <Box sx={styles.boxFormCode}>
                <Box sx={styles.titleForm}>
                  <Typography
                    sx={{ fontSize: { xs: 20, sm: 40 } }}
                    color="text.primary"
                    fontWeight={500}
                  >
                    Redefinição de Senha
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    color="text.secondary"
                  >
                    Enviamos um código para {email.toLowerCase()}
                  </Typography>
                </Box>
                <Box sx={styles.formCodeContent}>
                  <Code onChange={(value) => setCode(value.join(" "))} />
                  {codeAlert && (
                    <Typography sx={styles.codeErrorMsg}>
                      O código não está correto
                    </Typography>
                  )}

                  <Button
                    variant="contained"
                    disabled={!captcha}
                    onClick={handleNextUpdatePassword}
                    sx={styles.button}
                  >
                    Verificar
                  </Button>

                  <Interval
                    onTimeout={(value) => setRev(true)}
                    ref={intervalRef}
                  />
                  {rev && (
                    <Link
                      onClick={() => {
                        handleSend();
                        //handleStartCounter();
                        setRev(false);
                      }}
                      href="#"
                      sx={{
                        width: "100%",
                        textDecoration: "none!important",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      Reenviar email de redefinição
                    </Link>
                  )}
                </Box>
              </Box>
            )}
          </Stack>
        </Paper>
        <Paper sx={styles.paperPhoto}>
          <Box sx={styles.boxTitlePhoto}>
            <Typography sx={styles.title}>
              Monitoramento eficiente e confiável.
            </Typography>
            <br></br>
            <Typography sx={styles.subTitle}>
              Monitoramento de infraestrutura de redes para provedores de
              internet, com suporte confiável e atencioso.
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: Esse email não está cadastrado</Alert>
      </Snackbar>

      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
