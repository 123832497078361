import {
  Alert,
  Autocomplete,
  InputAdornment,
  Box,
  Fade,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Skeleton,
  Link,
  Card,
  Divider,
  IconButton,
  Drawer,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import imgEmpty from "../../../../assets/wallet.svg";
import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TablePagination from "@mui/material/TablePagination";

export default function Zabbix() {
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [predefinicoes, setPredefinicoes] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [hostsPagination, setHostsPagination] = useState([]);
  const [hostSelected, setHostSelected] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [grupoHosts, setGrupoHosts] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [loadingGrupoHosts, setLoadingGrupoHosts] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(false);
  //variaveis de selecionados
  const [template, setTemplate] = useState([]);
  const [fabricante, setFabricante] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [modelo, setModelo] = useState([]);
  const [grupoHost, setGrupoHost] = useState([]);
  const [disableButtonStart, setDisableButtonStart] = useState(false);
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    function paginationImage() {
      let hostsFilter = hosts,
        select = [];

      for (
        let index = currentPage * rowsPerPage - rowsPerPage;
        index < currentPage * rowsPerPage;
        index++
      ) {
        if (hostsFilter[index] !== undefined) select.push(hostsFilter[index]);
      }
      setHostsPagination(select);
    }
    paginationImage();
  }, [rowsPerPage, currentPage, hosts]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const styles = {
    cicle: {
      borderRadius: "50%",
      width: "23px",
      height: "23px",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      backgroundColor: "red",
      right: " -5%",
      marginTop: "-7px",
      backgroundColor: "chip.waitingReview",
    },
    chip: {
      color: "white",
      width: "150px",
      height: "25px",
      overflow: "hidden",
      textOverflow: " ellipsis",
    },
    box: {
      transition: "width 0.5s",
    },
    title: {
      fontWeight: 500,
      color: "primary.main",
      fontSize: "1.1rem",
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      minWidth: "1000px",
      width: "100%",
      m: "auto",
    },
    paperCliente: {
      borderRadius: 1,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "20px",
      width: "100%",

      flexDirection: "column",
    },
    paper: {
      maxWidth: "1550px",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      elevation: 3,
      gap: "20px",
      marginTop: "10px",
      mb: "100px",
    },
    backgroundTagCard: {
      background: theme.palette.mode == "dark" ? "rgba(0,0,0,0.5)" : "#5B2E87",
      color: "white",
    },
    tagTipoTemplate: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      fontSize: "11px",
      borderRadius: "3px",
    },
    itemContentTemplate: {
      color: "GrayText",
      fontSize: "13px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "240px",
    },
    itemTituloTemplate: {
      mt: 1,
      fontSize: "0.9rem",
      color: "invert(100%)",
      width: "100%",
      textAlign: "center",
    },
    itemTituloKey: {
      fontSize: "13px",
      color: "invert(100%)",
      width: "100%",
      textAlign: "left",
    },
    contentItens: {
      display: "flex",
      flexDirection: "column",
      height: "590px",
      mt: 1.5,
      ml: 2.5,

      width: "75%",
    },
    contentItensCards: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      overflow: "auto",
      gap: 1.5,
      maxHeight: "530px",
    },
    chipAlertItem: {
      bgcolor: "chip.inProgress",
      color: "white",

      height: "20px",
    },
    chipAlertVersion: {
      bgcolor: "chip.waitingReview",
      color: "white",

      height: "20px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  };

  const toggleWidth = (host) => {
    if (host.id !== hostSelected?.id) {
      setHostSelected(host);
      setIsExpanded(true);
      return;
    }
    if (!isExpanded) setHostSelected(host);
    else setHostSelected(null);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetGrupos();
    handleApiGetTemplates();
  }, []);

  async function handleApiGetTemplates() {
    try {
      setLoadingTemplates(true);
      const response = await api.get("/filtroDeHosts/templates/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os templates",
          severity: "error",
        });
      } else {
        if (Array.isArray(response.data)) {
          setTemplates(
            response.data.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
    } finally {
      setLoadingTemplates(false);
    }
  }

  async function handleApiGetGrupos() {
    try {
      setLoadingGrupoHosts(true);
      const response = await api.get("/filtroDeHosts/groups/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os grupos",
          severity: "error",
        });
      } else {
        if (Array.isArray(response.data)) {
          setGrupoHosts(
            response.data.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
    } finally {
      setLoadingGrupoHosts(false);
    }
  }

  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/getPredefinicoes");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os dados",
          severity: "error",
        });
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data);
        }
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
    } finally {
      setLoadingPredefinicoes(false);
    }
  }

  async function handleApiGetHostsFilter() {
    try {
      setLoadingPage(true);
      const response = await api.post("/filtroDeHosts/hosts/find", {
        groupFilterId: grupoHost?.id || null,
        templateFilterId: template?.id || null,
        funcaoFilterId: funcao?.id || null,
        modeloFilterId: modelo?.id || null,
        fabricanteFilterId: fabricante?.id || null,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os hosts",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        if (response.data) {
          setHosts(
            response.data.sort((a, b) =>
              a.AcessoClientes.empresa.localeCompare(b.AcessoClientes.empresa)
            )
          );
          if (response.data.length === 0) {
            setSnackbar({
              children: "Aviso: Nenhum host foi encontrado",
              severity: "success",
            });
          }
        }
        setLoadingPage(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
      setLoadingPage(false);
    }
  }

  function renderArrayCell(params) {
    const arrayValue = params.value;

    return (
      <Stack>
        {arrayValue?.map((a) => (
          <Chip label={a.templates.name || a.grupos.name} />
        ))}
      </Stack>
    );
  }

  const filterFuncoes = () => {
    return fabricante?.Funcoes?.flat();
  };

  const filterModelos = () => {
    return fabricante?.Funcoes?.flatMap((funcao) => funcao.Modelos)?.flat();
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  function CardHost({ host }) {
    return (
      <Card
        //key={d.name + index}
        sx={{
          width: "100%",
          height: "110px",
          display: "flex",
          flexDirection: "rows",
        }}
      >
        <Stack
          sx={{
            backgroundColor: "primary.main",
            height: "150px",
            padding: "0px 3px",
          }}
        ></Stack>
        <Stack sx={{ padding: "10px 15px", width: "98%" }}>
          <Typography fontSize="19px">
            <Tooltip title="Acessar o Zabbix">
              <>
                {/* {typeData.slice(0, -1) + " : "} */}
                <Link
                  href={
                    host?.AcessoProduto?.AcessoLink[0].link +
                    "/hosts.php?filter_host=" +
                    host?.hostname?.replace(/ /g, "+") +
                    "&filter_set=1"
                  }
                  underline="hover"
                  target="blank_"
                >
                  {host?.AcessoClientes?.numero +
                    " - " +
                    host?.AcessoClientes?.empresa}
                </Link>
              </>
            </Tooltip>
          </Typography>

          <Stack direction="row" spacing={2} mt="-7px">
            <Box>
              <Typography sx={styles.itemTituloTemplate}>Host:</Typography>
              <Tooltip title="Clique para copiar">
                <Chip
                  label={host?.hostname}
                  sx={[
                    styles.chip,
                    {
                      width: "250px",
                      bgcolor: host?.AcessoClientes?.empresa
                        ? "chip.waitingReturn"
                        : "chip.inProgress",
                      cursor: "pointer",
                      "&.MuiButtonBase-root.MuiChip-root:hover": {
                        bgcolor: host?.AcessoClientes?.empresa
                          ? "chip.waitingReturn"
                          : "chip.inProgress",
                      },
                    },
                  ]}
                  onClick={() => {
                    handleCopy(host?.hostname);
                  }}
                />
              </Tooltip>
            </Box>
            <Box>
              <Typography sx={styles.itemTituloTemplate}>IP:</Typography>
              <Tooltip title="Clique para copiar">
                <Chip
                  label={host?.ip || "Não encontrado"}
                  sx={[
                    styles.chip,
                    {
                      bgcolor: host?.ip
                        ? "chip.waitingReturn"
                        : "chip.inProgress",
                      cursor: "pointer",
                      "&.MuiButtonBase-root.MuiChip-root:hover": {
                        bgcolor: host?.AcessoClientes?.empresa
                          ? "chip.waitingReturn"
                          : "chip.inProgress",
                      },
                    },
                  ]}
                  onClick={() => {
                    handleCopy(host?.ip || "Não encontrado");
                  }}
                />
              </Tooltip>
            </Box>
            <Box>
              <Typography sx={styles.itemTituloTemplate}>
                Fabricante:
              </Typography>

              <Chip
                label={host?.Fabricante?.fabricante || "Não encontrado"}
                sx={[
                  styles.chip,
                  {
                    bgcolor: host?.Fabricante?.fabricante
                      ? "chip.waitingReturn"
                      : "chip.inProgress",
                  },
                ]}
              />
            </Box>
            <Box>
              <Typography sx={styles.itemTituloTemplate}>Função:</Typography>

              <Chip
                label={host?.Funcao?.funcao || "Não encontrado"}
                sx={[
                  styles.chip,
                  {
                    bgcolor: host?.Funcao?.funcao
                      ? "chip.waitingReturn"
                      : "chip.inProgress",
                  },
                ]}
              />
            </Box>
            <Box>
              <Typography sx={styles.itemTituloTemplate}>Modelo:</Typography>

              <Chip
                label={host?.Modelo?.modelo || "Não encontrado"}
                sx={[
                  styles.chip,
                  {
                    bgcolor: host?.Modelo?.modelo
                      ? "chip.waitingReturn"
                      : "chip.inProgress",
                  },
                ]}
              />
            </Box>

            <Box>
              <Typography sx={styles.itemTituloTemplate}>Templates:</Typography>
              <Stack direction="row" position="relative">
                <Chip
                  label={host?.Templates[0]?.templates?.name || "Sem Templates"}
                  sx={[
                    styles.chip,
                    {
                      bgcolor: host?.Templates[0]?.templates?.name
                        ? "chip.waitingReturn"
                        : "chip.inProgress",
                    },
                  ]}
                />
                {host?.Templates?.length > 1 && (
                  <Box sx={[styles.chip, styles.cicle]}>
                    {"+" +
                      (host?.Templates?.length - 1 > 99
                        ? "99"
                        : host?.Templates?.length - 1)}
                  </Box>
                )}
              </Stack>
            </Box>
            <Box>
              <Typography sx={styles.itemTituloTemplate}>Grupos:</Typography>

              <Stack direction="row" position="relative">
                <Chip
                  label={host.Grupos[0]?.grupos?.name || "Não encontrado"}
                  sx={[
                    styles.chip,
                    {
                      bgcolor: host.Grupos[0]?.grupos?.name
                        ? "chip.waitingReturn"
                        : "chip.inProgress",
                    },
                  ]}
                />

                {host.Grupos?.length > 1 && (
                  <Box sx={[styles.chip, styles.cicle]}>
                    {"+" +
                      (host?.Grupos?.length - 1 > 99
                        ? "99"
                        : host?.Grupos?.length - 1)}
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="row"
          width="50px"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            onClick={() => {
              toggleWidth(host);
            }}
            aria-label="delete"
            size="medium"
          >
            {hostSelected?.id !== host.id && (
              <ArrowForwardIosIcon fontSize="inherit" />
            )}
            {hostSelected?.id === host.id && (
              <ArrowBackIosNewIcon fontSize="inherit" color="primary" />
            )}
          </IconButton>
        </Stack>
      </Card>
    );
  }

  function transformarData() {
    let updateAt = hostSelected.updateAt;
    let formattedDate = "";
    let statusAtt = "chip.pending";
    if (updateAt) {
      let lastUpdate = getLastScheduledDate(hostSelected.LastUpdate);
      let updateDate = new Date(updateAt);
      let updateTimestamp = updateDate.getTime();
      let lastUpdateTimestamp = lastUpdate.getTime();

      console.log(lastUpdate);

      // Define a cor com base na comparação entre as datas
      if (updateTimestamp > lastUpdateTimestamp) {
        statusAtt = "chip.completed";
      }

      // Formata a data para exibição
      let day = String(updateDate.getDate()).padStart(2, "0");
      let month = String(updateDate.getMonth() + 1).padStart(2, "0");
      let year = updateDate.getFullYear();
      formattedDate = `${day}/${month}/${year}`;
    } else {
      statusAtt = "chip.completed";
      formattedDate = "Novo host";
    }
    return { formattedDate, statusAtt };
  }

  function getLastScheduledDate(cronExpression) {
    // Separar os campos da expressão de cron
    const fields = cronExpression.split(" ");

    // Extrair os valores relevantes
    const hour = parseInt(fields[2]); // hora
    const dayOfWeek = parseInt(fields[5]); // dia da semana

    // Obter a data e hora atuais
    const now = new Date();

    // Definir a data e hora do último evento agendado
    const lastEventDate = new Date(now);

    // Definir a hora
    lastEventDate.setHours(hour, 0, 0, 0);

    // Calcular o dia da semana do último evento agendado
    let lastEventDay = lastEventDate.getDay();

    // Ajustar para garantir que seja anterior ao dia da semana especificado na expressão de cron
    while (lastEventDay !== dayOfWeek) {
      lastEventDate.setDate(lastEventDate.getDate() - 1); // Retroceder um dia
      lastEventDay = lastEventDate.getDay();
    }

    return lastEventDate;
  }

  return (
    <>
      <Box sx={styles.container}>
        <Box width="100%">
          <Paper sx={styles.paperCliente}>
            <Typography sx={styles.title}>Filtro de Hosts</Typography>
            <Stack
              direction={"row"}
              gap={4}
              justifyContent={"space-between"}
              width="100%"
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={3}>
                <Autocomplete
                  value={template}
                  onChange={(event, newInputValue) => {
                    setTemplate(newInputValue);
                  }}
                  freeSolo
                  options={templates.map((template) => ({
                    label: template.name,
                    id: template.id,
                  }))}
                  getOptionLabel={(options) => options.label || ""}
                  sx={{ width: "200px" }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Template"
                        variant="standard"
                      />
                      <Fade in={loadingTemplates}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
                <Autocomplete
                  value={grupoHost}
                  onChange={(event, newInputValue) => {
                    setGrupoHost(newInputValue);
                  }}
                  freeSolo
                  options={grupoHosts.map((grupo) => ({
                    label: grupo.name,
                    id: grupo.id,
                  }))}
                  getOptionLabel={(options) => options.label || ""}
                  sx={{ width: "200px" }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Grupo de host"
                        variant="standard"
                      />

                      <Fade in={loadingGrupoHosts}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
                <Autocomplete
                  value={fabricante}
                  onChange={(event, newInputValue) => {
                    setFabricante(newInputValue);
                    setFuncao([]);
                    setModelo([]);
                  }}
                  freeSolo
                  options={predefinicoes.map((options) => options)}
                  getOptionLabel={(options) => options.fabricante || ""}
                  sx={{ width: "200px" }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Fabricante"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
                <Autocomplete
                  disabled={!fabricante?.fabricante}
                  value={funcao}
                  onChange={(event, newInputValue) => {
                    setFuncao(newInputValue);
                    setModelo([]);
                  }}
                  freeSolo
                  options={filterFuncoes()}
                  getOptionLabel={(options) => options.funcao || ""}
                  sx={{ width: "200px" }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Função"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />

                <Autocomplete
                  disabled={!funcao?.funcao}
                  value={modelo}
                  onChange={(event, newInputValue) => {
                    setModelo(newInputValue);
                  }}
                  freeSolo
                  options={filterModelos()}
                  getOptionLabel={(options) => options.modelo || ""}
                  sx={{ width: "200px" }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Modelo"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Stack>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="contained"
                  sx={{ height: "40px" }}
                  disabled={disableButtonStart || loadingPage}
                  onClick={() => {
                    handleApiGetHostsFilter();
                    setIsExpanded(false);
                    setCurrentPage(1);
                  }}
                  endIcon={<TroubleshootIcon />}
                >
                  Filtrar Hosts
                </Button>
                {loadingPage && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Paper>

          {hosts.length > 0 && !loadingPage && (
            <Stack direction="row" width={"100%"} mt={3} height="auto">
              <Stack
                sx={styles.box}
                spacing={1}
                direction="column"
                style={{
                  width: "100%",
                  overflow: "auto",
                  maxHeight: "100%",
                  display: "flow",
                }}
              >
                {hostsPagination.map((host) => (
                  <CardHost host={host} key={host.id + "hostss"} />
                ))}
              </Stack>
              {/* <Stack sx={[styles.box, { padding: isExpanded ? "0px 10px" : null, width: isExpanded ? '30%' : '0%' }]}>
                                {isExpanded && (
                                    <Card sx={{ padding: "20px", height: "100%" }}>
                                        <Stack minWidth="400px">
                                            <Typography sx={[styles.title, { fontSize: "1rem" }]}>Especificações do host: </Typography>
                                            <Typography sx={{ fontSize: "1.2rem", color: "white" }}>{hostSelected.hostname}</Typography>

                                            <Typography mt={2} sx={[styles.title, { fontSize: "1rem" }]}>Grupos:</Typography>
                                            <Stack
                                                mt={1}
                                                direction="row"
                                                justifyContent="start"
                                                gap={1}
                                                flexWrap="wrap"
                                            >
                                                {hostSelected?.Grupos.length == 0 && (<Chip sx={{ width: "min-content" }} label={"Sem grupos"} />)}
                                                {hostSelected?.Grupos?.map(grupo => (
                                                    <Chip key={grupo.grupos.name + hostSelected.id} sx={{ width: "min-content", bgcolor: "chip.waitingReturn", color: "#ffffff" }} label={grupo.grupos.name} />
                                                ))}
                                            </Stack>
                                            <Typography mt={2} sx={[styles.title, { fontSize: "1rem" }]}>Templates:</Typography>
                                            <Stack
                                                mt={1}
                                                direction="row"
                                                justifyContent="start"
                                                gap={1}
                                                flexWrap="wrap"
                                            >
                                                {hostSelected?.Templates.length == 0 && (<Chip sx={{ width: "min-content" }} label={"Sem Templates"} />)}
                                                {hostSelected?.Templates?.map(tem => (
                                                    <Chip key={tem.templates.name + hostSelected.id} sx={{ width: "min-content", bgcolor: "chip.waitingReturn", color: "#ffffff" }} label={tem.templates.name} />
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </Card>
                                )}
                            </Stack> */}
              <Drawer
                open={isExpanded}
                anchor={"right"}
                onClose={() => {
                  setIsExpanded(false);
                  setHostSelected(null);
                }}
              >
                {isExpanded && (
                  <Card sx={{ padding: "20px", height: "100%" }}>
                    <Stack width="450px">
                      <Typography sx={[styles.title, { fontSize: "1rem" }]}>
                        Especificações do host:{" "}
                      </Typography>
                      <Typography sx={{ fontSize: "1.2rem", color: "white" }}>
                        {hostSelected.hostname}
                      </Typography>

                      <Typography
                        mt={2}
                        sx={[styles.title, { fontSize: "1rem" }]}
                      >
                        Grupos:
                      </Typography>
                      <Stack
                        mt={1}
                        direction="row"
                        justifyContent="start"
                        gap={1}
                        flexWrap="wrap"
                      >
                        {hostSelected?.Grupos.length == 0 && (
                          <Chip
                            sx={{ width: "min-content" }}
                            label={"Sem grupos"}
                          />
                        )}
                        {hostSelected?.Grupos?.map((grupo) => (
                          <Chip
                            key={grupo.grupos.name + hostSelected.id}
                            sx={{
                              width: "min-content",
                              bgcolor: "chip.waitingReturn",
                              color: "#ffffff",
                            }}
                            label={grupo.grupos.name}
                          />
                        ))}
                      </Stack>
                      <Typography
                        mt={2}
                        sx={[styles.title, { fontSize: "1rem" }]}
                      >
                        Templates:
                      </Typography>
                      <Stack
                        mt={1}
                        direction="row"
                        justifyContent="start"
                        gap={1}
                        flexWrap="wrap"
                      >
                        {hostSelected?.Templates.length == 0 && (
                          <Chip
                            sx={{ width: "min-content" }}
                            label={"Sem Templates"}
                          />
                        )}
                        {hostSelected?.Templates?.map((tem) => (
                          <Chip
                            key={tem.templates.name + hostSelected.id}
                            sx={{
                              width: "min-content",
                              bgcolor: "chip.waitingReturn",
                              color: "#ffffff",
                            }}
                            label={tem.templates.name}
                          />
                        ))}
                      </Stack>
                      <Typography
                        mt={2}
                        sx={[styles.title, { fontSize: "1rem" }]}
                      >
                        Última atualização:
                      </Typography>
                      <Stack
                        mt={1}
                        direction="row"
                        justifyContent="start"
                        gap={1}
                        flexWrap="wrap"
                      >
                        <Chip
                          key={hostSelected.updateAt + hostSelected.id}
                          sx={{
                            width: "min-content",
                            bgcolor: transformarData().statusAtt,
                            color: "#ffffff",
                          }}
                          label={transformarData().formattedDate}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                )}
              </Drawer>
            </Stack>
          )}

          {hosts.length == 0 && !loadingPage && (
            <Box
              width="100%"
              height="300px"
              sx={{
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                filter: "opacity(0.6)",
                mt: 1,
              }}
            >
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Nenhum Host
              </Typography>
            </Box>
          )}
          {loadingPage && (
            <Stack width="100%" flexDirection="row" gap={2} padding="20px 0px">
              <Skeleton variant="rounded" width="100%" height={450} />
            </Stack>
          )}
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ marginTop: "20px", mb: "50px" }}
          >
            <TablePagination
              component="div"
              count={hosts.length}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50]}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Stack>
        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
