import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
  Avatar,
  Badge,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  createSvgIcon,
  Menu,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Fade,
  LinearProgress,
} from "@mui/material";
import {
  Edit,
  Delete,
  Settings,
  Error,
  ExpandMore,
  UnarchiveOutlined,
  ArchiveOutlined,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../../../services/api";

import Chat from "./chat";
import ModalDemanda from "./modalNovaDemanda";
import ModalEditaDemanda from "./modalEditaDemanda";
import AlertModal from "../../../../componentes/alertModal";
import dayjs from "dayjs";
import { ptBR } from "@mui/x-data-grid/locales";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: "960px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 0,
    gap: "1rem",
  },

  paper: {
    width: "100%",
    minWidth: "900px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  accordion: {
    width: "100%",
    minWidth: "900px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 1,
  },
  TypographyAccordion: {
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  campoFiltro: {
    width: "220px",
  },
  campoFiltroAutocomplete: {
    width: "460px",
  },
  button: { height: "40px", minWidth: "150px", m: 1 },
  TypographyCampo: { fontSize: 15 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  dangerousIcon: { fontSize: 60 },
};
const CommentIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="css-1ticslg"
  >
    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
  </svg>
);

export default function Suporte(params) {
  //filtros
  const { socket } = params;
  const [solicitante, setSolicitante] = useState("");
  const [status, setStatus] = useState("");
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data, setData] = useState(dayjs(new Date()));
  const [dataChange, setDataChange] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const [responsavel, setResponsavel] = useState([{ nome: "" }]);
  const [checkedFilter, setCheckedFilter] = useState(true);
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  //dialogs
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [disableButtonArquive, setDisableButtonArquive] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  //Datagrid
  const [rows, setRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [newRow, setNewRow] = useState("");
  const [updateRow, setUpdateRow] = useState("");
  const [maxCharLength, setMaxCharLength] = useState(40);
  //topicos
  //const [countTopicos, setCountTopicos] = useState(0);
  //
  const [categorias, setCategorias] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  //menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  //acordion
  useEffect(() => {
    if (newRow) {
      setRows([newRow, ...rows]);
    }
  }, [newRow]);

  useEffect(() => {
    if (updateRow) {
      const novoArray = rows.map((row) => {
        if (row.id === updateRow.id) {
          return { ...updateRow };
        } else {
          return row;
        }
      });
      setRows(novoArray);
    }
  }, [updateRow]);
  useEffect(() => {
    //funcao para chamar a api de relatoriosCliente
    handleApiGetRelatorios();
    handleApiGetDados();

    // funcao para atualizar a variavel maxCharLength com base na largura do DataGrid
    const updateMaxCharLength = () => {
      //obtem a largura atual do DataGrid
      const dataGridWidth =
        document.querySelector(".MuiDataGrid-root")?.clientWidth;
      //ajusta a variavel maxCharLength com base na largura do DataGrid
      if (dataGridWidth >= 600) {
        setMaxCharLength(60);
      } else {
        setMaxCharLength(40);
      }
    };
    updateMaxCharLength();
    window.addEventListener("resize", updateMaxCharLength);
    //limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("resize", updateMaxCharLength);
    };
  }, []);

  //funcao para processar os valores retornados pela api para o formato utilizado pela pagina
  function handleRows(params) {
    const pushRows = params.map((dataRow) => {
      let newObj = {};
      newObj.id = dataRow.id;
      newObj.responsavelId = dataRow.responsavel;
      newObj.solicitanteId = dataRow.solicitante;
      newObj.responsavel = dataRow.usuario ? dataRow.usuario.Perfil : "";
      newObj.solicitante = dataRow.usuarioCliente
        ? dataRow.usuarioCliente.PerfilCliente
        : "";
      newObj.codigo = dataRow.codigo;
      newObj.descricao = dataRow.descricao;
      newObj.categoria = dataRow.categoria;
      newObj.subCategoria = dataRow.subCategoria;
      newObj.status = dataRow.status;
      newObj.previsao = dataRow.previsao;
      newObj.data = new Date(new Date(dataRow.createAt) - 3 * 60 * 60 * 1000); //coreção de fuso horario, com diferença de 3 horas
      newObj.topicos = dataRow._count.TopicoRelatorio;
      newObj.cliente = {
        cliente: dataRow.acessoClientes?.empresa,
        numero: dataRow.acessoClientes?.numero,
        id: dataRow.acessoClientes?.id,
      };
      newObj.parceiro = dataRow.acessoClientes?.parceiro;
      newObj.acessoClientesId = dataRow.acessoClientes?.id;
      return newObj;
    });
    setRows(pushRows);
  }
  //funcao para copiar texto para area de transferencia
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  function handleArquiveRow(arquiveRow, newStatus) {
    const novoArray = rows.map((row) => {
      if (row.id === arquiveRow.id) {
        let auxRow = row;
        auxRow.status = newStatus;
        return { ...auxRow };
      } else {
        return row;
      }
    });
    setRows(novoArray);
  }
  function handleDeleteRow(removeRow) {
    const novoArray = rows.filter((row) => row.id !== removeRow.id);
    setRows(novoArray);
  }
  //funcao para filtrar linhas da tabela
  function filteredRows(rows) {
    const auxRows = rows.filter((row, index) => {
      let auxFiltroSolicitante = false;
      if (row.solicitante.pessoaFisica && row.solicitante) {
        auxFiltroSolicitante = row.solicitante.nome
          ?.toLowerCase()
          .includes(solicitante?.toLowerCase());
      } else {
        auxFiltroSolicitante = row.solicitante
          ? row.solicitante.nomeFantasia
              ?.toLowerCase()
              .includes(solicitante?.toLowerCase())
          : true;
      }
      const filtroSolicitante = auxFiltroSolicitante;

      let aux = false;
      let auxFiltro = false;
      if (responsavel.length < 1) {
        aux = true;
      } else {
        responsavel.forEach((obj) => {
          auxFiltro = row.responsavel
            ? row.responsavel.nome
                ?.toLowerCase()
                .includes(obj.nome?.toLowerCase())
            : true;
          if (auxFiltro === true) {
            aux = true;
          }
          if (!row.responsavel) {
            aux = false;
          }
        });
      }
      const filtroResponsavel = aux;

      const filtroCliente = cliente
        ? row.cliente?.cliente
            ?.toLowerCase()
            .includes(cliente?.cliente?.toLowerCase())
        : true;

      const filtroParceiro = parceiro
        ? row.parceiro
            ?.toLowerCase()
            .includes(parceiro?.parceiro?.toLowerCase())
        : true;

      const filtroData = dataChange
        ? new Date(row.data)
            .toLocaleDateString()
            .includes(new Date(data).toLocaleDateString())
        : true;

      return (
        filtroData &&
        filtroParceiro &&
        filtroCliente &&
        filtroResponsavel &&
        filtroSolicitante &&
        row.descricao?.toLowerCase().includes(descricao?.toLowerCase()) &&
        row.status?.toString().includes(status?.toString()) &&
        row.codigo?.toLowerCase().includes(codigo?.toLowerCase())
      );
    });
    return auxRows;
  }

  function rowsArquive(rows) {
    const filterPerArquive = rows.filter((row, index) => {
      return row.status === 5;
    });
    return filterPerArquive;
  }
  //funcao para ocultar apenas as demandas finalizadas
  function rowsOffComplete(rows) {
    let filterOffComplete = rows;
    if (checkedFilter) {
      filterOffComplete = rows.filter((row, index) => {
        return row.status !== 2;
      });
    }
    return filterOffComplete;
  }

  function rowsPast(rows) {
    const dataAtual = Date.parse(new Date());
    const filterPerPendant = rows.filter((row, index) => {
      const previsao = Date.parse(row.previsao);
      return previsao < dataAtual && row.status !== 5;
    });
    return filterPerPendant;
  }
  function rowsToday(rows) {
    const now = new Date();
    const inicioDia = new Date(now);
    inicioDia.setHours(0, 0, 0, 0);
    const fimDia = new Date(now);
    fimDia.setHours(23, 59, 59, 999);

    const filterRowsToday = rows.filter((row, index) => {
      const previsao = Date.parse(row.previsao);
      return previsao < fimDia && previsao > inicioDia && row.status !== 5;
    });
    return filterRowsToday;
  }

  function rowsWeek(rows) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const sevenDaysFromNow = new Date(today);
    sevenDaysFromNow.setDate(today.getDate() + 7);
    sevenDaysFromNow.setHours(23, 59, 59, 999);

    const filterRowsWeek = rows.filter((row) => {
      const previsao = Date.parse(row.previsao);
      return (
        previsao >= today && previsao <= sevenDaysFromNow && row.status !== 5
      );
    });

    return filterRowsWeek;
  }

  function rowsAfterWeek(rows) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const sevenDaysLater = new Date(today);
    sevenDaysLater.setDate(today.getDate() + 7);
    sevenDaysLater.setHours(0, 0, 0, 0);

    const filterAfterWeek = rows.filter((row) => {
      const previsao = Date.parse(row.previsao);
      return previsao > sevenDaysLater && row.status !== 5;
    });

    return filterAfterWeek;
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //    setOpenChat(open);
  };
  function atualizaBadgeTopicos(somaTopico) {
    const novoArray = rows.map((row) => {
      if (row.id === selectedRow.id) {
        // newObj = {};
        let newObj = row;
        newObj.topicos = row.topicos + somaTopico;
        return newObj;
      }
      return row;
    });
    setRows(novoArray);
  }
  async function handleApiGetRelatorios() {
    try {
      setLoadingTable(true);
      const response = await api.get("/suporte/findMany");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        setLoadingTable(false);
      } else {
        setLoadingTable(false);
        if (response.data) {
          handleRows(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingTable(false);
    }
  }
  async function handleApiGetDados(params) {
    try {
      setLoading(true);
      let response = {};
      response.categorias = await api.get("/categorias");
      response.subCategorias = await api.get("/subCategorias");
      response.colaboradores = await api.get("/usuarios");
      response.clientes = await api.get("/clientes");

      if (
        response.categorias.data.status === "Error" ||
        response.subCategorias.data.status === "Error" ||
        response.colaboradores.data.status === "Error" ||
        response.clientes.data.status === "Error"
      ) {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        setLoading(false);
      } else {
        setCategorias(response.categorias.data);
        setSubCategorias(response.subCategorias.data);
        setColaboradores(response.colaboradores.data);
        setClientes(response.clientes.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoading(false);
    }
  }
  async function handleApiDelete() {
    try {
      setConfirmarDelete(true);
      const response = await api.put("/suporte/delete", {
        id: selectedRow.id,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível deletar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          handleDeleteRow(response.data);
        }
        setSnackbar({
          children: "Dados deletados com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setOpenDeleteModal(false);
      setConfirmarDelete(false);
    }
  }
  async function handleApiEditStatusRelatorio(newStatus) {
    try {
      setDisableButtonArquive(true);
      const response = await api.put("/suporte/updateStatus", {
        id: selectedRow.id,
        status: newStatus,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setOpenEditDialog(false);
        setDisableButtonArquive(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setDisableButtonArquive(false);
        setOpenEditDialog(false);
        if (response.data) {
          //  handleRows(response.data);
          handleArquiveRow(response.data, newStatus);
        }
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setOpenEditDialog(false);
      setDisableButtonArquive(true);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingTable(false);
    }
  }
  useEffect(() => {
    if (colaboradores) {
      let perfil = localStorage.getItem("2");
      if (perfil !== "undefined" && perfil) {
        perfil = JSON.parse(perfil);
      }
      const colaborador = colaboradores.filter(
        (usuario) => usuario.usuarioId === perfil.usuarioId
      );
      setResponsavel(colaborador);
    }
  }, [colaboradores]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
      renderCell: (params) => {
        const text = params.value || "";
        return (
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text.length > maxCharLength
              ? `${text.substring(0, maxCharLength)}...`
              : text}
          </Box>
        );
      },
    },
    {
      field: "codigo",
      headerName: "Código",
      width: 140,
      renderCell: (params) => (
        <>
          {params.value && (
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.value);
                }}
              >
                {params.value}
              </span>
            </Tooltip>
          )}
        </>
      ),
    },

    {
      field: "responsavelId",
      headerName: "responsavelId",
      width: 90,
      hide: true,
    },
    {
      field: "responsavel",
      headerName: "Responsável",
      width: 180,
      sortable: false,
      //  flex: 0.5,
      renderCell: (params) => {
        let nomeCompleto = "";
        let names = "";
        if (params.value?.nome) {
          nomeCompleto = params.value.sobrenome
            ? params.value.nome?.trim() + " " + params.value.sobrenome?.trim()
            : params.value.nome?.trim() + "";
          names = nomeCompleto.split(" "); // params.value.split(" ") ;
        }

        return (
          <>
            {params.value.nome ? (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Avatar
                  sx={{ bgcolor: "orangered", width: "30px", height: "30px" }}
                  //alt={names[0].toUpperCase()}
                  src={params.value.foto}
                >
                  {" "}
                  {names[0][0].toUpperCase()}
                </Avatar>
                <Tooltip title={nomeCompleto} placement="bottom-end">
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                      width: "140px",
                      textTransform: "capitalize",
                    }}
                  >
                    {names.length <= 1
                      ? params.value.nome
                      : `${names[0]} ${names[1][0]}.`}
                  </Typography>
                </Tooltip>
              </Stack>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                  width: "140px",
                  textTransform: "capitalize",
                }}
              >
                Indefinido
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "solicitanteId",
      headerName: "solicitanteId",
      width: 90,
      hide: true,
    },
    {
      field: "solicitante",
      headerName: "Solicitante",
      width: 180,
      sortable: false,
      //flex: 0.5,
      renderCell: (params) => {
        let names = "";
        let nomeCompleto = "";
        if (params.value) {
          if (params.value.pessoaFisica) {
            nomeCompleto = params.value.sobrenome
              ? params.value.nome?.trim() + " " + params.value.sobrenome?.trim()
              : params.value.nome?.trim();
            names = nomeCompleto.split(" ");
          } else {
            nomeCompleto = params.value.nomeFantasia?.trim();
            names = nomeCompleto.split(" ").filter(Boolean);
          }
        }
        return (
          <>
            {names ? (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Avatar
                  sx={{ bgcolor: "green", width: "30px", height: "30px" }}
                  //alt={names[0].toUpperCase()}
                  src={params.value.foto}
                >
                  {names[0][0].toUpperCase()}
                </Avatar>
                <Tooltip title={nomeCompleto} placement="bottom-end">
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                      width: "140px",
                      textTransform: "capitalize",
                    }}
                  >
                    {names.length <= 1
                      ? `${names[0]}`
                      : `${names[0]} ${names[1][0]}.`}
                  </Typography>
                </Tooltip>
              </Stack>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                  width: "140px",
                  textTransform: "capitalize",
                }}
              >
                Indefinido
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      width: 110,
      //hide: true,
      renderCell: (params) => (
        <>
          {params.value && (
            <Tooltip title={params.value}>
              <Box>{params.value?.toLowerCase()}</Box>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      renderCell: (params) => (
        <>
          {params.value && (
            <Tooltip title={params.value.cliente}>
              <Box>{params.value.cliente}</Box>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {params.value === 0 ? (
            <>
              <Chip
                label={"Pendente"}
                sx={{
                  bgcolor: "chip.pending",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 1 ? (
            <>
              <Chip
                label={"Em andamento"}
                sx={{
                  bgcolor: "chip.inProgress",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 2 ? (
            <>
              <Chip
                label={"Concluída"}
                sx={{
                  bgcolor: "chip.completed",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 3 ? (
            <>
              <Chip
                label={"Aguardando revisão"}
                sx={{
                  bgcolor: "chip.waitingReview",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 4 ? (
            <>
              <Chip
                label={"Aguardando retorno"}
                sx={{
                  bgcolor: "chip.waitingReturn",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 5 ? (
            <>
              <Chip
                label={"Arquivada"}
                sx={{
                  bgcolor: "chip.arquive",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : null}
        </Box>
      ),
    },
    {
      field: "categoria",
      headerName: "Categoria",
      width: 110,
    },
    {
      field: "subCategoria",
      headerName: "Subcategoria",
      width: 180,
      renderCell: (params) => {
        const maxCharLength = 20; // Defina o comprimento máximo do texto antes de exibir os pontos de suspensão
        const text = params.value || "";
        return (
          <Tooltip title={params.value} placement="bottom-end">
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {text.length > maxCharLength
                ? `${text.substring(0, maxCharLength)}...`
                : text}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "data",
      headerName: "Data ",
      //type: "date",
      width: 120,
      renderCell: (params) => (
        <>
          {params.value && (
            <Typography variant="subtitle1" sx={styles.TypographyCampo}>
              {new Date(params.value).toLocaleDateString()}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "previsao",
      headerName: "Previsão",
      //type: "date",
      width: 120,
      renderCell: (params) => {
        const dataAtual = new Date().setHours(0, 0, 0, 0);
        const atrasado = params.row.status;
        return (
          <>
            {Date.parse(params.value) < dataAtual && atrasado !== 2 ? (
              <Tooltip title="Atrasada">
                <Error color="error" />
              </Tooltip>
            ) : (
              ""
            )}
            {params.value && (
              <Typography variant="subtitle1" sx={styles.TypographyCampo}>
                {new Date(params.value).toLocaleDateString()}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "topicos",
      headerName: "",
      sortable: false,
      width: 60,
      // editable: true,
      renderCell: (params) => {
        const onClick = (e) => {
          // setOpenChat(true);
          e.stopPropagation();

          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row;
          navigate(`/noc/clientes/suporte/${thisRow.id}`);
          hanldeUpDateSelectRow(thisRow);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }

        return (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={60}
            sx={{ mt: 0.4 }}
          >
            <IconButton aria-label="topicos" onClick={onClick}>
              <Badge badgeContent={params.value} color="primary">
                {" "}
                <Tooltip title="Tópicos">
                  <CommentIcon />
                </Tooltip>
              </Badge>
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 40,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          // don't select this row after clicking
          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row;

          hanldeUpDateSelectRow(thisRow);
          setAnchorEl(e.currentTarget);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Ações"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Settings color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={styles.container} onClick={toggleDrawer(true)}>
      <Paper sx={styles.paper}>
        <Typography
          variant="h6"
          component={"div"}
          gutterBottom
          sx={styles.TypographyAccordion}
          color="primary"
        >
          Filtrar demandas
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              gap={"20px"}
              //flexWrap={!hiddenFilter ? "wrap" : "nowrap"}
              flexWrap={"wrap"}
              // minWidth={"650px"}
            >
              <Autocomplete
                sx={styles.campoFiltro}
                disabled={loading}
                onChange={(event, newValue) => {
                  setParceiro(newValue);
                  setCliente(null);
                }}
                value={parceiro ? parceiro : null}
                options={clientes.map((cliente) => cliente)}
                getOptionLabel={(option) => option?.parceiro?.toUpperCase()}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Parceiro"
                      variant="standard"
                    />
                    <Fade in={loading}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
              <Autocomplete
                disabled={!parceiro}
                sx={styles.campoFiltro}
                onChange={(event, newValue) => {
                  setCliente(newValue);
                }}
                value={cliente ? cliente : null}
                options={
                  parceiro
                    ? parceiro?.clientes?.map((cliente) => cliente) || []
                    : null
                }
                getOptionLabel={(option) =>
                  `${option.numero} ${option.cliente}`
                }
                renderInput={(params) => (
                  <>
                    <TextField {...params} label="Cliente" variant="standard" />
                    <Fade in={loading}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
              <Autocomplete
                multiple
                id="tags-standard"
                sx={styles.campoFiltroAutocomplete}
                options={colaboradores}
                disabled={loading}
                value={responsavel ? responsavel : null}
                isOptionEqualToValue={(option, value) =>
                  option.nome === value.nome
                }
                onChange={(event, newValue) => {
                  setResponsavel(newValue);
                }}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="standard"
                      label="Responsável"
                    />
                    <Fade in={loading}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
              <TextField
                id="filtro-solicitante"
                variant="standard"
                label="Solicitante"
                value={solicitante}
                sx={styles.campoFiltro}
                onChange={(event) => {
                  setSolicitante(event.target.value);
                }}
                type="text"
              />{" "}
              <TextField
                id="filtro-descricao"
                variant="standard"
                label="Descricao"
                value={descricao}
                sx={styles.campoFiltro}
                onChange={(event) => {
                  setDescricao(event.target.value);
                }}
                type="text"
              />
              <TextField
                id="filtro-codigo"
                variant="standard"
                label="Código"
                value={codigo}
                sx={styles.campoFiltro}
                onChange={(event) => {
                  setCodigo(event.target.value);
                }}
                type="text"
              />
              <FormControl>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  variant="standard"
                  labelId="select-status"
                  id="filtro-status"
                  value={status}
                  sx={styles.campoFiltro}
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Indefinido</MenuItem>
                  <MenuItem value={0}>Pendente</MenuItem>
                  <MenuItem value={1}>Em andamento</MenuItem>
                  <MenuItem value={2}>Concluída</MenuItem>
                  <MenuItem value={3}>Aguardando Revisão</MenuItem>
                  <MenuItem value={4}>Aguardando Retorno</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="data"
                  label="Data de Criação"
                  sx={styles.campoFiltro}
                  color="secondary"
                  value={data}
                  onChange={(newValeu) => {
                    if (newValeu) {
                      setData(newValeu);
                      setDataChange(true);
                    } else {
                      setData(dayjs(new Date()));
                      setDataChange(false);
                    }
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                    textField: {
                      variant: "standard",
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>{" "}
            <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography sx={{ width: "140px", ml: 3 }}>
                Ocultar Concluídas
              </Typography>
              <Checkbox
                checked={checkedFilter}
                onChange={(e) => {
                  setCheckedFilter(e.target.checked);
                }}
              />
            </Stack>
          </Stack>{" "}
          <Button
            variant="contained"
            disabled={loading}
            sx={styles.button}
            onClick={() => {
              setOpenCreateModal(true);
            }}
          >
            CRIAR DEMANDA
          </Button>
        </Stack>
      </Paper>
      <Accordion disableGutters sx={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="demandas-hoje-content"
          id="demandas-hoje-header"
        >
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={styles.TypographyAccordion}
            color="primary"
          >
            Datas Passadas
          </Typography>
        </AccordionSummary>{" "}
        <AccordionDetails>
          <DataGrid
            sx={{
              fontSize: 15,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
            }}
            getRowHeight={() => "auto"}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredRows(rowsOffComplete(rowsPast(rows)))}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  responsavelId: false,
                  solicitanteId: false,
                },
              },
            }}
            loading={loadingTable || loading}
            disableColumnMenu
            //checkboxSelection
            disableRowSelectionOnClick={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters sx={styles.accordion} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="demandas-hoje-content"
          id="demandas-hoje-header"
        >
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={styles.TypographyAccordion}
            color="primary"
          >
            Hoje
          </Typography>
        </AccordionSummary>{" "}
        <AccordionDetails>
          <DataGrid
            sx={{
              fontSize: 15,
              //fontWeight: "900",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "900 !important",
                overflow: "visible !important",
              },
              ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
            }}
            getRowHeight={() => "auto"}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredRows(rowsOffComplete(rowsToday(rows)))}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  responsavelId: false,
                  solicitanteId: false,
                },
              },
            }}
            loading={loadingTable || loading}
            disableColumnMenu
            //checkboxSelection
            disableRowSelectionOnClick={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters sx={styles.accordion} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="demandas-hoje-content"
          id="demandas-hoje-header"
        >
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={styles.TypographyAccordion}
            color="primary"
          >
            Próximos 7 dias
          </Typography>
        </AccordionSummary>{" "}
        <AccordionDetails>
          <DataGrid
            sx={{
              fontSize: 15,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
            }}
            getRowHeight={() => "auto"}
            autoHeight={true}
            // components={{ Toolbar: GridToolbar }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredRows(rowsOffComplete(rowsWeek(rows)))}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  responsavelId: false,
                  solicitanteId: false,
                },
              },
            }}
            loading={loadingTable || loading}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters sx={styles.accordion} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="demandas-hoje-content"
          id="demandas-hoje-header"
        >
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={styles.TypographyAccordion}
            color="primary"
          >
            Demandas Futuras
          </Typography>
        </AccordionSummary>{" "}
        <AccordionDetails>
          <DataGrid
            sx={{
              fontSize: 15,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
            }}
            getRowHeight={() => "auto"}
            autoHeight={true}
            // components={{ Toolbar: GridToolbar }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredRows(rowsOffComplete(rowsAfterWeek(rows)))}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  responsavelId: false,
                  solicitanteId: false,
                },
              },
            }}
            loading={loadingTable || loading}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters sx={styles.accordion} defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="demandas-hoje-content"
          id="demandas-hoje-header"
        >
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={styles.TypographyAccordion}
            color="primary"
          >
            Arquivadas
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            sx={{
              fontSize: 15,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
            }}
            getRowHeight={() => "auto"}
            autoHeight={true}
            // components={{ Toolbar: GridToolbar }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredRows(rowsArquive(rows))}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  responsavelId: false,
                  solicitanteId: false,
                },
              },
            }}
            loading={loadingTable || loading}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />
        </AccordionDetails>
      </Accordion>
      <ModalDemanda
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        newRow={newRow}
        setNewRow={setNewRow}
        clientes={clientes}
        colaboradores={colaboradores}
        categorias={categorias}
        subCategorias={subCategorias}
      />
      <ModalEditaDemanda
        selectedRow={selectedRow}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setUpdateRow={setUpdateRow}
        clientes={clientes}
        colaboradores={colaboradores}
        categorias={categorias}
        subCategorias={subCategorias}
      />
      {/** <DeleteDialog
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        confirmarDelete={confirmarDelete}
        handleApiDelete={handleApiDelete}
      /> */}
      <AlertModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={confirmarDelete}
        handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
        //textContent={"ola mundo"}
      />
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
        }}
      >
        {selectedRow.status === 5 ? (
          <DialogTitle>{"Deseja realmente desarquivar?"}</DialogTitle>
        ) : (
          <DialogTitle>{"Deseja realmente arquivar?"}</DialogTitle>
        )}
        <DialogContent>
          {selectedRow.status === 5 ? (
            <DialogContentText>
              Ao desarquivar, o status será colocado como pendente. Tem certeza
              que deseja fazer essa edição ?
            </DialogContentText>
          ) : (
            <DialogContentText>
              Uma vez após arquivado o status da demanda não pode ser
              recuperado. Tem certeza que deseja fazer essa edição ?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            disabled={disableButtonArquive}
            onClick={() => {
              handleApiEditStatusRelatorio(selectedRow.status === 5 ? 0 : 5);
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Chat
        selectedRow={selectedRow}
        atualizaBadgeTopicos={atualizaBadgeTopicos}
        socket={socket}
        CommentIcon={CommentIcon}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenEditModal(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        {selectedRow.status === 5 ? (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenEditDialog(true);
            }}
          >
            <ListItemIcon>
              <UnarchiveOutlined fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Desarquivar</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenEditDialog(true);
            }}
          >
            <ListItemIcon>
              <ArchiveOutlined fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Arquivar</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleClose();
            setOpenDeleteModal(true);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Deletar</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
