import { Autocomplete, TextField, Stack, useTheme } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export default function EditCellAutoCompleteModelo({
  id,
  field,
  value,
  lista,
  disabled = false,
  pre = true,
  setIsEdit = () => {},
  selected,
  setHostsEdit = () => {},
}) {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const options = lista.map((option) => option[field]);

  const onChange = (event, newInputValue) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newInputValue || null,
      debounceMs: 1,
    });
    setIsEdit((data) => (data.includes(id) ? data : [...data, id]));
    let config = lista?.find((a) => a?.modelo == newInputValue)?.Predefinicoes
      ?.config;

    setHostsEdit((data) => {
      let row = data.find((a) => a.id == id);

      if (config?.SSH) {
        row["userSsh"] = config?.SSH?.userSSH;
        row["senhaSsh"] = config?.SSH?.senhaSSH;
        row["portaSsh"] = config?.SSH?.portaSSH;
      } else {
        row["userSsh"] = null;
        row["senhaSsh"] = null;
        row["portaSsh"] = null;
      }
      if (config?.Telnet) {
        row["userTelnet"] = config?.Telnet?.userTelnet;
        row["senhaTelnet"] = config?.Telnet?.senhaTelnet;
        row["portaTelnet"] = config?.Telnet?.portaTelnet;
      } else {
        row["userTelnet"] = null;
        row["senhaTelnet"] = null;
        row["portaTelnet"] = null;
      }
      return [...data];
    });
  };
  const onClose = (event, newInputValue) => {
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Autocomplete
      freeSolo
      value={value}
      blurOnSelect={pre}
      openOnFocus={pre}
      autoSelect
      onClose={onClose}
      onChange={onChange}
      disabled={disabled}
      sx={{
        border: selected ? "1px solid" + theme.palette.primary.main : null,
        p: "0px 10px",
        width: "100%",
        fontSize: 13,
        height: "100%",
      }}
      options={options}
      //getOptionLabel={(option) => option.toUpperCase() || ""}
      InputProps={{
        disableUnderline: true,
      }}
      renderInput={(params) => (
        <TextField
          autoFocus={pre}
          autoComplete="off"
          key={"text" + field + id}
          {...params}
          variant="standard"
          slotProps={{
            input: {
              ...params.InputProps,
              disableUnderline: true,
              style: { fontSize: "13px", padding: "3px 0px" },
            },
          }}
        />
      )}
    />
  );
}
