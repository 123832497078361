import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { io } from "socket.io-client";

//import Home from "./pages/home";
import Layout from "./pages/layout";
import DevPage from "./pages/devPage";
import JsonServices from "./pages/nocPage/operacao/jsonServices";
import NocPage from "./pages/nocPage";
import Construcao from "./pages/construcao";
import Login from "./pages/login";
import Acessos from "./pages/nocPage/clientes/Acessos";
import Perfil from "./pages/perfil";
//import Acessos from "./pages/Acessos";
import PrimeiroAcesso from "./pages/primeiroAcesso";
import AreaDoCliente from "./pages/nocPage/clientes/areaDoCliente";
import Predefinicoes from "./pages/nocPage/clientes/areaDoCliente/predefinicoes";
import Administracao from "./pages/administracao";
import Clientes from "./pages/nocPage/clientes";
import NocLinks from "./pages/home/nocLinks";
import Relatorios from "./pages/nocPage/clientes/relatorios";
import Suporte from "./pages/nocPage/clientes/suporte";
import Chat from "./pages/nocPage/clientes/suporte/chat";
import DashboardPage from "./pages/nocPage/clientes/dashboard";
import EasyReport from "./pages/nocPage/clientes/easyReport";
import Execucao from "./pages/nocPage/execucao";
import RelatoriosFlow from "./pages/nocPage/easyFLow/relatoriosFlow";
import EasyFLow from "./pages/nocPage/easyFLow";
import IotAdministracao from "./pages/iot/iotAdministracao";
import IotPreview from "./pages/iot/iotPreview";
import MidiaGrafana from "./pages/nocPage/operacao/midiaGrafana";
import Iot from "./pages/iot";
import Zabbix from "./pages/nocPage/zabbix";
import GerenciamentoDeTags from "./pages/nocPage/zabbix/gerenciamentoDeTags";
import ChecaTemplate from "./pages/nocPage/zabbix/VerificaTemplates";
import Filtrohost from "./pages/nocPage/zabbix/filtroDeHost";
import SolicitarRedefinirSenha from "./pages/solicitarRedefinirSenha";
import RedefinirSenha from "./pages/redefinirSenha";
import AcompanhamentoNoc from "./pages/nocPage/acompanhamento";
//import CriaUsuario from "./pages/criaUsuario";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import { socket } from "./services/socket";
import ProtectedRoute from "./pages/protectedRoute";
import { myTheme } from "./themes";
import GerarScript from "./pages/nocPage/operacao/gerarScript";
import AreaDoClienteOld from "./pages/nocPage/clientes/areaDoClienteOld";
import { NotificationProvider } from "./componentes/notificationProvider";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [checkLogin, setCheckLogin] = useState(null);
  const [colorMode, setColorMode] = useState(prefersDarkMode);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("3"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Frontend sistema estabeleceu conexão, id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(true);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }
    if (!checkLogin) {
      if (isConnected) socket.disconnect();
    }

    if (checkLogin) {
      socket.io.opts.query = {
        usuarioId: JSON.parse(localStorage.getItem("1"))?.usuario?.id,
      };
      socket.connect();
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("connect_error", connectErrorEvent);
      socket.io.on("reconnect_attempt", reconnectAttemptEvent);
      socket.io.on("reconnect_error", reconnectErrorEvent);
      socket.io.on("reconnect", reconnectEvent);
      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("connect_error", connectErrorEvent);
        socket.io.off("reconnect_attempt", reconnectAttemptEvent);
        socket.io.off("reconnect_error", reconnectErrorEvent);
        socket.io.off("reconnect", reconnectEvent);
      };
    }
  }, [checkLogin]);
  //temas
  const theme = React.useMemo(() => myTheme(colorMode), [colorMode]);

  useEffect(() => {
    // Define o estilo a ser aplicado com base no modo de cor
    const style = `
      ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      }
      ::-webkit-scrollbar-track {
      background: ${colorMode ? "#1E1E1E" : "#e2e2e2"};
      }
      ::-webkit-scrollbar-thumb {
      background: ${colorMode ? "#555" : "#b3b3b3"};
      }
      ::-webkit-scrollbar-thumb:hover {
      background: ${colorMode ? "#474747" : "#8f8e8e"};
      }
    `;

    // Cria o elemento <style> e adiciona o CSS
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";

    // Verifica compatibilidade para inserir o CSS
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }
    // Adiciona o elemento <style> ao <head> do documento
    document.head.appendChild(styleElement);
    // Remove o estilo ao desmontar o componente
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [colorMode]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <CssBaseline />
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/resetPassword/:token/:id"
                element={<RedefinirSenha />}
              />

              <Route
                exact
                path="/resetPassword"
                element={<SolicitarRedefinirSenha />}
              />
              <Route path="*" element={<Navigate replace to="/login" />} />
              <Route
                element={
                  <ProtectedRoute
                    setCheckLogin={setCheckLogin}
                    checkLogin={checkLogin}
                  >
                    <Layout
                      socket={socket}
                      colorMode={colorMode}
                      setColorMode={setColorMode}
                    />
                  </ProtectedRoute>
                }
              >
                <Route path="/cs" element={<Construcao />} />
                <Route path="/dev" element={<Construcao />} />

                {/* <Route path="/iot" element={<Iot />}>
                <Route
                  path="/iot/administracao"
                  element={<IotAdministracao />}
                />
                <Route
                  path="/iot/previewProduto/:id"
                  element={<IotPreview />}
                />
              </Route> */}
                {/* <Route path="/vendar" element={<Construcao />} />
              <Route path="/financeiro" element={<Construcao />} /> */}
                <Route path="/previewProduto/:id" element={<IotPreview />} />

                <Route element={<NocPage />} path={"/noc"}>
                  <Route
                    path={"/noc" || "noc/*"}
                    element={<Navigate replace to="/noc/home" />}
                  />
                  <Route path="/noc/home" element={<NocLinks />} />
                  <Route path="/noc/grafana" element={<Construcao />} />
                  <Route path="/noc/zabbix" element={<Zabbix />}>
                    <Route
                      path="/noc/zabbix/verificarTemplates"
                      element={<ChecaTemplate />}
                    />
                    <Route
                      path="/noc/zabbix/gerenciamentoDeTags"
                      element={<GerenciamentoDeTags />}
                    />
                    <Route
                      path="/noc/zabbix/filtroDeHosts"
                      element={<Filtrohost />}
                    />
                  </Route>

                  <Route path="/noc/operacao">
                    <Route
                      path="/noc/operacao/jsonServices"
                      element={<JsonServices />}
                    />
                    <Route
                      path="/noc/operacao/midiaGrafana"
                      element={<MidiaGrafana />}
                    />
                    <Route
                      path="/noc/operacao/gerarScripts"
                      element={<GerarScript />}
                    />
                  </Route>

                  <Route
                    path="/noc/acompanhamento"
                    element={<AcompanhamentoNoc />}
                  />

                  <Route path="/noc/clientes">
                    <Route
                      path={"/noc/clientes"}
                      element={<Navigate replace to="/noc/clientes/acessos" />}
                    />
                    <Route path="/noc/clientes/acessos" element={<Acessos />} />
                    <Route
                      path="/noc/clientes/relatorios"
                      element={<Relatorios />}
                    />

                    <Route
                      path="/noc/clientes/suporte"
                      element={<Suporte socket={socket} />}
                    >
                      <Route
                        path="/noc/clientes/suporte/:chatId"
                        element={<Chat />}
                      />
                    </Route>

                    <Route
                      path="/noc/clientes/dashboard"
                      element={<DashboardPage />}
                    />
                    <Route
                      path="/noc/clientes/areaDoCliente"
                      element={<AreaDoCliente socket={socket} />}
                    />
                    <Route
                      path="/noc/clientes/areaDoClienteOld"
                      element={<AreaDoClienteOld />}
                    />
                    <Route
                      path="/noc/clientes/easyReport"
                      element={<EasyReport />}
                    />
                  </Route>
                  <Route path="/noc/execucao" element={<Execucao />}>
                    <Route
                      path="/noc/execucao/centralDeInstrucoes"
                      element={<Predefinicoes />}
                    />
                  </Route>
                  <Route path="/noc/easyFlow" element={<EasyFLow />}>
                    <Route
                      path="/noc/easyFlow/relatoriosFlow"
                      element={<RelatoriosFlow />}
                    />
                  </Route>
                </Route>
                <Route path="/administracao" element={<Administracao />} />
                <Route path="/perfil" element={<Perfil />} />
              </Route>
              <Route path="/primeiroAcesso" element={<PrimeiroAcesso />} />
            </Routes>
          </BrowserRouter>
        </div>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
