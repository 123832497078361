import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    minHeight: "20vh",
    gap: 1,
  },
  accordion: {
    width: "100%",
    borderRadius: "5px",
    padding: 2,
  },
  sumary: {
    display: "flex",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    p: 0,
    width: "100%",
    margin: 0,
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: 0,
    },
  },
  expandIcon: {
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    borderRadius: "5px",
    p: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  optionsIcon: {
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.text.primary, 0.2),
    },
    borderRadius: "5px",
    p: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 1,
    p: 0,
    gap: 1.5,
    margin: 0,
  },
  divider: { marginY: 1.5 },
  boxDetails: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  textTitle: {
    fontWeight: 700,
    fontSize: "14px",
  },
  textContent: {
    fontWeight: 500,
    fontSize: "14px",
    color: "text.secondary",
  },
  campoPageSize: {
    backgroundColor: "transparent",

    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
    },
    "&. MuiSelect-root": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      boxShadow: "none",
      backgroundColor: "transparent",

      "&.Mui-focused": {
        backgroundColor: "transparent",
        "& fieldset": {
          border: `none`,
        },
        "&:hover": {
          "& fieldset": {
            border: `none`,
            backgroundColor: "transparent",
          },
        },
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      "& fieldset": {
        borderColor: "transparent",
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover": {
        "& fieldset": {
          borderColor: "none",
        },
      },
    },
  },
  boxPagination: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 1,
    flexWrap: "wrap",
  },
  containerNoRows: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxNoRows: { width: "100%", height: "140px" },
});

export default useStyles;
