import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Icon,
  IconButton,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  useTheme,
  Grid2,
  Badge,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import logo from "../../assets/logo4.png";
import logo2 from "../../assets/logo5.png";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../../services/api";
import background from "../../assets/headerHome.jpeg";
import Resizer from "react-image-file-resizer";
import { validarSenha } from "../../utils/validateFields";

export default function PrimeiroAcesso(params) {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [data, setData] = useState(null);
  const [novaSenha, setNovaSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [alertSize, setAlertSize] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [sector, setSector] = useState("");
  const [email, setEmail] = useState("");
  const [usuarioId, setUsuarioId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  // const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const sectorChange = (event) => {
    setSector(event.target.value);
  };

  // useEffect(() => {
  //   //update width
  //   window.addEventListener("resize", () => {
  //     setWidth(window.innerWidth);
  //   });
  // }, []);

  function checkPerfil() {
    let perfilAlreadyExists =
      JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
    let satusPerfil = JSON.parse(localStorage.getItem("2"))?.status || false;
    if (perfilAlreadyExists === true && satusPerfil === true) {
      navigate("/noc");
    }
  }

  useEffect(() => {
    let aux = handleUsuarioId();
    setUsuarioId(aux);
    checkPerfil();
  }, []);

  const navigate = useNavigate();

  const handleVoltar = () => {
    localStorage.clear();
    navigate("/login");
  };
  const styles = {
    containerBox: {
      height: { xs: "auto", md: "100vh" },
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    boxHeaderAndForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      height: { xs: "auto", md: "100vh" },
      minHeight: "100vh",
      gap: 6,
      padding: 4,
      width: { xs: "100vw", lg: "50vw" }, //lg: "50vw" },
    },
    stackForm: {
      //backgroundColor: "red",
      width: "100%", //lg: "40vw" },
      minWidth: "310px",
      maxWidth: "780px",
      height: "100%",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    paperPhoto: {
      //backgroundColor: "red",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "50vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", lg: "flex" },
      justifyContent: "end",
      alignContent: "end",
    },
    textCampo: {
      width: "100%",
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      padding: 2,
      //gap: "auto",
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      textWrap: "wrap",
      padding: 0,
      paddingBottom: 2,
    },
    boxGrid: {
      width: { xs: "90%", sm: "70%" },
      height: "max-content",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: 4,
    },
    columnsTextFields: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: 4,
    },
    rowsTextFields: {
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },
      justifyContent: "center",
      alignItems: { xs: "center", sm: "end" },
      width: "100%",
      gap: { xs: 1, sm: 4 },
    },

    boxAvatar: {
      width: "150px",
      height: "126px",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    boxBtn: {
      display: "flex",
      width: "100%",
      backgroundColor: "primary.main",
      padding: 1,
      textTransform: "none",
      height: "45px",
    },
    icon: { height: "auto", width: "auto" },
    img: { height: "auto", width: "120px" },
    iconBtn: {
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      width: "45px",
      height: "45px",
    },
    avatar: {
      width: "130px",
      height: "130px",
    },
    boxTitlePhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    title: {
      fontSize: { lg: "3em", md: "2em" },
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
      maxWidth: "max-content",
      textWrap: "wrap",
    },
    subTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
  };

  async function handleApi() {
    setIsSubmit(true);
    if (
      !novaSenha ||
      !lastName ||
      !name ||
      !email ||
      !sector ||
      !confSenha ||
      !data ||
      confSenha !== novaSenha ||
      !validarSenha(novaSenha)
    )
      return;
    try {
      setLoading(true);
      const perfil = JSON.parse(localStorage.getItem("2"));

      let response;
      if (perfil) {
        response = await api.put("/updatePerfil", {
          nome: name,
          sobrenome: lastName,
          email: email,
          dataDeNascimento: data,
          foto: baseImage,
          status: true,
          setor: sector,
          usuarioId,
          novaSenha,
        });
      } else {
        response = await api.post("/novoPerfil", {
          nome: name,
          sobrenome: lastName,
          email: email,
          dataDeNascimento: data,
          foto: baseImage,
          status: true,
          setor: sector,
          usuarioId,
          novaSenha,
        });
      }

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        localStorage.setItem("2", JSON.stringify(response.data.perfil));

        navigate("/noc");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleUsuarioId = () => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      return result.refreshToken.usuarioId;
    } else {
      return "";
    }
  };

  handleUsuarioId();

  return (
    <>
      <Box id="container" sx={styles.containerBox}>
        <Paper sx={styles.boxHeaderAndForm}>
          <Box sx={styles.boxHeader}>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              color="primary.main"
              onClick={() => {
                handleVoltar();
              }}
              startIcon={<ArrowBackRoundedIcon />}
            >
              Voltar
            </Button>
            <Icon style={styles.icon}>
              <img
                alt=""
                src={colorMode === "light" ? logo : logo2}
                style={styles.img}
              />
            </Icon>
          </Box>
          <Stack sx={styles.stackForm}>
            <Box sx={styles.boxTitle}>
              <Typography variant="h5" fontWeight="500">
                Gerenciar Primeiro Acesso
              </Typography>
            </Box>
            <Box sx={styles.boxGrid}>
              <Grid2
                container
                columnSpacing={1.5}
                rowSpacing={{ xs: 1, sm: 4 }}
                columns={12}
              >
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <Box sx={styles.rowsTextFields}>
                    <Box sx={styles.boxAvatar}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <IconButton
                            sx={styles.iconBtn}
                            variant="standard"
                            component="label"
                          >
                            <input
                              hidden
                              accept=".png, .jpg, .jpeg"
                              type="file"
                              onChange={(e) => {
                                //  setDialogFoto(true);
                                //  if (salvarFoto) {
                                const file = e.target.files[0];
                                if (file.size < 1024 * 4000) {
                                  //1024 byte = 1 kbyte
                                  // 1 MB
                                  Resizer.imageFileResizer(
                                    file,
                                    500,
                                    500,
                                    "JPEG",
                                    80,
                                    0,
                                    (uri) => {
                                      setBaseImage(uri);
                                      // Do something with the compressed image URI
                                    },
                                    "base64",
                                    500,
                                    500
                                  );
                                } else {
                                  setAlertSize(true);
                                }
                              }}
                            />
                            <PhotoCamera fontSize="" />
                          </IconButton>
                        }
                      >
                        <Avatar src={baseImage} sx={styles.avatar} />
                      </Badge>
                    </Box>
                    <Box sx={styles.columnsTextFields}>
                      <TextField
                        sx={styles.textCampo}
                        label="Nome"
                        id="nome"
                        name="nome"
                        variant="standard"
                        color="primary"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                        disabled={loading}
                        error={isSubmit && !name}
                        helperText={isSubmit && !name && "Campo inválido"}
                      />
                      <TextField
                        sx={styles.textCampo}
                        label="Sobrenome"
                        id="sobrenome"
                        name="sobrenome"
                        variant="standard"
                        color="primary"
                        value={lastName}
                        onChange={(event) => {
                          setLastName(event.target.value);
                        }}
                        disabled={loading}
                        error={isSubmit && !lastName}
                        helperText={isSubmit && !lastName && "Campo inválido"}
                      />
                    </Box>
                  </Box>
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <TextField
                    sx={styles.textCampo}
                    label="Email"
                    id="email"
                    name="email"
                    variant="standard"
                    color="primary"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    disabled={loading}
                    error={isSubmit && !email}
                    helperText={isSubmit && !email && "Campo inválido"}
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                  <TextField
                    select
                    width={"100%"}
                    label="Setor"
                    size="small"
                    sx={{ ...styles.textCampo, mt: 0.4 }}
                    autoComplete="off"
                    value={sector}
                    variant="standard"
                    onChange={sectorChange}
                    disabled={loading}
                    error={isSubmit && !sector}
                    helperText={isSubmit && !sector && "Campo inválido"}
                  >
                    <MenuItem value={"DEV"}>Dev</MenuItem>
                    <MenuItem value={"NOC"}>Noc</MenuItem>
                    <MenuItem value={"IOT"}>Iot</MenuItem>
                    <MenuItem value={"FINANCEIRO"}>Financeiro</MenuItem>
                    <MenuItem value={"VENDARKETING"}>Vendarketing</MenuItem>
                  </TextField>
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"pt-br"}
                  >
                    <DatePicker
                      label="Data de Nascimento"
                      sx={styles.textCampo}
                      color="primary"
                      maxDate={dayjs()}
                      autoComplete="off"
                      disabled={loading}
                      value={data}
                      onChange={(newValeu) => {
                        setData(dayjs(new Date(newValeu)));
                      }}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          error: isSubmit && !data,
                          helperText: isSubmit && !data && "Campo inválido",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <TextField
                    sx={styles.textCampo}
                    name="senha"
                    label="Nova Senha"
                    variant="standard"
                    color="primary"
                    type={showPass ? "text" : "password"}
                    value={novaSenha}
                    onChange={(event) => {
                      setNovaSenha(event.target.value);
                    }}
                    error={isSubmit && (!novaSenha || !validarSenha(novaSenha))}
                    helperText={
                      isSubmit &&
                      (!novaSenha || !validarSenha(novaSenha)) &&
                      "A senha tem que ter no mínimo 6 dígitos e uma letra maiúscula"
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowPass((event) => !event)}
                            >
                              {showPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <TextField
                    //error={checkSenha}
                    sx={styles.textCampo}
                    name="confirmarsenha"
                    label="Confirmar nova senha"
                    variant="standard"
                    color="primary"
                    type={showPassConf ? "text" : "password"}
                    value={confSenha}
                    onChange={(event) => {
                      setConfSenha(event.target.value);
                    }}
                    error={
                      isSubmit &&
                      ((novaSenha && !confSenha) || novaSenha !== confSenha)
                        ? true
                        : isSubmit && !confSenha
                    }
                    helperText={
                      isSubmit &&
                      ((novaSenha && !confSenha) || novaSenha !== confSenha)
                        ? "As senhas não conferem!"
                        : isSubmit && !confSenha && "Campo inválido"
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowPassConf((event) => !event)}
                            >
                              {showPassConf ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    //disabled={checkCadastro}
                    onClick={() => {
                      handleApi();
                    }}
                    sx={styles.boxBtn}
                  >
                    Cadastrar
                  </Button>
                </Grid2>
              </Grid2>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={styles.paperPhoto}>
          <Box sx={styles.boxTitlePhoto}>
            <Typography sx={styles.title}>
              Monitoramento eficiente e confiável.
            </Typography>
            <br></br>
            <Typography sx={styles.subTitle}>
              Monitoramento de infraestrutura de redes para provedores de
              internet, com suporte confiável e atencioso.
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 4MB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
