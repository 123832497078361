import { useState } from "react";
import Papa from "papaparse";
import {
  Typography,
  Box,
  CircularProgress,
  Dialog,
  Alert,
  AlertTitle,
  IconButton,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  AttachFile,
  CheckCircle,
  Error,
  Pending,
  Close,
  Send,
} from "@mui/icons-material";
import api from "../../../../../services/api";
import { Tooltip } from "@mui/material";
import { ptBR } from "@mui/x-data-grid/locales";
function CustomToolbarCsvTable({
  setHosts = () => {},
  setSnackbar = () => {},
  setOpenAlertDialog = () => {},
  setColunasAusentes = () => {},
}) {
  const [fileName, setFileName] = useState("");
  function validateHosts(colunas) {
    let col = colunas.map((a) =>
      a
        .toUpperCase()
        .trim()
        .replace(/\s/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
    let colunaErro = [];
    const colunasTemplate = [
      "nome",
      "funcao",
      "fabricante",
      "modelo",
      "coletor",
      "userSsh",
      "senhaSsh",
      "portaSsh",
      "userTelnet",
      "portaTelnet",
      "senhaTelnet",
      "senhaTelnet",
      "tipoSnmp",
      "portaSnmp",
      "comunidade",
      "ip",
    ];
    colunaErro = colunasTemplate.filter(
      (item) => !col.includes(item.toUpperCase())
    );
    if (Array.isArray(colunaErro) && colunaErro.length > 0) return colunaErro;
    else return false;
  }
  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, "")
      .replace(/.$/, (match) => match.toLowerCase());
  };

  const handleFormatHosts = (hostsCsv) => {
    let newLines = [];
    hostsCsv.map((host) => {
      let newLine = Object.keys(host).reduce((acc, key) => {
        const camelCaseKey = toCamelCase(key)
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (!!host[key]) {
          acc[camelCaseKey] = host[key];
        }

        return acc;
      }, {});

      if (Object.keys(newLine).length > 0) {
        newLine["id"] = JSON.stringify(Math.random());
        newLine["status"] = { codigo: 0, erro: "" };
        newLines.push(newLine);
      }
      return;
    });

    return newLines;
  };

  const handleChangeImportCsv = (e) => {
    if (!e.target.files[0]) {
      setSnackbar({
        children: "Error: Não foi possível ler o arquivo",
        severity: "error",
      });
      return;
    }
    setFileName(e.target?.files[0]?.name);
    Papa.parse(e.target.files[0], {
      header: true,
      download: true,
      complete: function (results) {
        const colunsValidate = validateHosts(results?.meta?.fields);

        if (colunsValidate) {
          setOpenAlertDialog(true);
          setColunasAusentes(colunsValidate);
          return;
        }

        const formattedHosts = handleFormatHosts(results?.data);

        setHosts(formattedHosts);
      },
    });

    e.target.value = "";
  };
  return (
    <GridToolbarContainer sx={{ margin: 1 }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          direction: "row",
          justifyContent: "space-between",
        }}
      >
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />{" "}
        <Box sx={stylesImportCsv.boxButtonAnexar}>
          <Typography>{fileName}</Typography>
          <Button
            variant="text"
            component="label"
            sx={{ fontSize: "0.8125rem" }}
            startIcon={<AttachFile />}
          >
            Anexar CSV
            <input
              hidden
              type="file"
              //ref={fileRef}
              className="file"
              accept=" .xls, .xlsx, .csv"
              onChange={handleChangeImportCsv}
            />
          </Button>
        </Box>{" "}
      </Box>
    </GridToolbarContainer>
  );
}
const stylesImportCsv = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 1,
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  porta: { width: "105px" },
  textField: { width: "230px" },
  titles: { fontWeight: 500, fontSize: "1.20rem" },
  boxButtonAnexar: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    //gap: 3,
    //width: "100%",
  },
  boxButtonSave: {
    display: "flex",
    width: "100%",
    //alignItems: "flex-end",
    justifyContent: "flex-end",
  },
};
export default function ImportarCsv(props) {
  const {
    predefinicoes,
    setSnackbar,
    acessoClientesId,
    acessoProdutoId,
    disableButtonCreate,
    handleApiGetHosts,
    setExpanded = () => {},
  } = props;
  //const fileRef = useRef();
  const [loading, setLoading] = useState(false);
  //const [fileName, setFileName] = useState("");
  const [hosts, setHosts] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [colunasAusentes, setColunasAusentes] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });
  const handleFileHosts = () => {
    let arr = hosts.map((host, index) => {
      let nomeHost = host?.nome
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return {
        nome: nomeHost,
        fabricante: host.fabricante,
        funcao: host.funcao,
        modelo: host.modelo,
        coletor: host.coletor,
        userSsh: host.userSsh,
        portaSsh: parseInt(host.portaSsh),
        senhaSsh: host.senhaSsh,
        userTelnet: host.userTelnet,
        portaTelnet: parseInt(host.portaTelnet),
        senhaTelnet: host.senhaTelnet,
        interfaces: [
          {
            ip: host.ip,
            portaSnmp: parseInt(host.portaSnmp),
            tipoSnmp: parseInt(host.tipoSnmp),
            comunidade: host.comunidade,
          },
        ],
      };
    });

    return arr;
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      disableExport: true,
      width: 100,
      hide: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      disableExport: true,
      width: 70,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value.erro ? params.value.erro : ""}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {params.value.codigo === 0 ? (
                <Pending color="primary" />
              ) : params.value.codigo === 1 ? (
                <CircularProgress size={20} />
              ) : params.value.codigo === 2 ? (
                <CheckCircle color="success" />
              ) : params.value.codigo === 3 ? (
                <Error color="error" />
              ) : null}
            </Box>
          </Tooltip>
        );
      },
    },
    { field: "nome", headerName: "NOME", minWidth: 300, flex: 1 },
    {
      field: "ip",
      headerName: "IP",
      width: 150,
    },
    { field: "tipoSnmp", headerName: "TIPO SNMP", width: 120 },
    { field: "comunidade", headerName: "COMUNIDADE", width: 160 },
    { field: "portaSnmp", headerName: "PORTA SNMP", width: 110 },
    { field: "fabricante", headerName: "FABRICANTE", width: 150 },
    { field: "funcao", headerName: "FUNÇÃO", width: 150 },
    { field: "modelo", headerName: "MODELO", width: 150 },
    { field: "coletor", headerName: "COLETOR", width: 150 },
    { field: "userSsh", headerName: "USER SSH", width: 100 },
    { field: "portaSsh", headerName: "PORTA SSH", width: 100 },
    { field: "senhaSsh", headerName: "SENHA SSH", width: 100 },
    { field: "userTelnet", headerName: "USER TELNET", width: 110 },
    { field: "portaTelnet", headerName: "PORTA TELNET", width: 110 },
    { field: "senhaTelnet", headerName: "SENHA TELNET", width: 110 },
  ];

  const createHosts = async (hostsToApi) => {
    try {
      const response = await api.post("/easyAdd/createMany", {
        hosts: hostsToApi,
        acessoClientesId,
        acessoProdutoId,
      });

      if (response.data) {
        let createdHosts = response.data.filter((data) => data.error == null);
        let errosHosts = response.data.filter((data) => data.error != null);

        if (!errosHosts.length > 0) {
          setSnackbar({
            children: "Todos os hosts foram criados com sucesso no zabbix!",
            severity: "success",
          });
          setExpanded(2);
        } else {
          setSnackbar({
            children: "Alguns hosts retornaram erro ao salvar no zabbix.",
            severity: "warning",
          });
          console.error(response);
        }

        setHosts((prevHosts) =>
          prevHosts.reduce((acc, host) => {
            const findErroHost = errosHosts.find(
              (hostE) => hostE.host == host.nome
            );
            if (findErroHost)
              acc.push({
                ...host,
                status: {
                  codigo: 3,
                  erro: findErroHost?.error[0]?.message,
                },
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {
      setSnackbar({
        children: `Error:${error.response.data.error}`,
        severity: "error",
      });
    }
  };
  async function handlaApiCreateManyHosts() {
    try {
      setLoading(true);
      let hostsToApi = handleFileHosts();
      const responseHosts = await createHosts(hostsToApi);
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box sx={stylesImportCsv.container}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            fontSize: 12,
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            width: "100%",
          }}
          density="compact"
          //getRowHeight={() => "auto"}
          autoHeight={true}
          rows={hosts}
          columns={columns}
          pageSizeOptions={[10, 50, 100]}
          initialState={{
            ...hosts.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{
            toolbar: CustomToolbarCsvTable,
          }}
          slotProps={{
            toolbar: {
              setHosts,
              setSnackbar,
              setOpenAlertDialog,
              setColunasAusentes,
              printOptions: { disableToolbarButton: true },
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
          disableRowSelectionOnClick={true}
        />

        <Box sx={stylesImportCsv.boxButtonSave}>
          <Button
            variant="outlined"
            loading={loading}
            disabled={disableButtonCreate || !hosts.length > 0 || loading}
            sx={{ width: "max-content", fontSize: "13px" }}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Send />
              )
            }
            onClick={() => {
              handlaApiCreateManyHosts();
            }}
          >
            {loading ? "Enviando..." : "Enviar para o Zabbix"}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openAlertDialog}
        onClose={() => {
          setOpenAlertDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 3 }}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlertDialog(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          Colunas Ausentes:
          <Box
            component={"p"}
            sx={{
              maxWidth: "500px" /* Defina a largura máxima desejada */,
              wordWrap:
                "break-word" /* Esta propriedade faz com que o texto quebre quando necessário */,
            }}
          >
            <strong>
              {" "}
              {colunasAusentes.map(
                (coluna, index) =>
                  `${coluna || ""}` +
                  (index !== colunasAusentes.length - 1 && `, `)
              )}
            </strong>
          </Box>
          {/* <Button
            onClick={() => {
              setOpenAlertDialog(false);
            }}
            autoFocus
          >
            Fechar
          </Button>*/}
        </Alert>
      </Dialog>
    </>
  );
}
