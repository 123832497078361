import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Typography,
  Stack,
  TextField,
  Divider,
  Autocomplete,
  Fade,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";

import TabelaCadastrados from "./tabelaCadastrados";
import api from "../../../../services/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imgEmpty from "../../../../assets/wallet.svg";
import ImportarCsv from "./importarCsv";

const styles = {
  accordionSettings: {
    width: "100%",
    borderRadius: 2,
    padding: "0px 10px",
    border: "none",
    "&::before": {
      display: "none",
    },
  },
  container: {
    minWidth: "800px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "10px",
    gap: "1rem",
  },
  paperUnselected: {
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    filter: "opacity(0.6)",
    height: "300px",
    width: "100%",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "100%",
    flexDirection: "column",
  },
  titles: {
    fontWeight: 500,
    color: "primary.main",
    fontSize: "16px",
    m: "5px 0px",
  },
};

export default function AreaDoClienteOld() {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  //cliente e produto
  const [cliente, setCliente] = useState();
  const [clientes, setClientes] = useState([]);
  const [predefinicoes, setPredefinicoes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [tipo, setTipo] = useState("easymon");
  const [easyMon, setEasyMon] = useState(null);
  const [proxys, setProxys] = useState([]);
  const [expanded, setExpanded] = useState(1);
  // outros laoding
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(true);
  // hosts das tabelas
  const [hostsZabbix, setHostsZabbix] = useState([]);

  //pagina
  const [snackbar, setSnackbar] = useState(null);
  //acessos id's
  const [acessoClientesId, setAcessoClientesId] = useState(null);
  const [acessoProdutoId, setAcessoProdutoId] = useState(null);
  //buttons
  const [disableButtonEasyMon, setDisableButtonEasyMon] = useState(true);
  const [disableButtonCreate, setDisableButtonCreate] = useState(true);

  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetClientes();
  }, []);
  useEffect(() => {
    if (tabValue === 0) {
      handleApiGetPredefinicoes();
    }
  }, [tabValue]);

  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;

  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    if (cliente && easyMon) {
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
      setDisableButtonCreate(true);
    } else {
      setHostsZabbix([]);
    }
  }, [easyMon]);

  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyMon(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);

  async function handleApiGetHosts(acessoClientesId, acessoProdutoId) {
    {
      try {
        setDisableButtonCreate(true);
        setLoadingPage(true);
        const response = await api.post("/zabbix/hosts/findManyPingSnmp", {
          acessoClientesId,
          acessoProdutoId,
        });

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível buscar dados do zabbix",
            severity: "error",
          });
          setLoadingPage(false);
        } else {
          setHostsZabbix(response.data.hosts);
          setProxys(response.data.proxys);
          setDisableButtonCreate(false);

          setLoadingPage(false);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });

        console.error(error);
        setLoadingPage(false);
      } finally {
        setDisableButtonCreate(false);
      }
    }
  }

  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/getPredefinicoes");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os dados",
          severity: "error",
        });
        setLoadingPredefinicoes(false);
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data);
        }
        setLoadingPredefinicoes(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
      setLoadingPredefinicoes(false);
    }
  }
  //busca clientes
  async function handleApiGetClientes() {
    try {
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      setLoadingProdutos(true);
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        const atualizaProdutos = response.data;
        setProdutos(atualizaProdutos);
        if (Array.isArray(atualizaProdutos) && atualizaProdutos.length === 1) {
          setEasyMon(atualizaProdutos[0]);
          setAcessoProdutoId(atualizaProdutos[0]?.id);
        }
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingProdutos(false);
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperCliente}>
          <Box>
            <Stack direction={"row"} gap={4}>
              <Box>
                <Typography
                  color={"primary"}
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  Selecione um Cliente
                </Typography>
                <Autocomplete
                  sx={{ m: 1, width: "180px", ml: 0 }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    //console.log(newValue.id);
                    setEasyMon(null);
                    setAcessoProdutoId("");
                    setHostsZabbix([]);
                  }}
                  value={cliente || null}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={disableButtonEasyMon}
                  sx={{ m: 1, mt: 4.8, width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue?.id);
                    //console.log(newValue.id);
                  }}
                  value={easyMon}
                  options={produtos}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingProdutos}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Box>{" "}
        </Paper>
        {acessoClientesId && acessoProdutoId && !loadingPredefinicoes && (
          <Accordion
            disableGutters
            sx={styles.accordionSettings}
            expanded={expanded == 1}
            onChange={() =>
              setExpanded((prev) => {
                if (prev == 1) return null;
                else return 1;
              })
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography sx={styles.titles}>Preview</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              <ImportarCsv
                loadingPredefinicoes={loadingPredefinicoes}
                predefinicoes={predefinicoes}
                proxys={proxys}
                setSnackbar={setSnackbar}
                setTabValue={setTabValue}
                acessoClientesId={acessoClientesId}
                acessoProdutoId={acessoProdutoId}
                disableButtonCreate={disableButtonCreate}
                handleApiGetHosts={handleApiGetHosts}
                setExpanded={setExpanded}
                key={3}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {acessoClientesId &&
          acessoProdutoId &&
          !(loadingPage || loadingPredefinicoes) && (
            <Accordion
              disableGutters
              sx={styles.accordionSettings}
              expanded={expanded == 2}
              onChange={() =>
                setExpanded((prev) => {
                  if (prev == 2) return null;
                  else return 2;
                })
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography sx={styles.titles}>Hosts Cadastrados</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider sx={{ width: "100%", mt: "-10px" }} />
                <TabelaCadastrados
                  loadingPage={loadingPage}
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  rows={hostsZabbix}
                  proxys={proxys}
                  setRows={setHostsZabbix}
                  key={2}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                />
              </AccordionDetails>
            </Accordion>
          )}
        {loadingPredefinicoes && (
          <Skeleton animation="wave" width={"100%"} height={70} />
        )}
        {(loadingPage || loadingPredefinicoes) && (
          <Skeleton animation="wave" width={"100%"} height={70} />
        )}
        {(!acessoClientesId || !acessoProdutoId) &&
          !(loadingPage || loadingPredefinicoes) && (
            <Box sx={styles.paperUnselected}>
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Selecione o cliente e o produto
              </Typography>
            </Box>
          )}

        {/* <Paper sx={styles.paperHosts}>
          <Stack direction={"column"} justifyContent={"space-between"}>
            <Box sx={styles.box}>
              {tabValue === 0 && (
                
              )}
              {tabValue === 1 && (
                <AdicaoEmMassa
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  key={2}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                />
              )}
              {tabValue === 2 && (
                <ImportarCsv
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                  key={3}
                />
              )}
            </Box>
          </Stack>
        </Paper> */}
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
