import { Language, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Snackbar,
  Stack,
  Typography,
  TextField,
  useTheme,
  Divider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import background from "../../assets/headerHome.jpeg";

// import logo4 from "../../assets/logoRoxa.svg";
// import logo3 from "../../assets/logosSvg/LOGOMW.svg";
import logo2 from "../../assets/logoRoxaMW.png";
import logo from "../../assets/logoRoxaMWDark.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CheckToken from "../../services/CheckToken";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login(params) {
  const { setCheckLogin = () => {} } = params;
  const recaptchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [captcha, setCaptcha] = useState(false);
  const [openRecaptcha, setOpenRecaptcha] = useState(false);

  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      tokenValidate();
    }, 200);

    async function tokenValidate() {
      const data = await CheckToken();
      let perfilAlreadyExists =
        JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
      let satusPerfil = JSON.parse(localStorage.getItem("2"))?.status || false;
      if (data && satusPerfil === true && perfilAlreadyExists === true) {
        navigate("/noc");
      }
      if (!data) setCheckLogin(false);
    }
  }, []);

  const handleLogin = async () => {
    if (!login || !senha) {
      setNull(true);
      return;
    }

    setLoading(true);
    try {
      setCaptcha(false);
      const response = await axios.post(
        `${url}/login`,
        { login, senha },
        { withCredentials: false }
      );

      if (response?.data?.token) {
        const { perfil, logs, ...userData } = response.data;
        localStorage.setItem("1", JSON.stringify(userData));

        localStorage.setItem("2", JSON.stringify(perfil));

        localStorage.setItem("logs", JSON.stringify(logs));

        if (
          response.data.perfilAlreadyExists === true &&
          response.data.perfil.status === true
        ) {
          navigate("/noc");
        } else {
          navigate("/primeiroAcesso");
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `${
          error?.response?.data?.error ||
          "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
      if (error.response.status === 403) {
        setOpenRecaptcha(true);
      }
      recaptchaRef.current.reset();
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const styles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    paperImage: {
      borderRadius: "0px!important",
      backgroundImage: `url(${background})`,
      backgroundSize: "auto 100%",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      width: { md: "50vw", lg: "55vw" },
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
    },
    boxTitlesImage: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    paperForm: {
      minHeight: "500px",
      minWidth: "310px",
      //padding: "20px 40px",
      paddingX: 10,
      width: { xs: "100vw", md: "50vw", lg: "45vw" },
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
    },
    titleImage: {
      fontSize: "3em",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
    },
    subTitleImage: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    boxLogo: {
      paddingY: 2,
      width: "100%",
      position: "absolute",
      top: 0,
      flexDirection: "column",
      alignItems: "center",
    },
    logo: { height: "auto", width: "auto" },
    textField: {
      width: "100%",
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      //marginTop: "15px",
      height: "50px",
      textTransform: "none",
      fontSize: 18,
    },
    boxForm: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      //alignItems: { xs: "center", md: "flex-start" },
      mt: 6,
      flex: { xs: 0, md: 1 },
      flex: 1,
      width: "100%",
      maxWidth: "550px",
    },
    titleForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0.5,
      alignSelf: "stretch",
      marginTop: 3,
    },
    boxTextFields: {
      display: "flex",
      padding: "32px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      alignSelf: "stretch",
    },
    boxButtonAndLinks: {
      display: "flex",
      height: "max-content",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      alignSelf: "stretch",
    },
    link: {
      display: "flex",
      padding: "0px 0px 12px 0px",
      justifyContent: { xs: "center", md: "space-between" },
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: "100%",
      gap: 1,
    },
    linkIcons: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      gap: 1,
    },
    icons: {
      color: "text.primary",
    },
    img: {
      height: "auto",
      width: "227px",
    },
  };

  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperImage}>
          <Box sx={styles.boxTitlesImage}>
            <Typography sx={styles.titleImage}>
              Monitoramento eficiente e confiável.
            </Typography>
            <br></br>
            <Typography sx={styles.subTitleImage}>
              Monitoramento de infraestrutura de redes para provedores de
              internet, com suporte confiável e atencioso.
            </Typography>
          </Box>
        </Paper>

        <Paper sx={styles.paperForm}>
          <Box sx={styles.boxForm}>
            <Stack sx={styles.boxLogo}>
              <Icon sx={styles.logo}>
                <img
                  src={colorMode === "light" ? logo : logo2}
                  style={styles.img}
                  alt="logo mw"
                />
              </Icon>
            </Stack>

            <Box sx={styles.titleForm}>
              <Typography
                fontSize="2.5em"
                color="text.primary"
                fontWeight={400}
              >
                Login
              </Typography>
              <Typography
                fontSize="1rem"
                color="text.secondary"
                fontWeight={400}
              >
                Bem vindo! Por favor digite suas credenciais.
              </Typography>
            </Box>
            <Box sx={styles.boxTextFields}>
              <TextField
                error={!login && Null}
                id="outlined-adornment-login"
                type="text"
                variant="standard"
                onKeyDown={handleKeyDown}
                onChange={(event) => {
                  setLogin(event.target.value);
                }}
                label="Login"
                helperText={!login && Null && "Login Inválido"}
                sx={styles.textField}
              />
              <TextField
                error={!senha && Null}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onKeyDown={handleKeyDown}
                variant="standard"
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                label="Password"
                helperText={!senha && Null && "Senha Inválida"}
                sx={styles.textField}
              />{" "}
            </Box>

            <Box sx={styles.boxButtonAndLinks}>
              {openRecaptcha && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  style={{ margin: "10px" }}
                  sitekey={window?.config?.REACT_APP_SITE_KEY || ""}
                  onChange={setCaptcha}
                />
              )}
              <Box sx={styles.link}>
                <Typography fontSize="1rem" color="text.secondary">
                  Não está conseguindo acessar?{" "}
                </Typography>
                <Link
                  href="/resetPassword"
                  sx={{
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  Redefinir senha
                </Link>
              </Box>
              <Button
                variant="contained"
                disabled={loading || (openRecaptcha && !captcha)}
                onClick={() => {
                  handleLogin();
                }}
                sx={styles.button}
              >
                Entrar
              </Button>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Divider
                  sx={{
                    width: "100%",
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "text.disabled",
                  }}
                >
                  {/* <Typography
                  sx={{
                    ,
                  }}
                > */}
                  redes sociais
                  {/*  </Typography> */}
                </Divider>
                <Box sx={styles.linkIcons}>
                  <IconButton href="https://www.facebook.com/mwsolucoesmonitoramento/">
                    <FacebookIcon sx={styles.icons} />
                  </IconButton>
                  <IconButton href="https://www.linkedin.com/company/mw-solucoes/">
                    <LinkedInIcon sx={styles.icons} />
                  </IconButton>
                  <IconButton href="https://instagram.com/mwsolucoesltda?igshid=MzRlODBiNWFlZA==">
                    <InstagramIcon sx={styles.icons} />
                  </IconButton>
                  <IconButton href="https://home.mw-solucoes.com/">
                    <Language sx={styles.icons} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: senha ou login incorreto</Alert>
      </Snackbar>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
