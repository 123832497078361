import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import background from "../../assets/headerHome.jpeg";
import { useParams } from "react-router-dom";
//import logo from "../../assets/logoRoxa.svg";
import logo from "../../assets/logoRoxa.svg";
import logo2 from "../../assets/logosSvg/LOGOMW.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function RedefinirSenha() {
  const [textFeedback, setTextFeedback] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  const { id, token } = useParams();

  const theme = useTheme();
  const colorMode = theme.palette.mode;

  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);
  useEffect(() => {
    async function permissionValidate() {
      try {
        await axios.get(`${url}/authenticationTokenUpdatePassword`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        if (error?.response?.status === 401) {
          setTextFeedback(
            "Infelizmente o link expirou, solicite outro link no site da MW e tente novamente!"
          );
          setFeedback(true);
        } else {
          setAlertServiceOpen(true);
        }
      }
    }
    permissionValidate();
  }, []);

  const handleUpdate = async () => {
    if (!senha || !confirmSenha || confirmSenha !== senha || !senhaValid) {
      setNull(true);
      return;
    }

    try {
      await axios.put(
        `${url}/usuario/resetPassword/update`,
        {
          password: senha,
          usuarioId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTextFeedback("Senha atualizada com sucesso!");
      setFeedback(true);
    } catch (error) {
      if (error?.response?.status === 404) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };

  const styles = {
    ContainerBox: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperPhoto: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "65vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
      objectFit: "cover",
    },
    boxTitlePhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    title: {
      fontSize: { lg: "3em", md: "2em" },
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
      maxWidth: "max-content",
      textWrap: "wrap",
    },
    subTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    boxHeaderAndForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      gap: { xs: 6, lg: 18 },
      padding: 4,
      width: { xs: "100vw", md: "35vw" },
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: 2,
      gap: "auto",
    },
    icon: { height: "auto", width: "auto" },
    img: { height: "auto", width: "300px" },
    PaperForm: {
      width: { xs: "80vw", smd: "30vw" },
      minWidth: "310px",
      height: "90vh",
      minHeight: "50vh",
      maxHeight: "600px",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    boxForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: { xs: "80vw", md: "100%" },
      maxWidth: "520px",
    },
    boxTitles: { gap: 2, display: "flex", flexDirection: "column" },
    boxFieldAndButton: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 3,
      alignSelf: "stretch",
    },
    textField: {
      width: "100%",
      marginTop: 2,
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      marginTop: "10px",
      height: "40px",
      marginBottom: "20px",
      fontSize: 18,
      textTransform: "none",
    },
  };

  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.boxHeaderAndForm}>
          <Box sx={styles.boxHeader}>
            <Icon style={styles.icon}>
              <img
                alt=""
                src={colorMode === "light" ? logo : logo2}
                style={styles.img}
              />
            </Icon>
          </Box>
          <Stack sx={styles.PaperForm}>
            <Box sx={styles.boxForm}>
              {!feedback && (
                <>
                  <Box sx={styles.boxTitles}>
                    <Typography
                      fontSize="1.5em"
                      fontWeight={400}
                      color="text.primary"
                    >
                      Digite a nova senha e confirme em seguida.
                    </Typography>
                  </Box>

                  <Box sx={styles.boxFieldAndButton}>
                    <TextField
                      error={(!senha || !senhaValid) && Null}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      onChange={(event) => {
                        setSenha(event.target.value);
                      }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }}
                      label="Senha"
                      helperText={
                        (!senha || !senhaValid) &&
                        Null &&
                        "A senha precisa ter no mínimo 6 digítos, uma letra e um número"
                      }
                      sx={styles.textField}
                    />

                    <TextField
                      error={
                        (!senha && Null) || (Null && senha !== confirmSenha)
                      }
                      id="outlined-adornment-password2"
                      type={showConfirmPassword ? "text" : "password"}
                      onKeyDown={handleKeyDown}
                      onChange={(event) => {
                        setConfirmSenha(event.target.value);
                      }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword((show) => !show)
                                }
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }}
                      label="Confirmar Senha"
                      helperText={
                        Null &&
                        (!senha || senha !== confirmSenha) &&
                        " As senhas não conferem!"
                      }
                      sx={styles.textField}
                    />

                    <Button
                      variant="contained"
                      onClick={() => {
                        handleUpdate();
                      }}
                      sx={styles.button}
                    >
                      Redefinir Senha
                    </Button>
                  </Box>
                </>
              )}
              {feedback && (
                <>
                  <Typography
                    sx={{
                      fontSize: "1.2em",
                      fontFamily: "Rubik",
                      textAlign: "center",
                      mb: "20px",
                      padding: "10px",
                      backgroundColor: "rgba(0, 0, 0,0.1)",
                    }}
                  >
                    {textFeedback}
                  </Typography>
                  <Button
                    variant="text"
                    href="/login"
                    sx={{
                      textTransform: "none",
                    }}
                    color="primary.main"
                    startIcon={<ArrowBackIcon />}
                  >
                    Voltar para login
                  </Button>
                </>
              )}
            </Box>
          </Stack>
        </Paper>
        <Paper sx={styles.paperPhoto}>
          <Box sx={styles.boxTitlePhoto}>
            <Typography sx={styles.title}>
              Monitoramento eficiente e confiável.
            </Typography>
            <br></br>
            <Typography sx={styles.subTitle}>
              Monitoramento de infraestrutura de redes para provedores de
              internet, com suporte confiável e atencioso.
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
