import {
  Alert,
  Autocomplete,
  InputAdornment,
  Box,
  Fade,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Skeleton,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import imgEmpty from "../../../../assets/wallet.svg";
import InterfaceExibicao from "./interfaceExibicao";
import React, { useCallback } from "react";
const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "1000px",
    width: "100%",
    m: "auto",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "100%",

    flexDirection: "column",
  },
};

export default function RelatoriosFlow() {
  const [snackbar, setSnackbar] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  const tipo = "easymon";
  const [easymon, setEasyMon] = useState(null);
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [acessoProdutoId, setAcessoProdutoId] = useState("");
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  const [disableButtonEasyFLow, setDisableButtonEasyFLow] = useState(true);
  const [disableButtonStart, setDisableButtonStart] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedTemplateHost, setselectedTemplateHost] = useState("");
  const [link, setLink] = useState("");

  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;
  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    handleApiGetClientes();
  }, []);

  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyFLow(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);

  useEffect(() => {
    console.log(acessoClientesId, acessoProdutoId);
    if (acessoClientesId === undefined || acessoProdutoId === undefined)
      setDisableButtonStart(true);
  }, [acessoClientesId, acessoProdutoId]);

  async function handleApiGetVerification() {
    if (
      acessoClientesId === "" ||
      acessoProdutoId === "" ||
      acessoClientesId === undefined ||
      acessoProdutoId === undefined
    )
      return;
    try {
      setLoadingPage(true);
      const response = await api.post("/host/verificaTemplate", {
        acessoClientesId,
        acessoProdutoId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível fazer a verificação",
          severity: "error",
        });
      } else {
        setHosts(response.data.responseHosts);
        setTemplates(response.data.responseTemplates);
        setLink(response.data.link);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingPage(false);
    }
  }
  async function handleApiGetClientes() {
    try {
      setLoadingClientes(true);
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
        setLoadingPage(true);
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingClientes(false);
    }
  }
  async function handleApiUpdateTemplate(list) {
    if (list?.length < 1) return;
    setLoadingPage(true);
    try {
      const response = await api.put("/host/updateTemplate", {
        acessoClientesId,
        acessoProdutoId,
        list,
      });
      if (
        response.data.status === "Error" ||
        response.data.error != undefined
      ) {
        setSnackbar({
          children: "Error: Não foi possível atualizar",
          severity: "error",
        });
      } else {
        let temp = templates;
        temp.map((temp) => {
          if (list.some((list) => list.idDefault === temp.idDefault)) {
            temp.status = 0;
          }
        });
        let hostB = hosts;
        hostB.map((host) => {
          host.templates.map((temp) => {
            if (
              list.some(
                (list) => list.idDefault === temp.idDefault && temp.status === 1
              )
            ) {
              host.status = 0;
              temp.statusTemp = 0;
            } else {
              if (
                list.some(
                  (list) =>
                    list.idDefault === temp.idDefault && temp.status === 3
                )
              ) {
                temp.statusTemp = 0;
                temp.idCliente = response.data.find(
                  (l) => l.name === temp.name
                )?.idCliente;
              }
            }
          });
        });
        setHosts(hostB);
        setTemplates(temp);
        setSnackbar({
          children: "Os templates foram atualizados corretamente!",
          severity: "success",
        });
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
    }
  }
  async function handleApiUpdateHost(list) {
    if (list?.length < 1) return;
    setLoadingPage(true);
    try {
      const response = await api.put("/host/updateHosts", {
        acessoClientesId,
        acessoProdutoId,
        list,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível atualizar os Hosts",
          severity: "error",
        });
      } else {
        let hostsB = hosts;
        let hostsResponse = response.data;
        let statusUpdate = 0;

        hostsB.map((host) => {
          if (hostsResponse.some((list) => list.hostId === host.hostId)) {
            //verificar se teve erros na resposta
            let error = hostsResponse.find(
              (list) => list.hostId === host.hostId
            )?.error;
            if (error === undefined) {
              if (!host.templates.some((temp) => temp.statusTemp != 0)) {
                host.status = 0;
                if (statusUpdate <= 0) statusUpdate = 0;
              } else {
                host.templates.map((temp) => {
                  if (temp.statusTemp === 0) {
                    temp.status = 0;
                    temp.versaoCliente = temp.versaoPredefinicao;
                  }
                });
                if (statusUpdate <= 1) statusUpdate = 1;
              }
            } else {
              if (statusUpdate <= 2) statusUpdate = 2;
              host.status = 2;
              host["alertErro"] = "Error: " + error;
            }
          }
        });

        if (statusUpdate === 0) {
          setSnackbar({
            children: "Os hosts foram atualizados corretamente!",
            severity: "success",
          });
        } else if (statusUpdate === 1) {
          setSnackbar({
            children:
              "A atualização completa de alguns hosts não foi possível, verifique na lista e tente novamente!",
            severity: "warning",
          });
        } else {
          setSnackbar({
            children:
              "Alguns hosts retonaram erro, verifique na lista de alertas e tente novamente!",
            severity: "error",
          });
        }

        setHosts(hostsB);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
    }
  }

  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      setLoadingProdutos(true);
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
        if (response.data?.length === 1) {
          setEasyMon(response.data[0]);
          setAcessoProdutoId(response.data[0].id);
          setDisableButtonStart(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingProdutos(false);
    }
  }

  function selectTemplateHandler(name) {
    setselectedTemplateHost(name);
    setValueTab(0);
  }
  return (
    <>
      <Box sx={styles.container}>
        <Box width="100%">
          <Paper sx={styles.paperCliente}>
            <Typography
              color={"primary"}
              fontWeight={500}
              sx={{
                fontSize: "1.25rem",
              }}
            >
              Selecione um Cliente
            </Typography>
            <Stack
              direction={"row"}
              gap={4}
              justifyContent={"space-between"}
              width="100%"
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={3}>
                <Autocomplete
                  sx={{ width: "180px" }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    setEasyMon(null);
                    setAcessoProdutoId("");
                    setDisableButtonStart(true);
                  }}
                  value={cliente}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingClientes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />

                <Autocomplete
                  disabled={disableButtonEasyFLow}
                  sx={{ width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue?.id);
                    setDisableButtonStart(false);
                  }}
                  value={easymon}
                  options={produtos}
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingProdutos}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Stack>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="contained"
                  sx={{ height: "40px" }}
                  disabled={disableButtonStart || loadingPage}
                  onClick={handleApiGetVerification}
                  endIcon={<TroubleshootIcon />}
                >
                  Iniciar scanner
                </Button>
                {loadingPage && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Paper>

          {(hosts.length > 0 || templates.length > 0) && !loadingPage && (
            <>
              <Paper
                sx={{
                  borderRadius: "15px",
                  maxWidth: "290px",
                  width:
                    hosts.length == 0 || templates.length == 0 ? "200px" : null,
                  margin: "auto",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons={true}
                  value={valueTab}
                  onChange={(event, newValue) => {
                    setValueTab(newValue);
                    setselectedTemplateHost("");
                  }}
                >
                  {templates.length > 0 && (
                    <Tab label={"Templates"} value={0} sx={styles.tab} />
                  )}
                  {hosts.length > 0 && (
                    <Tab label={"Hosts"} value={1} sx={styles.tab} />
                  )}
                </Tabs>
              </Paper>
              {valueTab === 0 && (
                <InterfaceExibicao
                  key={"inter1"}
                  data={templates}
                  link={link}
                  templateSeleted={selectedTemplateHost}
                  setUpdate={handleApiUpdateTemplate}
                />
              )}
              {valueTab === 1 && (
                <InterfaceExibicao
                  key={"inter2"}
                  data={hosts}
                  link={link}
                  selectTemplate={selectTemplateHandler}
                  setUpdate={handleApiUpdateHost}
                />
              )}
            </>
          )}
          {hosts.length == 0 && templates.length == 0 && !loadingPage && (
            <Box
              width="100%"
              height="300px"
              sx={{
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                filter: "opacity(0.6)",
                mt: 1,
              }}
            >
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Cliente não Selecionado
              </Typography>
            </Box>
          )}
          {loadingPage && (
            <Stack width="100%" flexDirection="row" gap={2} padding="20px 0px">
              <Skeleton variant="rounded" width="20%" height={450} />
              <Skeleton variant="rounded" width="80%" height={450} />
            </Stack>
          )}
        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
