import React, { useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Avatar,
  styled,
  IconButton,
  InputAdornment,
  Chip,
  Badge,
  Icon,
  useTheme,
  Tooltip,
  Zoom,
} from "@mui/material";
import "dayjs/locale/pt-br";
import api from "../../services/api";
import {
  Add,
  Clear,
  QueryBuilder,
  Search,
  Verified,
  MoreVert,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useStyles, { styles } from "./styles";
import { ptBR as ptBRDatagrid } from "@mui/x-data-grid/locales";
import MenuAdm from "../../componentes/administracao/menu";
import ModalEditProfile from "./modalEditProfile";
import ModalCreateUser from "./modalCreateUser";
import ModalEditUserPassword from "./modalEditUserPassword";
import ModalEditPermission from "./modalEditPermission";
import { useNotification } from "../../componentes/notificationProvider";
import Filters from "../../componentes/administracao/filters";
import { socket } from "../../services/socket";
import { ReactComponent as IconUserNotFound } from "../../assets/icons/userNotFound.svg";
import MobileTable from "../../componentes/mobileTable";
import AddIcon from "@mui/icons-material/Add";
import { render } from "@testing-library/react";

const StyledBadge = styled(Badge)(({ theme, color }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const permissoes = [
  { name: "Adm001", value: "adm001" },
  { name: "Adm002", value: "adm002" },
  { name: "Dev 001", value: "dev001" },
  { name: "Dev 002", value: "dev002" },
  { name: "Noc 001", value: "noc001" },
  { name: "Noc 002", value: "noc002" },
];

export default function Permissoes() {
  const [perfisDefault, setPerfisDefault] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [role, setRole] = useState([]);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditLoginModal, setOpenEditLoginModal] = useState(false);
  const [openEditPermissionModal, setOpenEditPermissionModal] = useState(false);
  const [buscarUsuario, setBuscarUsuario] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [criadoEm, setCriadoEm] = useState("");
  const [ultimoAcesso, setUltimoAcesso] = useState("");
  const [profileStatus, setProfileStatus] = useState({
    value: "",
    label: "",
  });
  const [permissionStatus, setPermissionStatus] = useState({
    value: "",
    label: "",
  });
  const [sector, setSector] = useState({
    value: "",
    label: "",
  });

  const columnVisibilityMobile = {
    foto: false,
    options: false,
    nome: true,
    sobrenome: true,
    email: true,
    login: true,
    status: true,
    setor: true,
    codigoPermissao: true,
    ultimoAcesso: true,
    createAt: true,
  };

  const theme = useTheme();
  const styles = useStyles(theme);
  const notify = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("2");
      result = JSON.parse(result);
      if (result?.codigoPermissao) {
        if (
          result?.codigoPermissao?.indexOf("adm001") !== -1 ||
          result?.codigoPermissao?.indexOf("adm002") !== -1
        ) {
          return;
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    handleUsuarioId();
  }, []);

  useEffect(() => {
    setSelectedRow(
      (row) => row?.id && perfisDefault.find((r) => r.id === row.id)
    );
  }, [perfisDefault]);

  useEffect(() => {
    async function getRoleUser(params) {
      try {
        const role = JSON.parse(localStorage.getItem("2")).codigoPermissao;
        const roles = await Promise.all(
          role.split(",").map(async (permissao) => permissao)
        );
        setRole(roles);
      } catch (error) {
        console.log(error);
      }
    }

    apigetPerfis();
    getRoleUser();
  }, []);

  async function apigetPerfis(params) {
    try {
      setLoadingPage(true);
      const response = await api.get("/administracao/perfil/findMany");
      setPerfisDefault(response.data);
    } catch (error) {
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Não foi possível se conectar ao servidor!", "error");
    } finally {
      setLoadingPage(false);
    }
  }

  const getFilteredRows = useMemo(() => {
    return perfisDefault.filter((row) => {
      return (
        (buscarUsuario
          ? row?.Perfil?.nome
              ?.toLowerCase()
              .startsWith(buscarUsuario.toLowerCase()) ||
            row?.Perfil?.sobrenome
              ?.toLowerCase()
              .startsWith(buscarUsuario.toLowerCase()) ||
            row?.login.toLowerCase().startsWith(buscarUsuario.toLowerCase())
          : true) &&
        (row?.Perfil
          ? row?.Perfil?.status
              ?.toString()
              .includes(profileStatus?.value.toString())
          : true) &&
        (row?.Perfil?.codigoPermissao
          ? row?.Perfil?.codigoPermissao?.includes(permissionStatus?.value)
          : true) &&
        ((sector.value && row?.Perfil?.setor) || !!sector.value
          ? row?.Perfil?.setor?.includes(sector.value)
          : true) &&
        (criadoEm
          ? new Date(row?.createAt)
              .toLocaleDateString()
              .includes(new Date(criadoEm).toLocaleDateString())
          : true) &&
        (ultimoAcesso
          ? new Date(row?.ultimoAcesso)
              .toLocaleDateString()
              .includes(new Date(ultimoAcesso).toLocaleDateString())
          : true)
      );
    });
  }, [
    perfisDefault,
    buscarUsuario,
    profileStatus,
    permissionStatus,
    criadoEm,
    ultimoAcesso,
    sector,
  ]);

  const open = Boolean(anchorEl);

  const handleClick = (event, row) => {
    event.stopPropagation();
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewOpen = () => {
    setOpenCreateUserModal(true);
  };

  const columns = [
    {
      sortable: false,
      field: "foto",
      headerName: "foto",
      width: 60,
      renderCell: (params) => {
        return (
          <Box sx={styles.centerBox}>
            <Tooltip
              title={params.row?.online ? "online" : "offline"}
              arrow
              slots={{
                transition: Zoom,
              }}
            >
              <StyledBadge
                hidden={!params.row?.online}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color={
                  params.row?.online ? "#44b700" : theme.palette.error.main
                }
                sx={{ height: "fit-content" }}
              >
                <Avatar
                  alt={
                    params?.row?.Perfil?.nome ? params?.row?.Perfil?.nome : ""
                  }
                  src={params?.row?.Perfil?.foto || "/"}
                  sx={styles.foto}
                />
              </StyledBadge>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      width: 120,
      field: "login",
      headerName: "Login",
      renderCell: (params) => (
        <Stack sx={styles.containerColumnEmailNome}>
          <Typography fontSize={14}>
            {params.row?.login ? params.row?.login : "-"}
          </Typography>
        </Stack>
      ),
    },
    {
      width: 230,
      field: "email",
      headerName: "E-mail",
      renderCell: (params) => (
        <Stack sx={styles.containerColumnEmailNome}>
          <Typography fontSize={14}>
            {params.row?.Perfil?.email ? params.row?.Perfil?.email : "-"}
          </Typography>
        </Stack>
      ),
    },
    {
      width: 220,
      flex: 1,
      field: "nome",
      headerName: "Nome",
      renderCell: (params) => (
        <Stack sx={styles.containerColumnEmailNome}>
          <Typography fontSize={14}>
            {params.row?.Perfil?.nome ? params.row?.Perfil?.nome : "-"}
          </Typography>
        </Stack>
      ),
    },
    {
      width: 150,
      flex: 1,
      field: "sobrenome",
      headerName: "Sobrenome",
      renderCell: (params) => (
        <Stack sx={styles.containerColumnEmailNome}>
          <Typography fontSize={14}>
            {params.row?.Perfil?.sobrenome
              ? params.row?.Perfil?.sobrenome
              : "-"}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "setor",
      headerName: "Setor",
      headerAlign: "center",
      width: 100,
      columnAlign: "center",
      renderCell: (params) => (
        <Stack sx={styles.containerColumnSector}>
          <Typography fontSize={14}>
            {params.row?.Perfil?.setor
              ? `${params.row?.Perfil?.setor[0].toUpperCase()}${params.row?.Perfil?.setor
                  .substring(1)
                  .toLowerCase()}`
              : "-"}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status Perfil",
      width: 150,
      renderCell: (params) => (
        <Stack
          color={
            params.row?.Perfil?.status === true
              ? "sucess.main"
              : "text.secondary"
          }
          sx={styles.containerColumnStatus}
        >
          <Icon
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.row?.Perfil?.status === true ? (
              <Verified />
            ) : (
              <QueryBuilder />
            )}
          </Icon>
          <Typography fontSize={13} fontWeight={500}>
            {params.row?.Perfil?.status === true ? "Ativo" : "Aguardando"}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "codigoPermissao",
      headerName: "Permissões",
      headerAlign: "center",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.Perfil?.codigoPermissao ? (
              <Stack sx={styles.containerColumnPermissao}>
                {params?.row?.Perfil?.codigoPermissao
                  ?.split(",")
                  ?.map((permissao, index) => (
                    <Chip
                      key={index}
                      label={permissao}
                      sx={{
                        color:
                          permissao === "adm001"
                            ? "access.superAdmin"
                            : permissao === "adm002"
                            ? "access.admin"
                            : permissao === "dev001"
                            ? "access.dev001"
                            : permissao === "dev002"
                            ? "access.dev002"
                            : permissao === "noc001"
                            ? "access.noc001"
                            : "access.noc002",
                        backgroundColor:
                          permissao === "adm001"
                            ? "filters.superAdmin"
                            : permissao === "adm002"
                            ? "filters.admin"
                            : permissao === "dev001"
                            ? "filters.dev001"
                            : permissao === "dev002"
                            ? "filters.dev002"
                            : permissao === "noc001"
                            ? "filters.noc001"
                            : "filters.noc002",
                      }}
                    />
                  ))}
              </Stack>
            ) : (
              " "
            )}
          </>
        );
      },
    },
    {
      field: "ultimoAcesso",
      headerName: "Último acesso",
      width: 140,
      valueGetter: (value, row) => row?.ultimoAcesso || null,

      renderCell: (params) => {
        return (
          params.value && (
            <Stack sx={styles.containerColumnLastLogin}>
              <Typography fontSize={13} sx={styles.textDate}>
                {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
              </Typography>
              <Typography fontSize={12} sx={styles.subTextDate}>
                {dayjs(params.value).locale("pt-br").format("[às] HH:mm:ss")}
              </Typography>
            </Stack>
          )
        );
      },
    },
    {
      sortable: false,
      field: "createAt",
      headerName: "Criado em",
      width: 150,

      renderCell: (params) => {
        return (
          <Stack sx={styles.containerColumnCreatedAt} spacing={1}>
            <Typography fontSize={13} sx={styles.textDate}>
              {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      sortable: false,
      field: "options",
      headerName: "Opções",
      width: 70,

      renderCell: (params) => {
        const disabledButton =
          JSON.parse(localStorage.getItem("1"))?.refreshToken?.usuarioId ===
            params?.row?.id ||
          (!JSON.parse(localStorage.getItem("2"))?.codigoPermissao?.includes(
            "adm001"
          ) &&
            params?.row?.Perfil?.codigoPermissao?.includes("adm001"));

        return (
          <Box sx={styles.centerBox}>
            <IconButton
              disabled={disabledButton}
              onClick={(e) => handleClick(e, params.row)}
              fontSize="large"
            >
              <MoreVert fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const interval = window.setInterval(() => {
      updateStatusOnline();
    }, 10000);

    return () => window.clearInterval(interval);
  }, []);

  useEffect(() => {
    async function permissionValidate() {
      try {
        const response = await api.get("/authenticationPermission");

        if (response.data.message !== "Permission is valid") {
          navigate("/noc");
        }
      } catch (error) {
        console.error(error);
      }
    }
    permissionValidate();
  }, []);

  async function updateStatusOnline() {
    try {
      socket.emit("onlineUsers", {}, (response) => {
        if (response.status !== 200) {
          console.error(response);
        } else {
          let responseData = response?.data;

          setPerfisDefault((elements) => {
            return elements.map((element) => {
              const user = responseData.find((user) => user.id === element.id);

              if (user) {
                element.online = true;
              } else {
                element.online = false;
              }
              return element;
            });
          });
        }
      });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else
        notify("Não foi possivel atualizar status de conectividade!", "error");
    }
  }

  function CustomNoRowsOverlay() {
    return (
      <Box sx={styles.boxNoRowsOverlay}>
        <IconUserNotFound style={styles.iconUserNotFound} />
        <Typography color="text.disabled">Não possui usuários</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Box sx={styles.CabecalhoBox}>
          <Typography width="100%" variant="h4" sx={styles.titlePage}>
            Gerenciamento de Usuários
          </Typography>
        </Box>

        <Paper elevation={0} sx={styles.paper}>
          <Box sx={styles.boxHeader}>
            <Box sx={styles.boxSearchAndFilter}>
              <TextField
                size="small"
                placeholder="Buscar usuário"
                value={buscarUsuario}
                onChange={(event) => {
                  setBuscarUsuario(event.target.value);
                }}
                sx={styles.searchInput}
                slotProps={{
                  input: {
                    endAdornment: buscarUsuario ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setBuscarUsuario("")}
                          edge="end"
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <Search />
                    ),
                  },
                }}
              />
              <Filters
                profileStatus={profileStatus}
                setProfileStatus={setProfileStatus}
                permissionStatus={permissionStatus}
                setPermissionStatus={setPermissionStatus}
                sector={sector}
                setSector={setSector}
                criadoEm={criadoEm}
                setCriadoEm={setCriadoEm}
                ultimoAcesso={ultimoAcesso}
                setUltimoAcesso={setUltimoAcesso}
                handleNewOpen={handleNewOpen}
              />
              <Tooltip title="Adicionar usuário">
                <Button
                  onClick={handleNewOpen}
                  sx={styles.buttonMobile}
                  variant="contained"
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box width={"100%"}>
            <MobileTable
              autoHeight={true}
              getRowHeight={() => "auto"}
              localeText={
                ptBRDatagrid.components.MuiDataGrid.defaultProps.localeText
              }
              editMode="row"
              loading={loadingPage}
              disableColumnMenu
              disableColumnFilter
              disableSelectionOnClick
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              sx={styles.dataGrid}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rows={getFilteredRows}
              columns={columns}
              onOptionsClick={(e, row) => handleClick(e, row)}
              columnVisibility={columnVisibilityMobile}
              noRowsOverlay={CustomNoRowsOverlay}
              headerMobile={({ login, Perfil, online }) => (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <StyledBadge
                    hidden={online}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    color={online ? "#44b700" : theme.palette.error.main}
                    sx={{ height: "fit-content" }}
                  >
                    <Avatar
                      fontSize={"35px"}
                      alt={Perfil?.nome ? Perfil?.nome : ""}
                      src={Perfil?.foto || "/"}
                    />
                  </StyledBadge>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                      Login
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "text.secondary",
                      }}
                    >
                      {login}
                    </Typography>
                  </Box>
                </Box>
              )}
            />
          </Box>
        </Paper>
      </Box>

      {anchorEl && (
        <MenuAdm
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          setOpenEditProfileModal={setOpenEditProfileModal}
          setOpenEditLoginModal={setOpenEditLoginModal}
          setOpenEditPermissionModal={setOpenEditPermissionModal}
        />
      )}

      <ModalEditProfile
        openEditProfileModal={openEditProfileModal}
        setOpenEditProfileModal={setOpenEditProfileModal}
        selectedRow={selectedRow}
        setPerfisDefault={setPerfisDefault}
        rows={perfisDefault}
      />

      <ModalCreateUser
        openCreateUserModal={openCreateUserModal}
        setOpenCreateUserModal={setOpenCreateUserModal}
        rows={perfisDefault}
        setRows={setPerfisDefault}
        permissoes={permissoes}
        role={role}
        apigetPerfis={apigetPerfis}
      />

      <ModalEditUserPassword
        openEditLoginModal={openEditLoginModal}
        setOpenEditLoginModal={setOpenEditLoginModal}
        selectedRow={selectedRow}
      />

      <ModalEditPermission
        openEditPermissionModal={openEditPermissionModal}
        setOpenEditPermissionModal={setOpenEditPermissionModal}
        selectedRow={selectedRow}
        rows={perfisDefault}
        setRows={setPerfisDefault}
        role={role}
      />
    </>
  );
}
