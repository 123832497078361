import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  Alert,
  AlertTitle,
  IconButton,
  useTheme,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellModes,
} from "@mui/x-data-grid";
import { Close, Delete, Send } from "@mui/icons-material";
import api from "../../../../../services/api";
import CustomToolbar from "../../../../../componentes/easyAdd/customToolbar";
import EditCellAutoComplete from "../../../../../componentes/easyAdd/editCellAutoComplete";
import EditcellTextField from "../../../../../componentes/easyAdd/editCellText";
import { Actions, ActionsEditMode } from "./actionCell";
import { CellExibition, CellExibitionAlerts } from "./exibitionCell";
import { validateIp } from "../../../../../utils/validateIp";
import { handleAlert } from "../../../../../utils/easyAdd/alert";
import { validateTags } from "../../../../../utils/easyAdd/validateTags";
import { validateColetor } from "../../../../../utils/easyAdd/validateColetor";
import EditCellAutoCompleteInterface from "../../../../../componentes/easyAdd/editCellAutoCompleteInterface";
import {
  RenderCellWithMemo,
  RenderEditCellWithMemo,
  RenderFuncaoCellWithMemo,
  RenderFuncaoEditCellWithMemo,
  RenderModeloCellWithMemo,
  RenderModeloEditCellWithMemo,
} from "./memos";
import { ptBR } from "@mui/x-data-grid/locales";
import { DataGridWithNavigation } from "../../../../../componentes/easyAdd/dataGridWithNavigation";
import { EditModelGlobal } from "../../../../../componentes/easyAdd/editModel";
export default function TabelaPreCadastros(props) {
  const {
    predefinicoes = [],
    proxys = [],
    setSnackbar = () => {},
    hosts = [],
    setHosts,
    hostsZabbix = [],
    loading,
    onChangePreCadastro = () => {},
    deletePreCadastro = () => {},
    acessoClientesId,
    acessoProdutoId,
    handleApiGetHosts = () => {},
  } = props;
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [hostsEdit, setHostsEdit] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [keyTable, setKeyTable] = useState(1);
  const [validateData, setValidateData] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");
  const [loadingZabbix, setLoadingZabbix] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    ac: false,
    id: false,
    nome: true,
    ip: true,
    interface: true,
    tipoSnmp: true,
    comunidade: true,
    portaSnmp: true,
    fabricante: true,
    funcao: true,
    modelo: true,
    coletor: true,
    userSsh: true,
    portaSsh: true,
    senhaSsh: true,
    userTelnet: true,
    portaTelnet: true,
    senhaTelnet: true,
  });
  const [cellSelected, setCellSelected] = useState(null);
  const [cellModesModel, setCellModesModel] = useState({});
  const theme = useTheme();
  const tiposSNMP = [{ tipoSnmp: "SNMPv1" }, { tipoSnmp: "SNMPv2" }];
  const tiposInterface = [{ interface: "AGENTE" }, { interface: "SNMP" }];
  const hostsEditRef = useRef();
  const isEditRef = useRef();
  const [openModalUpdateMany, setOpenModalUpdateMany] = useState(false);
  useEffect(() => {
    hostsEditRef.current = hostsEdit;
    isEditRef.current = isEdit;
  }, [hostsEdit, isEdit]);

  const stylesPrecadastro = {
    container: {
      minWidth: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "30px",
    },
    paper: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      elevation: 3,
      gap: "20px",
    },
    accordion: {
      width: "84vw",
    },
    button: { height: "40px", minWidth: "120px", m: 1 },
    filter: { width: "180px" },
    deleButton: { width: "100px", fontWeight: "800" },
    dangerousIcon: { fontSize: 60 },
    boxDialogs: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    dialogText: { color: "#D32F2F" },
    porta: { width: "105px" },
    textField: { width: "230px" },
    titles: { fontWeight: 500, fontSize: "20px" },
    dataGrid: {
      fontSize: 13,
      "& .MuiDataGrid-selectedRowCount": {
        display: "none!important",
      },
      "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none !important",
      },
      height: "100%",
      width: "100%",

      "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${
          theme.palette.mode === "dark" ? 0 : 0.1
        })`,
        color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
      },
      "& .MuiDataGrid-row": {
        backgroundColor: editMode ? theme.palette.background.paper : null,
      },
      "& .MuiDataGrid-cell": {
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          right: 0,
          top: "25%", // 10% da parte superior para centralizar a borda
          height: "50%", // 80% da altura da célula
          width: "1px",
          backgroundColor: theme.palette.color.textDisable, // Cor da borda
        },
      },
    },
  };
  const handleValidateDataSimple = useCallback((hosts) => {
    const verify = [...hosts]?.every((host) => {
      if (camposNulos(host) && host?.newHost) return true;
      return (
        !!host?.nome &&
        !!host?.ip &&
        validateIp(host?.ip) &&
        validaColunaNome({ value: host?.nome, id: host?.id }) == null
      );
    });
    if (!verify)
      setSnackbar({
        severity: "error",
        children: "Alguns campos não foram preenchidos corretamente",
      });
    return verify;
  }, []);

  async function updateHosts() {
    const interval = setInterval(() => {
      if (isEditRef.current.length > 0) return;

      hostsEditRef.current = hostsEditRef.current?.filter(
        (host) => !camposNaoNulos(host)
      );

      if (!handleValidateDataSimple(hostsEditRef.current)) {
        clearInterval(interval);
        return;
      }

      onChangePreCadastro(
        hostsEditRef?.current?.map((host) => ({
          interface: host.interface,
          nome: host.nome,
          id: host.id,
          ip: host.ip,
          comunidade: host.comunidade,
          fabricante: host.fabricante,
          funcao: host.funcao,
          modelo: host.modelo,
          coletor: host.coletor,
          tipoSnmp: host.tipoSnmp,
          portaSnmp: host.portaSnmp,
          userSsh: host.userSsh,
          portaSsh: host.portaSsh,
          senhaSsh: host.senhaSsh,
          userTelnet: host.userTelnet,
          portaTelnet: host.portaTelnet,
          senhaTelnet: host.senhaTelnet,
        }))
      );
      setHostsEdit([]);
      setEditMode(false);
      setValidateData(true);
      clearInterval(interval);
    }, 10);
  }
  function removeHostEditList(id) {
    const line = cellModesModel[id];
    //remover linha de edição
    if (line) {
      setCellModesModel((data) => {
        delete data[id];
        return { ...data };
      });
    }
    setHostsEdit((hosts) => hosts.filter((host) => host.id != id));
  }

  function updateStateHost(ids) {
    let hostsUpdate = hosts
      .filter((host) => ids.includes(host.id))
      .map((host) => ({ ...host, status: 1 }));
    if (hostsUpdate.length == 0) return;
    onChangePreCadastro(hostsUpdate);
  }

  // async function sendToZabbixUnique(id) {
  //   let host = hosts.find((host) => host.id == id);
  //   if (!handleValidateData([host])) {
  //     return;
  //   }
  //   setLoadingZabbix(true);
  //   try {
  //     const response = await api.post("/easyAdd/createMany", {
  //       hosts: [
  //         {
  //           nome: host.nome,
  //           fabricante: host.fabricante,
  //           funcao: host.funcao,
  //           modelo: host.modelo,
  //           userSsh: host.userSsh,
  //           portaSsh: host.portaSsh,
  //           senhaSsh: host.senhaSsh,
  //           userTelnet: host.userTelnet,
  //           portaTelnet: host.portaTelnet,
  //           senhaTelnet: host.senhaTelnet,
  //           coletor: host.coletor,
  //           interfaces: [
  //             {
  //               interface: host.interface,
  //               portaSnmp: host.portaSnmp,
  //               comunidade: host.comunidade,
  //               ip: host.ip,
  //               tipoSnmp: host.tipoSnmp,
  //             },
  //           ],
  //         },
  //       ],
  //       acessoClientesId,
  //       acessoProdutoId,
  //     });
  //     if (response.data) {
  //       let createdHosts = response.data.filter((data) => data.error == null);
  //       let errosHosts = response.data.filter((data) => data.error != null);
  //       if (errosHosts.length == 0) {
  //         setSnackbar({
  //           children: "O hosts foi criado com sucesso no zabbix !",
  //           severity: "success",
  //         });
  //       } else {
  //         setSnackbar({
  //           children: "Error: Não foi possível salvar o host no zabbix.",
  //           severity: "error",
  //         });
  //         console.error("Impedimentos de salvar no zabbix: " + errosHosts);
  //       }
  //       if (createdHosts.length > 0) {
  //         deletePreCadastro([host.id]);
  //       }
  //     }
  //   } catch (error) {
  //     setSnackbar({
  //       children: `Error:${error.message}`,
  //       severity: "error",
  //     });
  //   } finally {
  //     setLoadingZabbix(false);
  //   }
  // }

  async function sendToZabbix() {
    if (!handleValidateData(hosts)) {
      return;
    }
    setLoadingZabbix(true);
    try {
      const response = await api.post("/easyAdd/createMany", {
        hosts: hosts
          ?.filter((a) => rowSelectionModel.includes(a.id))
          ?.map((host) => ({
            nome: host.nome,
            fabricante: host.fabricante,
            funcao: host.funcao,
            modelo: host.modelo,
            userSsh: host.userSsh,
            portaSsh: host.portaSsh,
            senhaSsh: host.senhaSsh,
            userTelnet: host.userTelnet,
            portaTelnet: host.portaTelnet,
            senhaTelnet: host.senhaTelnet,
            coletor: host.coletor,
            interfaces: [
              {
                interface: host.interface,
                portaSnmp: host.portaSnmp,
                comunidade: host.comunidade,
                ip: host.ip,
                tipoSnmp: host.tipoSnmp,
              },
            ],
          })),
        acessoClientesId,
        acessoProdutoId,
      });
      if (response.data) {
        let createdHosts = response.data.filter((data) => data.error == null);
        let errosHosts = response.data.filter((data) => data.error != null);

        if (errosHosts.length == 0) {
          setSnackbar({
            children: "Todos os hosts foram criados com sucesso no zabbix!",
            severity: "success",
          });
        } else {
          setSnackbar({
            children: "Alguns hosts retornaram erro ao salvar no zabbix.",
            severity: "warning",
          });
          console.error(response);
        }
        if (createdHosts.length > 0) {
          deletePreCadastro(
            hosts.filter((host) =>
              createdHosts.some((hostC) => hostC.host == host.nome)
            )
          );
          handleApiGetHosts(acessoClientesId, acessoProdutoId);
        }
      }
    } catch (error) {
      setSnackbar({
        children: `Error:${error.message}`,
        severity: "error",
      });
    } finally {
      setLoadingZabbix(false);
    }
  }

  const validaColunaNome = useCallback(
    (params) => {
      let handleError = params.error
        ? params.error
        : !params?.value
        ? "Campo obrigatório"
        : params?.value?.match(/^[a-zA-Z0-9 .-]*$/)
        ? null
        : "Caracteres inválidos";

      const comparaNomes = hosts?.some(
        ({ id, nome }) => params.value === nome && params.id !== id
      );
      const comparaNomesCadastrados = hostsZabbix?.some(
        ({ id, nome }) => params.value === nome && params.id !== id
      );
      if (comparaNomes) handleError = "Nome do host não pode ser repetido";
      if (comparaNomesCadastrados)
        handleError = "Já existe um host com esse nome cadastrado";

      return handleError;
    },
    [hosts, hostsZabbix, editMode]
  );

  const validaColunaIp = useCallback(
    (params) => {
      let errorMessage = !params?.value
        ? "Campo obrigatório"
        : validateIp(params?.value?.toString())
        ? null
        : "Formato de IP inválido";
      return errorMessage;
    },
    [editMode]
  );

  const validaColObrigatoria = useCallback(
    (params) => {
      let errorMessage = null;
      errorMessage = !params?.value ? "Campo obrigatório" : null;
      return errorMessage;
    },
    [editMode]
  );

  const columns = [
    { field: "id", headerName: "ID", width: 180, hideable: false },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 50,
      hideable: false,
    },
    {
      field: "ac",
      headerName: "AÇÃO",
      hideable: false,
      width: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let disabled =
          (!params.row.newHost && isNaN(params.id)) ||
          params.row.newHost == false;
        return (
          <Tooltip
            title={
              disabled ? "Hosts já criados não podem ser excluidos" : "Delete"
            }
          >
            <Box>
              <IconButton size="small" disabled={disabled} color="secondary">
                <Delete
                  fontSize="small"
                  onClick={() => removeHostEditList(params.id)}
                />
              </IconButton>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "nome",
      hideable: false,
      headerName: "NOME",
      minWidth: 300,
      flex: 1,
      editable: true,

      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            error={validaColunaNome(params)}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            error={validaColunaNome(params)}
            pre={true}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "ip",
      hideable: false,
      headerName: "IP",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            error={validaColunaIp(params)}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            error={validaColunaIp(params)}
            pre={true}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "interface",
      headerName: "INTERFACE",
      hideable: false,
      width: 120,
      type: "",
      editable: true,
      renderCell: (params) => {
        let erro = !tiposInterface.some((a) => a.interface == params.value);
        return (
          <CellExibitionAlerts
            {...params}
            alert={erro}
            text={"nas opções disponíveis"}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditCellAutoCompleteInterface
            {...params}
            lista={tiposInterface}
            setIsEdit={setIsEdit}
            setHostsEdit={setHostsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      hideable: false,
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 120,
      type: "",
      editable: true,
      renderCell: (params) => {
        let erro =
          params.row.interface == "SNMP"
            ? !tiposSNMP.some((a) => a.tipoSnmp == params.value)
            : false;
        return (
          <CellExibitionAlerts
            {...params}
            alert={erro}
            text={"nas opções disponíveis"}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditCellAutoComplete
            {...params}
            lista={
              params.row.interface == "SNMP" ? tiposSNMP : [{ tipoSnmp: "-" }]
            }
            error={validaColObrigatoria(params)}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      hideable: false,
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 160,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            pre={true}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      hideable: false,
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            error={validaColObrigatoria(params)}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            error={validaColObrigatoria(params)}
            setIsEdit={setIsEdit}
            pre={true}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },

    {
      hideable: false,
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: true,
      width: 150,
      renderCell: (params) => {
        //let alert = !predefinicoes.some(a => (a.fabricante)?.toUpperCase() == (params.value)?.toUpperCase())
        let alert = handleAlert(predefinicoes, "fabricante", params?.value);
        return (
          <CellExibitionAlerts
            value={params.value}
            alert={alert}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditCellAutoComplete
            {...params}
            lista={predefinicoes}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      hideable: false,
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
      editable: true,
      // renderCell: (params) => {
      //     const { Funcoes: funcoes = [] } =
      //         predefinicoes.find(
      //             ({ fabricante }) => fabricante == params.row?.fabricante
      //         ) || {};

      //     //let alert = !funcoes.some(a => (a.funcao)?.toUpperCase() == (params.value)?.toUpperCase())
      //     let alert = handleAlert(funcoes, "funcao", params?.value)
      //     return <CellExibitionAlerts value={(params.value)?.toUpperCase()} alert={alert} />
      // },
      // renderEditCell: (params) => {
      //     // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
      //     const { Funcoes: funcoes = [] } =
      //         predefinicoes.find(
      //             ({ fabricante }) => fabricante == params.row?.fabricante
      //         ) || {};
      //     // Função para redefinir os parâmetros do host editado
      //     return <EditCellAutoComplete {...params} lista={funcoes} setIsEdit={setIsEdit} />;
      // },
      renderCell: (params) => {
        return (
          <RenderFuncaoCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },

      renderEditCell: (params) => {
        return (
          <RenderFuncaoEditCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      hideable: false,
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <RenderModeloCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },

      renderEditCell: (params) => {
        return (
          <RenderModeloEditCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            setHostsEdit={setHostsEdit}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      // renderCell: (params) => {
      //     // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
      //     const { Funcoes: funcoes = [] } =
      //         predefinicoes?.find(
      //             ({ fabricante }) => fabricante === params.row?.fabricante
      //         ) || {};

      //     // Encontra os modelos predefinidos para a função específica e desestrutura para obter os modelos ou um array vazio
      //     const { Modelos: modelos = [] } =
      //         funcoes?.find(({ funcao }) => funcao === params.row?.funcao) || {};
      //     let alert = handleAlert(modelos, "modelo", params?.value)
      //     //    let alert = !modelos.some(a => (a.modelo)?.toUpperCase() == (params.value)?.toUpperCase())
      //     return <CellExibitionAlerts value={(params.value)?.toUpperCase()} alert={alert} />
      // },
      // renderEditCell: (params) => {
      //     // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
      //     const { Funcoes: funcoes = [] } =
      //         predefinicoes?.find(
      //             ({ fabricante }) => fabricante === params.row?.fabricante
      //         ) || {};
      //     // Encontra os modelos predefinidos para a função específica e desestrutura para obter os modelos ou um array vazio
      //     const { Modelos: modelos = [] } =
      //         funcoes?.find(({ funcao }) => funcao === params.row?.funcao) || {};
      //     // Retorna o componente EditCellAutoComplete com a lista de modelos
      //     return <EditCellAutoComplete {...params} lista={modelos} setIsEdit={setIsEdit} />;
      // },
    },
    {
      hideable: false,
      field: "coletor",
      headerName: "COLETOR",
      width: 150,
      editable: true,

      // renderCell: (params) => {
      //     let proxs = proxys.map(a => ({ coletor: a.host }))
      //     let alert = !proxs.some(a => a.coletor == params.value)
      //     //    let alert = !modelos.some(a => (a.modelo)?.toUpperCase() == (params.value)?.toUpperCase())
      //     return <CellExibitionAlerts value={(params.value)?.toUpperCase()} alert={alert} text="no cliente" error={validaColObrigatoria(params)} />
      // },
      // renderEditCell: (params) => {
      //     let proxs = proxys.map(a => ({ coletor: a.host }))
      //     return <EditCellAutoComplete {...params} lista={proxs} error={validaColObrigatoria(params)} setIsEdit={setIsEdit} />;
      // },
      renderCell: (params) => {
        return (
          <RenderCellWithMemo
            params={params}
            proxys={proxys}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },

      renderEditCell: (params) => {
        return (
          <RenderEditCellWithMemo
            params={params}
            proxys={proxys}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },

    {
      field: "userSsh",
      headerName: "USER SSH",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            pre={true}
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
  ];

  const camposNulos = (host) => {
    return Object.keys(host).every((key) => {
      if (
        key !== "id" &&
        key !== "newHost" &&
        key !== "ac" &&
        key !== "interface" &&
        key !== "portaSnmp" &&
        key !== "tipoSnmp"
      )
        return !host[key];
      else return true;
    });
  };
  const camposNaoNulos = (host) => {
    return !Object.keys(host).some((key) => {
      // Ignorar os campos especificados
      if (
        key !== "id" &&
        key !== "newHost" &&
        key !== "ac" &&
        key !== "interface" &&
        key !== "portaSnmp" &&
        key !== "status" &&
        key !== "tipoSnmp" &&
        key !== "updateAt" &&
        key !== "createAt"
      ) {
        return (
          host[key] !== null && host[key] !== undefined && host[key] !== ""
        );
      }
      return false;
    });
  };

  const handleValidateData = useCallback(
    (hosts) => {
      const verify = hosts?.every((host) => {
        if (camposNulos(host) && host?.newHost) return true;
        return (
          (host?.interface == "SNMP"
            ? tiposSNMP.some((a) => a.tipoSnmp == host?.tipoSnmp)
            : true) &&
          tiposInterface.some((a) => a.interface === host?.interface) &&
          !!host?.interface &&
          !!host?.nome &&
          !!host?.ip &&
          !!host?.coletor &&
          !!host?.tipoSnmp &&
          !!host?.portaSnmp &&
          validateTags(predefinicoes, host) &&
          validateIp(host?.ip) &&
          validateColetor(proxys, host) &&
          validaColunaNome({ value: host?.nome, id: host?.id }) == null
        );
      });

      // if (verify) setValidateData(true);
      // else setValidateData(false);
      if (!verify)
        setSnackbar({
          severity: "error",
          children: "Alguns campos não foram preenchidos corretamente",
        });
      return verify;
    },
    [editMode]
  );

  // const processRowUpdate = useCallback(async (newRow, oldRow) => {
  //     const compare = _.isEqual(newRow, oldRow);

  //     const { newHost, ...newRowWithoutNewRow } = newRow;

  //     if (!compare && !camposNulos(newRow))
  //         setHostsEdit((prev) => {
  //             return [...prev, newRowWithoutNewRow];
  //         });

  //     return newRow;
  // }, []);

  const processRowUpdate = useCallback(async (newRow, oldRow) => {
    const { newHost, ...newRowWithoutNewRow } = newRow;
    // setHostsEdit((prev) => {
    //     return [...prev, newRowWithoutNewRow];
    // });
    // console.log("ssadsa", newRowWithoutNewRow)
    setIsEdit((data) => data.filter((a) => a != newRowWithoutNewRow.id));

    setHostsEdit((prev) => {
      // Verifica se já existe uma linha com o mesmo id
      const index = prev.findIndex((row) => row.id === newRowWithoutNewRow.id);
      // Se existir, substitui a linha existente; caso contrário, adiciona a nova linha
      if (index !== -1) {
        // Substitui a linha existente
        return prev.map((row, i) => (i === index ? newRowWithoutNewRow : row));
      } else {
        // Adiciona a nova linha
        return [...prev, newRowWithoutNewRow];
      }
    });

    return newRow;
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    //console.log(error);
  }, []);

  const handleCellClick = useCallback(
    (params, event) => {
      if (!editMode) return;
      if (!params.isEditable) {
        return;
      }
      // Ignore portal
      if (
        event.target.nodeType === 1 &&
        !event.currentTarget.contains(event.target)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [editMode]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <>
      <Stack alignItems="end" gap={1.5}>
        <DataGridWithNavigation
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          loading={loading || loadingZabbix}
          rows={hosts}
          style={stylesPrecadastro.dataGrid}
          columns={columns}
          setEditMode={setEditMode}
          editMode={editMode}
          setCellModesModel={setCellModesModel}
          cellModesModel={cellModesModel}
          setRowsEdit={setHostsEdit}
          rowsEdit={hostsEdit}
          updateHosts={updateHosts}
          cellSelected={cellSelected}
          setCellSelected={setCellSelected}
          updateRows={updateHosts}
          setIsEdit={setIsEdit}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          sendRowsSelect={sendToZabbix}
          updateStateHost={updateStateHost}
          setOpenModalUpdateMany={setOpenModalUpdateMany}
        />
      </Stack>
      <EditModelGlobal
        open={openModalUpdateMany}
        setOpen={setOpenModalUpdateMany}
        predefinicoes={predefinicoes}
        proxys={proxys}
        setHostsEdit={setHostsEdit}
        rowSelectionModel={rowSelectionModel}
      />

      <Dialog
        open={openAlertDialog}
        onClose={() => {
          setAlertContent("");
          setAlertTitle("");
          setOpenAlertDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 3 }}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertContent("");
                setAlertTitle("");
                setOpenAlertDialog(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {alertTitle}
          <Box
            component={"p"}
            sx={{
              maxWidth: "500px" /* Defina a largura máxima desejada */,
              wordWrap:
                "break-word" /* Esta propriedade faz com que o texto quebre quando necessário */,
            }}
          >
            <strong> {alertContent}</strong>
          </Box>
          {/* <Button
            onClick={() => {
              setOpenAlertDialog(false);
            }}
            autoFocus
          >
            Fechar
          </Button>*/}
        </Alert>
      </Dialog>
    </>
  );
}
