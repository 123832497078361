import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  ContainerBox: {
    padding: { xs: 2, md: 6 },
    paddingTop: { xs: 2, md: 3 },
    width: "100%",
    minWidth: "320px",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  centerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  CabecalhoBox: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  titlePage: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: "1.25rem",
    color: "theme.palette.text.primary",
    mt: 1,
    mb: 2,
  },
  paper: {
    padding: 2,
    gap: 2,
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    minWidth: "320px",
    borderRadius: "0px 5px 5px 5px",
    backgroundColor: "background.paperAdm",
  },
  boxHeader: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
    alignItems: "center",
  },
  buttonMobile: {
    color: "primary.main",
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    display: { xs: "flex", md: "none" },
    maxWidth: "40.78px",
    maxHeight: "40.78px",
    p: 1.3,
    minWidth: 0,
  },
  boxSearchAndFilter: {
    width: { xs: "100%", sm: "100%" },
    flexWrap: { xs: "nowrap", md: "wrap" },
    gap: { xs: 1, md: 2 },
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  searchInput: {
    width: { xs: "100%", md: "400px" },
  },
  button: {
    fontSize: "13px",
    flexWrap: "no-wrap",
    minWidth: "160px",
    width: { xs: "100%", sm: "160px" },
    height: "40px",
    backgroundColor: "filters.main",
    color: "primary.main",
  },
  dataGrid: {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    width: "100%",
  },
  foto: {
    width: "30px",
    height: "30px",
  },
  containerColumnStatus: {
    height: "100%",
    gap: "8px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  containerColumnSector: {
    height: "100%",
    gap: "8px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  containerColumnEmailNome: {
    height: "100%",
    gap: "8px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  containerColumnPermissao: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
    justifyContent: { xs: "end", md: "center" },
    alignItems: "center",
    flexDirection: "row",
    gap: 0.5,
    padding: { xs: 0, md: 1 },
  },
  containerColumnLastLogin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "row", md: "column" },
    alignItems: { xs: "center", md: "flex-start" },
    //flexWrap: "wrap",
    gap: 0.5,
    height: "100%",
  },
  textDate: {
    color: "text.secondary",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  subTextDate: {
    color: "text.secondary",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  containerColumnCreatedAt: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "start",
    alignItems: "center",
  },
  boxNoRowsOverlay: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  iconUserNotFound: { fill: theme.palette.text.disabled },
});

export default useStyles;
