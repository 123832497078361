import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
};

export default function Execucao() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const pageName = window.location.pathname;
    //console.log("pagename", pageName);
    const pages = ["/noc/execucao/centralDeInstrucoes"];
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setValue(index);
      }
    });
  }, []);

  return (
    <Box sx={styles.container}>
      {/* <Paper
        sx={{
          borderRadius: "15px",

          minWidth: "260px",
          maxWidth: "870px",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Central de instruções"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/execucao/centralDeInstrucoes");
            }}
          />
        </Tabs>
      </Paper> */}

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
