import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Avatar,
  Dialog,
  DialogTitle,
  DialogActions,
  Modal,
  Divider,
  Tooltip,
  MenuItem,
  useTheme,
  DialogContent,
  Grid2,
} from "@mui/material";
import {
  Person,
  Code,
  MemoryOutlined,
  AttachMoneyOutlined,
  SellOutlined,
  SupportAgentOutlined,
  ModeEdit,
} from "@mui/icons-material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../services/api";
import Resizer from "react-image-file-resizer";
import useStyles from "./style";
import { LoadingButton } from "@mui/lab";
import ImageButton from "../../componentes/imageButton";
import { useNotification } from "../../componentes/notificationProvider";

export default function Perfil(props) {
  const { openModal, setOpenModal } = props;
  const [email, setEmail] = useState("");
  const [alertSize, setAlertSize] = useState(false);
  const [editar, setEditar] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [dialogSalvar, setDialogSalvar] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [setor, setSetor] = useState("");
  const [login, setLogin] = useState("");
  const [usuarioId, setUsuarioId] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(dayjs(new Date()));
  const [checkData, setCheckData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const notify = useNotification();
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const styles = useStyles(theme, colorMode);

  const validateFields = () => {
    setIsSubmit(true);

    if (!nome || !sobrenome || !setor || !dataDeNascimento) return false;

    return true;
  };
  async function handleApi() {
    try {
      setLoading(true);
      const response = await api.put("/updatePerfil", {
        nome,
        sobrenome,
        email,
        dataDeNascimento,
        foto,
        status: true,
        setor,
        usuarioId,
      });
      if (response.data.status === "Error") {
        notify("Error: Não foi possível salvar dados", "error");
      } else {
        notify("Dados salvos com sucesso", "success");
        localStorage.setItem("2", JSON.stringify(response.data.perfil));
        handleUsuarioId();
      }
    } catch (error) {
      console.error();
      notify("Não foi possível se conectar com o servidor!", "error");
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }
  useEffect(() => {
    if (!openModal) {
      setEditar(false);
    }
    handleUsuarioId();
  }, [openModal, editar]);

  const handleUsuarioId = () => {
    let result = localStorage.getItem("2");
    let login = JSON.parse(localStorage.getItem("1"))?.usuario?.login;

    if (result !== "undefined") {
      result = JSON.parse(result);
      setLogin(login);
      setNome(result.nome);
      setSobrenome(result.sobrenome);
      setDataDeNascimento(dayjs(new Date(result.dataDeNascimento)));
      setFoto(result.foto);
      setEmail(result.email);
      setSetor(result.setor);
      setUsuarioId(result.usuarioId);
    }
  };
  return (
    <>
      <Modal
        open={openModal}
        closeAfterTransition
        onClose={() => {
          setOpenModal(false);
          setIsSubmit(false);
        }}
        sx={styles.modal}
      >
        <Paper variant="outlined" sx={styles.paper}>
          <DialogTitle sx={styles.dialogTitle}>
            <Box sx={styles.paperPreview}>
              <Box sx={styles.boxCover}></Box>
              <Box sx={styles.boxLoginAndButton}>
                {editar ? (
                  <Box sx={styles.boxButtons}>
                    <Button
                      disabled={loading}
                      onClick={() => {
                        setEditar(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={styles.loadingButton}
                      loading={loading}
                      onClick={() => {
                        if (validateFields()) setDialogSalvar(true);
                      }}
                    >
                      Salvar
                    </LoadingButton>
                  </Box>
                ) : (
                  <Box sx={styles.boxLoginAndButton}>
                    <Button
                      startIcon={<ModeEdit />}
                      component="label"
                      size="small"
                      sx={styles.loadingButton}
                      onClick={() => setEditar(true)}
                    >
                      Editar
                    </Button>
                    <Typography sx={styles.login}>{login}</Typography>
                  </Box>
                )}
              </Box>
              <Box sx={styles.boxAvatar}>
                {!editar ? (
                  <>
                    <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
                    <Tooltip
                      title={`${setor[0]?.toUpperCase()}${setor
                        ?.substring(1)
                        .toLowerCase()}`}
                    >
                      <Box
                        variant="contained"
                        component="label"
                        sx={styles.boxIcon}
                      >
                        {setor === "NOC" ||
                        setor === "NOC-EASYFLOW" ||
                        setor === "NOC-EASYMON" ? (
                          <SupportAgentOutlined />
                        ) : setor === "IOT" ? (
                          <MemoryOutlined />
                        ) : setor === "DEV" ? (
                          <Code />
                        ) : setor === "FINANCEIRO" ? (
                          <AttachMoneyOutlined />
                        ) : setor === "VENDARKETING" ? (
                          <SellOutlined />
                        ) : (
                          <Person />
                        )}
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
                    <ImageButton
                      component="label"
                      size="small"
                      setFoto={setFoto}
                      sx={styles.imageButton}
                    />
                  </>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Grid2 container columnSpacing={1.5} rowSpacing={2} sx={{ p: 1 }}>
              <Grid2 item size={{ xs: 12, sm: 12 }}>
                {editar ? (
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>Nome</Typography>{" "}
                      <TextField
                        id="nome"
                        placeholder="Insira seu nome"
                        value={nome}
                        sx={styles.textfield}
                        disabled={loading}
                        onChange={(event) => {
                          setNome(event.target.value);
                        }}
                        error={isSubmit && !nome}
                        helperText={
                          isSubmit && !nome ? "Campo obrigatório" : ""
                        }
                      />
                    </Box>
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>Sobrenome</Typography>
                      <TextField
                        id="sobrenome"
                        placeholder="Insira seu sobrenome"
                        value={sobrenome}
                        sx={styles.textfield}
                        disabled={loading}
                        onChange={(event) => {
                          setSobrenome(event.target.value);
                        }}
                        error={isSubmit && !sobrenome}
                        helperText={
                          isSubmit && !sobrenome ? "Campo obrigatório" : ""
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>
                      Nome e Sobrenome
                    </Typography>{" "}
                    <Box sx={styles.boxFullName}>
                      <Typography variant="h5" sx={styles.paragraph}>
                        {nome}
                      </Typography>
                      <Typography variant="h5" sx={styles.paragraph}>
                        {sobrenome}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid2>
              <Divider width="100%" />
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                {editar ? (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Setor</Typography>
                    <TextField
                      select
                      width={"100%"}
                      placeholder="Setor"
                      sx={styles.textfield}
                      autoComplete="off"
                      value={setor}
                      onChange={(event) => {
                        setSetor(event.target.value);
                      }}
                      disabled={loading}
                      error={isSubmit && !setor}
                    >
                      {" "}
                      <MenuItem value={"DEV"}>Dev</MenuItem>
                      <MenuItem value={"NOC"}>Noc</MenuItem>
                      <MenuItem value={"IOT"}>Iot</MenuItem>
                      <MenuItem value={"FINANCEIRO"}>Financeiro</MenuItem>
                      <MenuItem value={"VENDARKETING"}>Vendas</MenuItem>
                    </TextField>
                  </Box>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Setor</Typography>
                    <Typography sx={styles.paragraph}>{setor}</Typography>
                  </Box>
                )}
              </Grid2>
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                {editar ? (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>
                      Data de Nascimento{" "}
                    </Typography>{" "}
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"pt-br"}
                    >
                      <DatePicker
                        id="dataDeNascimento"
                        error={checkData}
                        helperText={checkData && "Data inválida."}
                        value={dataDeNascimento}
                        disabled={loading}
                        onChange={(newValeu) => {
                          const dataAtual = dayjs();
                          setDataDeNascimento(newValeu);
                          if (newValeu && newValeu.isAfter(dataAtual, "day")) {
                            // Verifica se a newValeu é maior (no futuro) do que a dataAtual.
                            setCheckData(true); // Define o estado hasError como true se houver erro.
                          } else {
                            setCheckData(false); // Define o estado hasError como false se não houver erro.
                          }
                        }}
                        maxDate={dayjs(dayjs())}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>
                      Data de Nascimento
                    </Typography>{" "}
                    <Typography sx={styles.paragraph}>
                      {dayjs(dataDeNascimento).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                )}
              </Grid2>
              <Divider width="100%" />
              <Grid2 item size={{ xs: 12, sm: 12 }}>
                {editar ? (
                  <Tooltip title="Não é possível editar o e-mail">
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>E-mail</Typography>
                      <TextField
                        id="telefone"
                        placeholder="Insira o e-mail"
                        value={email}
                        disabled
                        sx={styles.textfield}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        error={isSubmit && !email}
                        helperText={isSubmit && !email && "Campo inválido"}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>E-mail</Typography>
                    <Typography sx={styles.paragraph}>{email}</Typography>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </DialogContent>
        </Paper>
      </Modal>
      <Dialog
        open={dialogSalvar}
        onClose={() => {
          //setOpenEditModal(false);
        }}
        sx={styles.modal}
      >
        <DialogTitle>
          <Typography sx={styles.dialogTitle}>
            Deseja realmente salvar os dados editados?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            color={"text.primary"}
            onClick={() => {
              // setOpenEditModal(false);
              setDialogSalvar(false);
              //setEditar("");
            }}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setDialogSalvar(false);
              handleApi();
              setEditar(false);
            }}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
