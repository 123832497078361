import { useTheme } from "@emotion/react";
import { Business, Close, ModeEdit, Person } from "@mui/icons-material";
import {
  Alert,
  alpha,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { validarEmail } from "../../../utils/validateFields";
import api from "../../../services/api";
import ImageButton from "../../../componentes/imageButton";
import { useNotification } from "../../../componentes/notificationProvider";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

function BoxPreview(params) {
  const { foto, setFoto = () => {}, pessoaFisica } = params;
  const theme = useTheme();

  const styles = {
    paperPreview: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "100%",
      padding: 1,
      paddingBottom: 2,
      gap: 1.5,
    },
    boxCover: {
      height: "100px",
      borderRadius: "5px",
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      width: "100%",
    },
    avatar: {
      position: "absolute",
      width: "100px",
      height: "100px",
      mb: 2,
      ml: 3,
      outline: "8px solid" + " " + theme.palette.background.paper,
    },
    boxAvatar: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "end",
    },
    boxIcon: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      color: "white",
      bgcolor: "primary.main",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      position: "absolute",
      margin: "0px 0px 10px 90px",
    },
    button: {
      color: "primary.main",
      backgroundColor: "filters.cover",
      borderRadius: "none",
      textTransform: "none",
    },
  };

  return (
    <Paper sx={styles.paperPreview} variant="outlined">
      <Box sx={styles.boxCover}></Box>

      <ImageButton
        startIcon={<ModeEdit />}
        component="label"
        size="small"
        setFoto={setFoto}
        sx={styles.button}
        isButton={true}
      >
        Editar Foto
      </ImageButton>

      <Box sx={styles.boxAvatar}>
        <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
        <Tooltip title="pessoa física">
          <Box variant="contained" component="label" sx={styles.boxIcon}>
            <Person />
          </Box>
        </Tooltip>
      </Box>
    </Paper>
  );
}

export default function ModalEditProfile(params) {
  const notify = useNotification();

  const {
    openEditProfileModal,
    setOpenEditProfileModal,
    selectedRow = {},
    setPerfisDefault = () => {},
    rows,
  } = params;

  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [foto, setFoto] = useState("");
  const [status, setStatus] = useState(true);
  const [setor, setSetor] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsSubmit(false);
    setId(selectedRow?.id);
    setLogin(selectedRow?.login);
    if (selectedRow?.Perfil && selectedRow?.Perfil?.status === true) {
      setEmail(selectedRow?.Perfil?.email);
      setNome(selectedRow?.Perfil?.nome);
      setSobrenome(selectedRow?.Perfil?.sobrenome);
      setFoto(selectedRow?.Perfil?.foto);
      setSetor(selectedRow?.Perfil?.setor);
      setStatus(selectedRow?.Perfil?.status);
      setDataDeNascimento(selectedRow?.Perfil?.dataDeNascimento);
      setHasProfile(true);
      return;
    }
    setHasProfile(false);
  }, [selectedRow]);

  async function handleUpdateProfile(data) {
    try {
      setLoading(true);

      const response = await api.put("/administracao/perfil/update", data);
      if (response.data) {
        setPerfisDefault((rows) =>
          rows.map((row) =>
            row.id === id ? { ...row, ...response.data } : row
          )
        );
      }
      notify("Perfil atualizado com sucesso!", "success");
    } catch (error) {
      if (error?.response?.data) notify(error.response.data?.message, "error");
      else notify("Erro ao atualizar dados!", "error");
    } finally {
      setLoading(false);
      setOpenEditProfileModal(false);
    }
  }

  const handleClose = () => {
    setOpenEditProfileModal(false);
    setIsSubmit(false);
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    let data = {};

    if (!login) return;
    if (!hasProfile)
      data = {
        id,
        login,
      };
    else if (
      !nome ||
      !sobrenome ||
      !email ||
      !validarEmail(email) ||
      !setor ||
      !dataDeNascimento
    )
      return;
    else
      data = {
        id,
        nome,
        sobrenome,
        email,
        foto,
        login,
        setor,
        status,
        dataDeNascimento,
      };

    handleUpdateProfile(data);
  };

  const handleValidateLogin = () => {
    const verificaLogin = rows?.some(
      (row) => login === row.login && row.id !== id
    );
    if (verificaLogin) {
      return true;
    }
    return false;
  };

  const handleValidateEmail = () => {
    const verificaEmail = rows?.some(
      (row) => email === row?.Perfil?.email && row.id !== id
    );
    if (verificaEmail) {
      return true;
    }
    return false;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const styles = {
    foto: {
      width: "30px",
      height: "30px",
    },
    stack: {
      padding: "0px",
      width: "100%",
      height: "100%",
    },
    button: {
      fontSize: "13px",
      marginRight: "10px",
      backgroundColor: "filters.main",
      color: "primary.main",
    },
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
    paper: {
      padding: 2,
      maxWidth: "432px",
      width: "90%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
      width: "100%",
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    subtitle: {
      fontSize: "14px",
      fontWeight: 500,
      color: "text.primary",
    },
    dialogTitle: {
      padding: 0,
    },
    dialogActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      gap: 0,
      padding: 0,
      width: "100%",
    },
    dialogContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: 1.5,
    },
    boxRowFields: {
      display: "flex",
      flexDirection: "column",
      with: "100%",
      gap: 1,
    },
    textfield: {
      width: "100%",
    },

    alert: { mt: 2, boxShadow: 0 },
  };

  return (
    <Modal open={openEditProfileModal} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={styles.dialogTitle}>
          <Box sx={styles.boxHeader}>
            <Box sx={styles.boxTitle}>
              <IconButton size="small">
                <Person sx={{ color: "primary.main" }} />
              </IconButton>
              <Typography fontWeight={500}>Editar Detalhes</Typography>
            </Box>

            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenEditProfileModal(false);
              }}
              sx={styles.iconButton}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />

          {hasProfile ? (
            <Box sx={{ ...styles.boxRowFields, mt: 1 }}>
              <Typography sx={styles.subtitle}>Prévia</Typography>
              <BoxPreview foto={foto} setFoto={setFoto} />
            </Box>
          ) : (
            <Alert sx={styles.alert} severity="info">
              Usuário não possui dados de perfil cadastrados
            </Alert>
          )}
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Nome</Typography>{" "}
                  <TextField
                    placeholder="Nome"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    value={nome}
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                    disabled={loading}
                    error={isSubmit && !nome}
                    helperText={isSubmit && !nome && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Sobrenome</Typography>{" "}
                  <TextField
                    placeholder="Sobrenome"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    value={sobrenome}
                    onChange={(e) => {
                      setSobrenome(e.target.value);
                    }}
                    disabled={loading}
                    error={isSubmit && !sobrenome}
                    helperText={isSubmit && !sobrenome && "Campo inválido"}
                  />
                </Box>
              </Grid2>
            )}

            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>Login</Typography>{" "}
                <TextField
                  width={"100%"}
                  placeholder="Login"
                  size="small"
                  sx={styles.textfield}
                  autoComplete="off"
                  value={login}
                  onChange={(e) => {
                    setLogin(e.target.value);
                  }}
                  disabled={loading}
                  error={(isSubmit && !login) || handleValidateLogin()}
                  helperText={
                    (isSubmit && !login && "Campo inválido") ||
                    (handleValidateLogin() && "Este login já está cadastrado")
                  }
                />
              </Box>
            </Grid2>

            {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Setor</Typography>
                  <TextField
                    select
                    width={"100%"}
                    placeholder="Setor"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    value={setor}
                    onChange={(e) => {
                      setSetor(e.target.value);
                    }}
                    disabled={loading}
                    error={isSubmit && !setor}
                  >
                    {" "}
                    <MenuItem value={"DEV"}>Dev</MenuItem>
                    <MenuItem value={"NOC"}>Noc</MenuItem>
                    <MenuItem value={"IOT"}>Iot</MenuItem>
                    <MenuItem value={"FINANCEIRO"}>Financeiro</MenuItem>
                    <MenuItem value={"VENDARKETING"}>Vendarketing</MenuItem>
                  </TextField>
                </Box>
              </Grid2>
            )}
            {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>
                    Data de nascimento
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"pt-br"}
                  >
                    <DatePicker
                      placeholder="Data de nascimento"
                      sx={styles.textfield}
                      maxDate={dayjs()}
                      disabled={loading}
                      value={dayjs(dataDeNascimento)}
                      onChange={(newValeu) => {
                        setDataDeNascimento(dayjs(new Date(newValeu)));
                      }}
                      error={isSubmit && !dataDeNascimento}
                      helperText={
                        isSubmit && !dataDeNascimento && "Campo inválido"
                      }
                      size="small"
                    />
                  </LocalizationProvider>
                </Box>
              </Grid2>
            )}
            {hasProfile && (
              <Grid2 item size={12}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>E-mail</Typography>
                  <TextField
                    width={"100%"}
                    placeholder="E-mail"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    disabled={loading}
                    error={
                      (isSubmit && (!email || !validarEmail(email))) ||
                      handleValidateEmail()
                    }
                    helperText={
                      (isSubmit &&
                        (!email || !validarEmail(email)) &&
                        "Campo inválido") ||
                      (handleValidateEmail() && "Este email já está cadastrado")
                    }
                  />
                </Box>
              </Grid2>
            )}
            {/* {hasProfile && (
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <Box sx={styles.boxRowFields}>
                  <Typography sx={styles.subtitle}>Status</Typography>
                  <TextField
                    select
                    width={"100%"}
                    placeholder="Status"
                    size="small"
                    sx={styles.textfield}
                    autoComplete="off"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    disabled={loading}
                    error={isSubmit && !status}
                  >
                    <MenuItem value={true}>Ativo</MenuItem>
                    <MenuItem value={false}>Aguardando Login</MenuItem>
                  </TextField>
                </Box>
              </Grid2>
            )} */}
          </Grid2>
        </DialogContent>

        <DialogActions sx={styles.dialogActions}>
          <Button
            sx={{ textTransform: "none" }}
            disabled={loading}
            onClick={() => {
              handleClose();
            }}
          >
            Cancelar
          </Button>
          <LoadingButton
            sx={{ textTransform: "none" }}
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
            disabled={
              hasProfile
                ? handleValidateEmail() || handleValidateLogin()
                : handleValidateLogin()
            }
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
